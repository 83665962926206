import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { nl2br, nl2brParagraph } from "../../../../utils/nl2br";
import sanitizeHTML from "../../../../utils/sanitizeHTML";
import { Location } from "../../../../interfaces/common.interface";

const BrandDetailAccordion: FC<{
  description: any;
  terms: any;
  locations: Location[];
}> = ({ description, terms, locations }) => {
  const { t } = useTranslation();
  
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="brand-detail-accordion">
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<img src="/assets/images/down-arrow.png" alt="" />}
          id="panel1a-header"
        >
          <Typography>{t("aboutThisGiftCard")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            className="about-brand-details"
            dangerouslySetInnerHTML={{
              __html: nl2brParagraph(sanitizeHTML(description)),
            }}
          ></Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<img src="/assets/images/down-arrow.png" alt="" />}
          id="panel2a-header"
        >
          <Typography>{t("termsAndConditions")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            dangerouslySetInnerHTML={{
              __html: nl2br(sanitizeHTML(terms)),
            }}
          ></Typography>
        </AccordionDetails>
      </Accordion>
      {locations?.length > 0 && (
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<img src="/assets/images/down-arrow.png" alt="" />}
            id="panel3a-header"
          >
            <Typography>{t("location")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="accordion-locations-container">
              {locations?.map((item, index) => {
                return (
                  <div key={index} className="accordion-locations">
                    <Tooltip title={item.address} placement="right" arrow>
                      <div className="accordion-locations-address">
                        {item.address}
                      </div>
                    </Tooltip>
                    <div className="accordion-locations-contact">
                      <img src="/../assets/images/phone.png" alt="" />
                      <div>{item.contactNumber}</div>
                    </div>
                  </div>
                );
              })}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
};

export default BrandDetailAccordion;
