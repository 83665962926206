import DOMPurify from "isomorphic-dompurify";

/**
 * @method sanitize
 * @description To santize dynamic html injection to prevent the script injection
 * @dependencies dompurify
 * @security
 */

const sanitizeHTML = (html: string, options?: any): string | any => {
  if (options) {
    return DOMPurify.sanitize(html, options);
  } else {
    return DOMPurify.sanitize(html);
  }
};

export default sanitizeHTML;
