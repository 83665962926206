import React, { FC } from "react";
import TooManyRequest from "../../features/common/error/tooManyRequestError/TooManyRequest";

const TooManyRequestsError: FC = () => {
  return (
    <div>
      <TooManyRequest />
    </div>
  );
};

export default TooManyRequestsError;
