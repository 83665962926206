import React, { useState, ChangeEvent, FormEvent } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import styles from "./activation.module.scss";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { showNotifierPopup } from "../common/commonSlice";
import { useNavigate } from "react-router-dom";
import PAGE_URLS from "../../constants/pageUrls";
import { useTranslation } from "react-i18next";
import { passwordRegex } from "../../utils/regex";
import { RootState } from "../../redux/store";
import { useMutation } from "@apollo/client";
import { ACTIVATE_ACCOUNT_MUTATION } from "./activation.query";

interface FieldsState {
  password: string;
  confirmPassword: string;
}

const ActivationStepThree: React.FC<{
  token?: string;
  slug?: string;
}> = ({ token, slug }) => {
  const [fields, setFields] = useState<FieldsState>({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { i18n, t } = useTranslation();

  const currentLang = i18n.language;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { userName } = useAppSelector((state: RootState) => state.setUserName);

  const showNotifier = (headerText: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: headerText,
        notifierText: t("pls-sign-in-proceed"),
      })
    );
  };

  const [activateAccount, { loading }] = useMutation(ACTIVATE_ACCOUNT_MUTATION);

  const validateFields = () => {
    return new Promise(function (resolve, reject) {
      let errors = { password: "", confirmPassword: "" };
      let hasError = false;

      if (!fields.password) {
        hasError = true;
        errors = {
          ...errors,
          password: t("password-required"),
        };
      } else if (!passwordRegex.test(fields.password)) {
        hasError = true;
        errors = {
          ...errors,
          password: t("password-error-text"),
        };
      } else if (fields.password !== fields.confirmPassword) {
        hasError = true;
        errors = {
          ...errors,
          confirmPassword: t("password-not-match"),
        };
      }

      if (!hasError) {
        // Clear the error messages
        errors = { password: "", confirmPassword: "" };
      }

      hasError ? reject(errors) : resolve(fields);
    });
  };

  const handleClearError = () => {
    setErrors({
      password: "",
      confirmPassword: "",
    });
  };

  // for instantly changing the error state and onchange function
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setFields((prev) => ({ ...prev, password: newPassword }));
    if (!newPassword) {
      setErrors((prev) => ({ ...prev, password: "" }));
    } else if (newPassword.length >= 8 && newPassword.length <= 25) {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
  };

  // for instantly changing the error state and onchange function
  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = event.target.value;
    setFields((prev) => ({ ...prev, confirmPassword: newConfirmPassword }));
    if (!newConfirmPassword) {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    } else if (newConfirmPassword === fields.password) {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await validateFields();
      const { data } = await activateAccount({
        variables: {
          username: userName,
          password: fields.password,
          confirmPassword: fields.confirmPassword,
          signedUsername: token,
          corporateSlug: slug,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      const message = data?.activateAccount?.message;
      showNotifier(message);
      navigate(
        slug
          ? `/${currentLang}/${PAGE_URLS.LOGIN}?corp_slug=${slug}`
          : `/${currentLang}/${PAGE_URLS.LOGIN}`
      );
    } catch (err: any) {
      setErrors(err);
    }
  };

  return (
    <div className="activation-form">
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          placeholder={t("username") || ""}
          value={userName}
          color="info"
          className={`${styles["activation-form-fields"]}`}
          disabled={true}
        />
        <TextField
          placeholder={t("password") || ""}
          type={showPassword ? "text" : "password"}
          value={fields.password}
          color="info"
          onChange={handlePasswordChange}
          className={`${styles["activation-form-fields"]} confirm-password-eye`}
          helperText={errors.password}
          error={!!errors.password}
          onBlur={handleClearError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  disabled={!fields.password}
                >
                  {showPassword ? (
                    <img
                      src="/assets/images/invisible-eye.png"
                      alt="password-eye-invisible"
                      style={{ height: "16px", width: "21px" }}
                    />
                  ) : (
                    <img
                      src="/assets/images/visible-eye.png"
                      alt="password-eye-visible"
                      style={{ height: "16px", width: "21px" }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          placeholder={t("confirm-password") || ""}
          type="password"
          color="info"
          value={fields.confirmPassword}
          onChange={handleConfirmPasswordChange}
          className={`${styles["activation-form-fields"]}`}
          helperText={errors.confirmPassword}
          error={!!errors.confirmPassword}
          onBlur={handleClearError}
          inputProps={{ maxLength: 255 }}
        />
        <Button
          variant="contained"
          type="submit"
          className="auth-button"
          sx={{ marginBottom: "59px" }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("activate")}
        </Button>
      </form>
    </div>
  );
};

export default ActivationStepThree;
