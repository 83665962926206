const theme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1320,
    },
  },
  palette: {
    primary: {
      main: "#332879",
    },
    info: {
      main: "#dad8e2",
    },
    secondary: {
      main: "#3a1a5f",
      contrastText: "#164587",
    },
    background: {
      default: "#ffffff",
    },
  },
};

export default theme;
