import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux/store";
import { SelectedStore } from "../../interfaces/common.interface";

interface CommonStateType {
  notifierHeader: string;
  notifierText: string;
  showNotifier: boolean;
  userName?: any;
  accessToken?: string;
  userNameFromProfile?: string;
  selectedStore?: SelectedStore;
  userBalance?: string;
}

const initialState: CommonStateType = {
  notifierHeader: "",
  notifierText: "",
  showNotifier: false,
  userName: null,
  accessToken: "",
  userNameFromProfile: "",
  selectedStore: {
    id: "",
    name: "",
    image: "",
  },
  userBalance: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showNotifierPopup: (state, action: PayloadAction<CommonStateType>) => {
      state.notifierHeader = action.payload.notifierHeader;
      state.notifierText = action.payload.notifierText;
      state.showNotifier = action.payload.showNotifier;
    },
    setUserName: (state, action: PayloadAction<any>) => {
      state.userName = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setUserNameFromProfileQuery: (state, action: PayloadAction<string>) => {
      state.userNameFromProfile = action.payload;
    },
    setSelectedStore: (state, action: PayloadAction<SelectedStore>) => {
      if (!state.selectedStore) {
        state.selectedStore = { id: "", name: "", image: "" };
      }
      state.selectedStore.id = action.payload?.id;
      state.selectedStore.name = action.payload?.name;
      state.selectedStore.image = action.payload?.image;
    },
    setUserBalance: (state, action: PayloadAction<string>) => {
      state.userBalance = action.payload;
    },
  },
});

export const getUserName = (state: RootState) => state.setUserName;
export const NotifierSelector = (state: RootState) => state.notifier;
export const getAccessToken = (state: RootState) => state.setAccessToken;
export const getUserNameFromProfile = (state: RootState) =>
  state.setUserNameFromProfile;
export const getSelectedStore = (state: RootState) => state.setSelectedStore;
export const getUserBalance = (state: RootState) => state.setUserBalance;

export const {
  showNotifierPopup,
  setUserName,
  setAccessToken,
  setUserNameFromProfileQuery,
  setSelectedStore,
  setUserBalance,
} = commonSlice.actions;
export default commonSlice.reducer;
