import { FormControl, TextField } from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useRef } from "react";
import CategorySelector from "../categorySelector/CategorySelector";
import styles from "./LandingFilterTab.module.scss";
import { useTranslation } from "react-i18next";

const LandingFilterTab: FC<{
  handleCategoryOpen: () => void;
  handleCategoryClose: () => void;
  handleCategoryClick: (name: string, id: any, image?: any) => void;
  showCategorySelector: boolean;
  selectedCategory: any;
  categories: any;
  handleBrandName: (event: ChangeEvent<HTMLInputElement>) => void;
  categoryLoading?: boolean;
}> = ({
  categories,
  categoryLoading,
  handleCategoryClick,
  handleCategoryClose,
  handleCategoryOpen,
  selectedCategory,
  showCategorySelector,
  handleBrandName,
}) => {
  const categoryRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        categoryRef.current &&
        !categoryRef.current.contains(event.target as Node)
      ) {
        handleCategoryClose?.();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className={styles["filter-container"]}>
      <div
        className={`${styles["filter-container__search"]} filter-container-search`}
      >
        <FormControl fullWidth>
          <TextField
            placeholder={t("search") || ""}
            onChange={handleBrandName}
          />
        </FormControl>
      </div>
      <div
        className={`${styles["filter-container__categories"]} filter-container-categories`}
        onClick={handleCategoryOpen}
        ref={categoryRef}
      >
        <p>{selectedCategory.name}</p>
        <img src="/assets/images/down-arrow.png" alt="" />
      </div>

      {showCategorySelector && (
        <CategorySelector
          handleCategoryClick={handleCategoryClick}
          categories={categories}
          selectedCategory={selectedCategory}
        />
      )}
    </div>
  );
};

export default LandingFilterTab;
