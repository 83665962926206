import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { debounce } from "lodash";
import { useAppSelector } from "../../utils/hooks";
import { getAccessToken, getSelectedStore } from "../../features/common/commonSlice";
import { BRAND_LISTING_QUERY, CATEGORY_LISTING_QUERY } from "../../features/dashboard/dashboard.query";
import CorporateSlider from "../../features/dashboard/corporateSlider/CorporateSlider";
import GiftSelectionBox from "../../features/dashboard/giftSelectionBox/GiftSelectionBox";

const Dashboard: FC = () => {
  const { i18n } = useTranslation();
  const { accessToken } = useAppSelector(getAccessToken);
  const { selectedStore } = useAppSelector(getSelectedStore);
  const currentLang = i18n.language;

  const { data, loading: categoryLoading } = useQuery(CATEGORY_LISTING_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  const categories = data?.categoryList?.edges;

  const [showCategorySelector, setShowCategorySelector] = useState(false);

  const handleCategoryOpen = () => {
    setShowCategorySelector(true);
  };
  const handleCategoryClose = () => {
    setShowCategorySelector(false);
  };
  const [selectedCategory, setSelectedCategory] = useState({
    name: "",
    image: "",
    id: "",
  });

  const handleCategoryClick = (name: string, id?: any, image?: any) => {
    setSelectedCategory({
      ...selectedCategory,
      name: name,
      image: image,
      id: id,
    });
    setShowCategorySelector(false);
  };

  useEffect(() => {
    if (categories) {
      setSelectedCategory({
        ...selectedCategory,
        name: categories[0]?.node?.name,
        id: categories[0]?.node?.code,
      });
    }
  }, [categories]);

  // //=> Beginning of Brand Listing API Integration <=//

  const INITIAL_FETCH_COUNT_BRANDS = 12;
  const [brandName, setBrandName] = useState("");
  const debounceTime = 300;
  const handleBrandName = debounce((event: ChangeEvent<HTMLInputElement>) => {
    setBrandName(event.target.value);
  }, debounceTime);

  const {
    loading: brandsLoading,
    error: brandsError,
    fetchMore: fetchMoreBrands,
  } = useQuery(BRAND_LISTING_QUERY, {
    variables: {
      after: "",
      first: INITIAL_FETCH_COUNT_BRANDS,
      storeCode: selectedStore?.id,
      categoryCode: selectedCategory?.id,
      brandName: brandName,
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  // State management for data and hasMore flag
  const [hasMoreBrands, setHasMoreBrands] = useState(true);
  const [brands, setBrands] = useState<any>([]);
  const [onchangeLoading, setOnChangeLoading] = useState(brandsLoading);

  // Fetch initial data for brand listing
  useEffect(() => {
    const fetchData = async () => {
      if (brands?.length === 0 && !brandsLoading && !brandsError) {
        try {
          const newData = await fetchMoreBrands({
            variables: {
              after: "",
            },
          });
          if (newData && newData?.data?.brandList) {
            const updatedData = newData?.data?.brandList?.edges;
            setBrands(updatedData);
            setHasMoreBrands(newData.data.brandList.pageInfo.hasNextPage);
          }
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      }
    };

    fetchData();
  }, [fetchMoreBrands, brandsLoading, brandsError]);

  // Fetch more data function
  const fetchMoreDataForBrands = async () => {
    if (brands?.length > 0) {
      try {
        const newData = await fetchMoreBrands({
          variables: {
            after: brands[brands?.length - 1]?.cursor,
          },
        });
        if (newData && newData?.data?.brandList) {
          const updatedData = newData?.data?.brandList?.edges;
          setBrands((prevBrands: any) => [...prevBrands, ...updatedData]);
          if (!newData?.data?.brandList?.pageInfo?.hasNextPage) {
            setHasMoreBrands(false);
          }
        }
      } catch (error) {
        console.error("Error fetching more data:", error);
      }
    }
  };
  // responsible for brand listing according to store ,category, search
  const fetchData = useCallback(async () => {
    setOnChangeLoading(true); // Set loading state to true when fetching starts
    try {
      const newData = await fetchMoreBrands({
        variables: {
          after: "",
          // storeCode: selectedCountry.id,
          categoryCode: selectedCategory.id,
          brandName: brandName,
        },
      });
      if (newData && newData.data.brandList) {
        const updatedData = newData.data.brandList.edges;
        setBrands(updatedData);
        setHasMoreBrands(newData.data.brandList.pageInfo.hasNextPage);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setOnChangeLoading(false); // Set loading state to false after fetching is complete
    }
  }, [fetchMoreBrands, selectedStore?.id, selectedCategory.id, brandName]);

  useEffect(() => {
    if (selectedStore?.id && selectedCategory.id) {
      fetchData();
    }
  }, [selectedStore?.id, selectedCategory.id, brandName, fetchData]);

  return (
    <div>
      <CorporateSlider />
      <GiftSelectionBox
        handleCategoryOpen={handleCategoryOpen}
        handleCategoryClose={handleCategoryClose}
        handleCategoryClick={handleCategoryClick}
        showCategorySelector={showCategorySelector}
        selectedCategory={selectedCategory}
        categories={categories}
        handleBrandName={handleBrandName}
        hasMoreBrands={hasMoreBrands}
        brands={brands}
        fetchMoreBrands={fetchMoreDataForBrands}
        onChangeLoading={onchangeLoading}
        categoryLoading={categoryLoading}
      />
      <div className="gifts-footer-separator"></div>
    </div>
  );
};

export default Dashboard;
