import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import styles from "./BrandDetailingMobile.module.scss";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import BrandDetailAccordion from "../common/brandDetailAccordion/BrandDetailAccordion";
import CheckoutModal from "../common/checkoutModal/CheckoutModal";
import BrandDetaingMobileSkeleton from "./skeleton/BrandDetailMobileSkeleton";
import { SingleObj } from "../../../interfaces/common.interface";

interface BrandDetailMobileProps {
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleDenomChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDenominationClick: (amount: any) => void;
  onClickOther: () => void;
  handleBalanceError: (error: string) => void;
  singleObj: SingleObj;
}

const BrandDetaingMobile: FC<BrandDetailMobileProps> = ({
  handleDecrement,
  handleDenomChange,
  handleDenominationClick,
  handleIncrement,
  onClickOther,
  handleBalanceError,
  singleObj,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLang = i18n.language;
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleGoBack = () => {
    navigate(`/${currentLang}/dashboard`);
  };

  const handleMouseEnter = (index: any, item: any) => {
    // Add the 'active' class when the user hovers over an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles["brand-detail-container__amount-selection__single-item"]
    );
    if (!(item === singleObj?.initialDenom)) {
      elements[index]?.classList?.add(styles["active"]);
    }
  };

  const handleMouseLeave = (index: any, item: any) => {
    // Remove the 'active' class when the user hovers out of an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles["brand-detail-container__amount-selection__single-item"]
    );
    if (!(item === singleObj?.initialDenom)) {
      elements[index]?.classList?.remove(styles["active"]);
    }
  };

  const onKeyDown = (event: any) => {
    const value = event?.target?.value;
    const maxLength = String(singleObj?.maxDenom).length || 4;

    if (
      value?.split(".")[0].length === maxLength &&
      event.which !== 8 &&
      event.which !== 46 &&
      (Number(value) < Number(singleObj?.maxDenom) ||
        Number(value) > Number(singleObj?.minDenom))
    ) {
      event.preventDefault();
      return false;
    }

    // #. Prevent adding char "e" from input
    // #. "e" char allowed in numeric field by default
    if (event.which === 69 || event.which === 107 || event.which === 109) {
      event.preventDefault();
      return false;
    }
  };
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>();
  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const handleShouldHide = () => {
    setTooltipEnabled(false);
  };
  if (singleObj?.detailLoading) {
    return <BrandDetaingMobileSkeleton />;
  }

  return (
    <>
      <div className={styles["brand-detail-container"]}>
        <div className="brand-detail-mobile-wrapper">
          <div className={styles["brand-detail-container__header"]}>
            <div className={styles["brand-detail-container__header__left"]}>
              <img
                src="/assets/images/left-arrow.png"
                alt=""
                onClick={handleGoBack}
              />
            </div>
            <Tooltip
              title={singleObj?.singleData?.name}
              placement="top"
              open={tooltipEnabled || false}
            >
              <div
                className={styles["brand-detail-container__header__right"]}
                onMouseEnter={handleShouldShow}
                onMouseLeave={handleShouldHide}
              >
                {singleObj?.singleData?.name}
              </div>
            </Tooltip>
            <div></div>
          </div>
          <div className={styles["brand-detail-container__logo-container"]}>
            <img src={singleObj?.singleData?.productImageUrl} alt="" />
            <div
              className={
                styles["brand-detail-container__logo-container__texts"]
              }
            >
              <span>
                {t("validity")} {singleObj?.singleData?.validity}
              </span>
              <span>
                {singleObj?.singleData?.denominationRange?.currency}{" "}
                {singleObj?.singleData?.denominationRange?.min} -{" "}
                {singleObj?.singleData?.denominationRange?.currency}{" "}
                {singleObj?.singleData?.denominationRange?.max}
              </span>
            </div>
          </div>
          <div
            className={styles["brand-detail-container__denomination-section"]}
          >
            <img
              src="/assets/images/minus-icon.png"
              alt=""
              onClick={
                singleObj?.initialDenom > singleObj?.minDenom
                  ? handleDecrement
                  : undefined
              }
              className={
                singleObj?.initialDenom <= singleObj?.minDenom
                  ? styles["disabled"]
                  : ""
              }
            />
            <div
              className={
                styles[
                  "brand-detail-container__denomination-section__denom-value"
                ]
              }
            >
              <h4>{singleObj?.singleData?.denominationRange?.currency}</h4>
              <input
                type="number"
                value={singleObj?.initialDenom}
                onChange={handleDenomChange}
                pattern="\d+(\.\d+)?"
                step="any"
                ref={singleObj?.inputRef}
                readOnly={!singleObj?.isVariableDenom}
                style={{
                  width: String(singleObj?.initialDenom)?.length + "ch",
                }}
                max={Number(singleObj?.maxDenom)}
                min={Number(singleObj?.minDenom)}
                onKeyDown={onKeyDown}
              />
            </div>

            <img
              src="/assets/images/plus-icon.png"
              alt=""
              onClick={
                singleObj?.initialDenom < singleObj?.maxDenom
                  ? handleIncrement
                  : undefined
              }
              className={
                singleObj?.initialDenom >= singleObj?.maxDenom
                  ? styles["disabled"]
                  : ""
              }
            />
          </div>
          <div className={styles["brand-detail-container__amount-selection"]}>
            {singleObj?.denominations &&
              singleObj?.denominations.length > 0 &&
              singleObj?.denominations.map((item: any, index: any) => (
                <div key={index}>
                  <div
                    className={`${
                      styles[
                        "brand-detail-container__amount-selection__single-item"
                      ]
                    } ${
                      Number(item?.amount) === singleObj?.initialDenom &&
                      styles["active"]
                    }`}
                    key={index}
                    onMouseEnter={() =>
                      handleMouseEnter(index, Number(item?.amount))
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(index, Number(item?.amount))
                    }
                    onClick={() =>
                      handleDenominationClick(Number(item?.amount))
                    }
                  >
                    <div>{item?.currency}</div>
                    <div style={{ fontSize: "16px" }}>{item?.amount}</div>
                  </div>
                </div>
              ))}
            {singleObj?.isVariableDenom && (
              <div
                className={`${
                  styles["brand-detail-container__amount-selection__other"]
                } ${singleObj?.isOtherActive && styles["active"]}`}
                onClick={onClickOther}
              >
                {t("other")}
              </div>
            )}
          </div>
          {singleObj?.errorText ? (
            <div className={styles["brand-detail-container__error-container"]}>
              <img src="/assets/images/brand-denomination-error.png" alt="" />
              <p>{singleObj?.errorText}</p>
            </div>
          ) : singleObj?.balanceError ? (
            <div className={styles["brand-detail-container__error-container"]}>
              <img src="/assets/images/brand-denomination-error.png" alt="" />
              <p>{singleObj?.balanceError}</p>
            </div>
          ) : (
            ""
          )}
          <BrandDetailAccordion
            description={singleObj?.singleData?.description}
            terms={singleObj?.singleData?.termsAndConditions}
            locations={singleObj?.singleData?.location}
          />
        </div>
      </div>

      <div className={styles["brand-detail-container__checkout-button"]}>
        <Button
          variant="contained"
          fullWidth
          onClick={() => setOpen(true)}
          disabled={
            singleObj?.errorText.length > 0 || singleObj?.initialDenom === 0
          }
        >
          {t("checkout")}
        </Button>
      </div>
      {open && (
        <CheckoutModal
          amount={singleObj?.initialDenom?.toString()}
          currency={singleObj?.singleData?.denominationRange?.currency}
          brandName={singleObj?.singleData?.name}
          onClose={handleClose}
          brandCode={singleObj?.singleData?.code}
          handleError={handleBalanceError}
        />
      )}
    </>
  );
};

export default BrandDetaingMobile;
