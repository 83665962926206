import { Backdrop } from "@mui/material";
import React, { FC } from "react";
import styles from "./ResendSuccessModal.module.scss";
import { useTranslation } from "react-i18next";

const ResendSuccessModal: FC<{
  onClose?: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <div className="resend-modal-container">
      <Backdrop open={true}>
        <div className={styles["resend-modal"]}>
          <div className={styles["resend-modal__header"]}>
            <div>{t("successfullyResend")}</div>
            <div onClick={onClose}>
              <img src="/assets/images/resend-close.png" alt="" />
            </div>
          </div>
          <div className={styles["resend-modal__contents"]}>
            {t("successfullyResendText")}
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default ResendSuccessModal;
