import React, { FC } from "react";
import styles from "./CorporateSelectionFooter.module.scss";

const Footer: FC = () => {
  return (
    <div className={styles["footer-container"]}>
      <div className={styles["footer-container__contents"]}>
        <div className={styles["footer-container__contents__text"]}>
          Powered by
        </div>
        <img src="/assets/images/ygag-logo.png" alt="ygag-logo" />
      </div>
    </div>
  );
};

export default Footer;
