import { useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../utils/hooks";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import NotFoundPage from "../404/NotFound";
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../constants/common";
import { getAccessToken } from "../../features/common/commonSlice";
import { GIFT_DETAILS_QUERY } from "../../features/viewGiftcard/viewGiftcard.query";
import ViewGiftcardMobileSkeleton from "../../features/viewGiftcard/mobile/skeleton/ViewGiftcardMobileSkeleton";
import ViewGiftcardTabletSkeleton from "../../features/viewGiftcard/tablet/skeleton/ViewGiftcardTabletSkeleton";
import ViewGiftcardContentSkeleton from "../../features/viewGiftcard/web/skeleton/ViewGiftcardContentSkeleton";
import ViewGiftcardMobile from "../../features/viewGiftcard/mobile/ViewGiftcardMobile";
import ViewGiftcardTablet from "../../features/viewGiftcard/tablet/ViewGiftcardTablet";
import ViewGiftcardContent from "../../features/viewGiftcard/web/ViewGiftcardContent";

const ViewGiftcard: FC = () => {
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);
  const isMobileDevice = useMediaQuery(MOBILE_MAX_WIDTH);
  const { accessToken } = useAppSelector(getAccessToken);

  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const params = useParams();
  const giftId = params.giftid;
  const {
    data: giftDetailData,
    error: giftDetailError,
    loading: giftDetailLoading,
  } = useQuery(GIFT_DETAILS_QUERY, {
    variables: {
      giftId: giftId,
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });
  const singleData = giftDetailData?.giftDetails?.data;

  return (
    <div>
      {giftDetailLoading ? (
        isMobileDevice ? (
          <ViewGiftcardMobileSkeleton />
        ) : isTabletDevice ? (
          <ViewGiftcardTabletSkeleton />
        ) : (
          <div>
            <ViewGiftcardContentSkeleton />
          </div>
        )
      ) : giftDetailError ? (
        <div>
          <NotFoundPage />
        </div>
      ) : isMobileDevice ? (
        <ViewGiftcardMobile singleData={singleData} />
      ) : isTabletDevice ? (
        <ViewGiftcardTablet singleData={singleData} />
      ) : (
        <ViewGiftcardContent singleData={singleData} />
      )}
    </div>
  );
};

export default ViewGiftcard;
