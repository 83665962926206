import { gql } from "@apollo/client";


export const GET_CREDIT_HISTORY_QUERY = gql`
  query creditHistory($startDate: String!, $endDate: String!) {
    creditHistory(startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          id
          amountInBaseCurrency
          createdOn
          currency
          expirationDate
          promotion
        }
        cursor
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
