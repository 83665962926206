import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./CartSummary.module.scss";
import CartSummarySkeleton from "./skeleton/CartSummarySkeleton";

const items = [
  {
    id: 1,
    logo: "/assets/images/brandlogo.png",
    brand: " Virgin Megastore",
    price: 250,
  },
  {
    id: 2,
    logo: "/assets/images/brandlogo.png",
    brand: "Nike",
    price: 550,
  },
  {
    id: 3,
    logo: "/assets/images/brandlogo.png",
    brand: " Adidas",
    price: 650,
  },
  {
    id: 4,
    logo: "/assets/images/brandlogo.png",
    brand: "H&M",
    price: 850,
  },
];
const CartSummary = () => {
  const [loading, setLoading] = useState(true);

  const [totalItems, setTotalItems] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      let itemsCount = 0;
      let itemsCost = 0;

      items.forEach((item) => {
        itemsCount++;
        itemsCost += item.price;
      });

      setTotalItems(itemsCount);
      setTotalCost(itemsCost);

      setLoading(false);
    }, 5000);
  }, []);
  return (
    <>
      {loading ? (
        <>
          <CartSummarySkeleton />
        </>
      ) : (
        <div className={styles["order-summary-container"]}>
          <div className={styles["order-summary-container__heading"]}>
            {t("orderSummary")}
          </div>
          <div className={styles["order-summary-container__inner-container"]}>
            <div
              className={
                styles[
                  "order-summary-container__inner-container__totalitem-container"
                ]
              }
            >
              <div
                className={
                  styles[
                    "order-summary-container__inner-container__totalitem-container__items"
                  ]
                }
              >
                {t("totalItems")}
              </div>
              <div
                className={
                  styles[
                    "order-summary-container__inner-container__totalitem-container__qty"
                  ]
                }
              >
                {totalItems} {t("items")}
              </div>
            </div>
            <div
              className={
                styles["order-summary-container__inner-container__container"]
              }
            >
              <div
                className={
                  styles[
                    "order-summary-container__inner-container__container__total-gift-value"
                  ]
                }
              >
                {t("totalGiftValue")}
              </div>
              <div
                className={
                  styles[
                    "order-summary-container__inner-container__container__price"
                  ]
                }
              >
                AED {totalCost}
              </div>
            </div>
          </div>
          <div className={styles["order-summary-container__total"]}>
            <div>{t("total")}</div>
            <div>AED {totalCost}</div>
          </div>
          <div className={styles["order-summary-container__button-div"]}>
            <button
              className={
                styles["order-summary-container__button-div__btn-check"]
              }
            >
              {t("proceedToCheckout")}
            </button>
            <button
              className={
                styles["order-summary-container__button-div__btn-continue"]
              }
            >
              {t("continueShopping")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CartSummary;
