import { Button, CircularProgress } from "@mui/material";
import React, { FC } from "react";
import styles from "./userDetailsBox.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../constants/pageUrls";

interface ProfileBoxProps {
  isDashboard?: boolean;
  data?: any;
  handleLogout: () => void;
  logoutLoading?: boolean;
  handleClose?: (event: any) => void;
}

const UserDetailsBox: FC<ProfileBoxProps> = ({
  isDashboard,
  data,
  handleLogout,
  logoutLoading,
  handleClose,
}) => {
  const containerHeight = isDashboard ? "302px" : "267px";
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`/${currentLang}/${PAGE_URLS.CORPORATE_SELECTION}`);
  };

  const redirectToProfile = (event: any) => {
    handleClose?.(event);
    navigate(`/${currentLang}/${PAGE_URLS.PROFILE}`);
  };

  return (
    <div className={styles["dropdown"]} style={{ height: containerHeight }}>
      <div className={styles["dropdown__top"]}>
        <div className={styles["dropdown__top__header"]}>
          {t("poweredByYgag")}
        </div>
      </div>
      <div className={styles["dropdown__middle"]}>
        <div className={styles["dropdown__middle__user-details"]}>
          <div className={styles["dropdown__middle__user-details__text"]}>
            {t("userDetails")}
          </div>
        </div>
        <div className={styles["dropdown__middle__details-box"]}>
          <div className={styles["dropdown__middle__details-box__rounded-box"]}>
            <div
              className={
                styles["dropdown__middle__details-box__rounded-box__text"]
              }
            >
              {data?.data?.name
                ? data?.data?.name?.charAt(0)?.toUpperCase()
                : data?.data?.username?.charAt(0)?.toUpperCase()}
            </div>
            <div
              className={
                styles["dropdown__middle__details-box__rounded-box__green-tick"]
              }
            >
              <img src="/assets/images/green-tick.png" alt="green-tick" />
            </div>
          </div>
          <div className={styles["dropdown__middle__details-box__details"]}>
            <h3>{data?.data?.name}</h3>
            <h4>{data?.data?.username}</h4>
          </div>
        </div>
        {isDashboard && data?.data?.enable_corporate_selection && (
          <div className={styles["dropdown__middle__switch-button"]}>
            <Button variant="contained" onClick={handleRedirect}>
              {t("switchAccount")}
            </Button>
          </div>
        )}
      </div>

      <hr />
      <div className={styles["dropdown__bottom"]}>
        {isDashboard && (
          <div
            className={styles["dropdown__bottom__change-password"]}
            onClick={redirectToProfile}
          >
            {t("changePassword")}
          </div>
        )}
        <div className="corporate-selection-user-details-button-container">
          <Button
            className="sign-out-btn"
            onClick={handleLogout}
            startIcon={logoutLoading ? <CircularProgress size={20} /> : null}
          >
            {logoutLoading ? "" : t("signOut")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserDetailsBox;
