import { useEffect, useState } from 'react';
import styles from './ProgressiveImg.module.scss';

interface PropsInterface {
  placeHolderSrc: string;
  src: string;
  alt?: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
}

const ProgressiveImg = ({ placeHolderSrc, src, ...props }: PropsInterface) => {
  const [imgSrc, setImgSrc] = useState(placeHolderSrc);
  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
    };
  }, [src]);
  return (
    <img
      {...{ src: imgSrc, ...props }}
      alt={props?.alt || ''}
      className={` ${styles['progressiveImg']}  ${
        imgSrc === placeHolderSrc
          ? styles['progressiveImg__loading']
          : styles['progressiveImg__loaded']
      }`}
    />
  );
};
export default ProgressiveImg;
