import React, { FC, useState } from "react";
import { useLocation } from "react-router-dom";
import ForgotPasswordStepOne from "../../features/forgotPassword/ForgotPasswordStepOne";
import ForgotPasswordStepThree from "../../features/forgotPassword/ForgotPasswordStepThree";
import ForgotPasswordStepTwo from "../../features/forgotPassword/ForgotPasswordStepTwo";
import AuthLayout from "../../features/common/layouts/authLayout/AuthLayout";

const ForgotPassword: FC = () => {
  const [steps, setSteps] = useState(0);
  const [showTimerOnMount, setShowTimerInitialOnMount] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("corp_slug");

  const [userName, setUserName] = useState(""); // setting this state here to autopopulate the user in steptwo;

  const handleStoreUserName = (username: string) => {
    setUserName(username);
  };

  const handleChangeSteps = () => {
    setSteps((prev) => prev + 1);
  };

  const handleShowTimer = () => {
    setShowTimerInitialOnMount(true);
  };

  const setToInitialState = () => {
    setSteps(0);
  };

  return (
    <AuthLayout>
      {(() => {
        switch (steps) {
          case 0:
            return (
              <ForgotPasswordStepOne
                nextStep={handleChangeSteps}
                storeUserName={handleStoreUserName}
                handleShowTimer={handleShowTimer}
              />
            );
          case 1:
            return (
              <ForgotPasswordStepTwo
                userName={userName}
                slug={slug ?? ""}
                showTimerOnMount={showTimerOnMount}
                toInitialState={setToInitialState}
                nextStep={handleChangeSteps}
              />
            );
          case 2:
            return (
              <ForgotPasswordStepThree userName={userName} slug={slug ?? ""} />
            );
          default:
            return null;
        }
      })()}
    </AuthLayout>
  );
};

export default ForgotPassword;
