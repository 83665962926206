import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface ButtonProps {
  onClick?: () => void;
  to?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean; // Add the disabled prop
}

const ButtonAnchor: FC<ButtonProps> = ({
  onClick,
  to,
  style,
  children,
  className,
  disabled, // Destructure the disabled prop
  ...rest
}) => {
  const navigate = useNavigate();

  const buttonStyle: React.CSSProperties = {
    textDecoration: "none",
    cursor: disabled ? "not-allowed" : "pointer", // Set the cursor style based on disabled state
    opacity: disabled ? 0.6 : 1, // Adjust opacity based on disabled state
    ...style,
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    if (onClick) {
      onClick();
    }

    if (to) {
      event.preventDefault();
      navigate(to);
    }
  };

  return (
    <a
      href={to || "#"}
      onClick={handleClick}
      style={buttonStyle}
      {...rest}
      className={className}
    >
      {children}
    </a>
  );
};

export default ButtonAnchor;
