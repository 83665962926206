import { useQuery } from "@apollo/client";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PAGE_URLS from "../../constants/pageUrls";
import ActivationStepOne from "../../features/activation/ActivationStepOne";
import ActivationStepThree from "../../features/activation/ActivationStepThree";
import ActivationStepTwo from "../../features/activation/ActivationStepTwo";
import { showNotifierPopup } from "../../features/common/commonSlice";
import AuthLayout from "../../features/common/layouts/authLayout/AuthLayout";
import Loader from "../../features/common/loader/Loader";
import { VerifyUserStatusData } from "../../interfaces/common.interface";
import { useAppDispatch } from "../../utils/hooks";
import { VERIFY_USER_STATUS_QUERY } from "../../features/activation/activation.query";

const Activation: FC = () => {
  const { i18n, t } = useTranslation();
  const { uid } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const corporateSlug = searchParams.get("corp_slug");

  const navigate = useNavigate();
  const [activationStep, setActivationStep] = useState(0);
  const currentLang = i18n.language;
  const dispatch = useAppDispatch();
  const [showTimerOnMount, setShowTimerInitialOnMount] = useState(false);

  const swithActivationSteps = () => {
    setActivationStep((prev) => prev + 1);
  };

  const handleShowTimer = () => {
    setShowTimerInitialOnMount(true);
  };

  const toFinalScreen = () => {
    setActivationStep(2);
  };

  const toLoginScreen = () => {
    navigate(
      corporateSlug
        ? `/${currentLang}/${PAGE_URLS.LOGIN}?corp_slug=${corporateSlug}`
        : `/${currentLang}/${PAGE_URLS.LOGIN}`
    );
  };

  const showNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-sign-in-proceed"),
      })
    );
  };

  const { data, loading, error } = useQuery(VERIFY_USER_STATUS_QUERY, {
    variables: {
      corporateSlug: corporateSlug,
      signedUserName: uid,
    },
  });

  const results: VerifyUserStatusData = data;
  const is_Active = results?.verifyRedeemLink?.data?.is_active;
  const message = results?.verifyRedeemLink?.message;
  const splittedMessage = message?.split(".");
  const displayMessage = splittedMessage
    ? splittedMessage?.[0]
    : splittedMessage?.[0];

  if (loading) {
    return <Loader />;
  }

  if (error) {
    navigate(`/${PAGE_URLS.NOT_FOUND}`);
  }

  if (is_Active) {
    toLoginScreen();
    showNotifier(displayMessage);
  }

  return (
    <AuthLayout>
      {(() => {
        switch (activationStep) {
          case 0:
            return (
              <ActivationStepOne
                nextStep={swithActivationSteps}
                toFinalScreen={toFinalScreen}
                toLoginScreen={toLoginScreen}
                token={uid}
                slug={corporateSlug ?? ""}
                handleShowTimer={handleShowTimer}
              />
            );
          case 1:
            return (
              <ActivationStepTwo
                nextStep={swithActivationSteps}
                token={uid}
                showTimerOnMount={showTimerOnMount}
              />
            );
          case 2:
            return (
              <ActivationStepThree token={uid} slug={corporateSlug ?? ""} />
            );
          default:
            return null;
        }
      })()}
    </AuthLayout>
  );
};

export default Activation;
