import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { FC } from "react";
import { DatePicker } from "@mui/x-date-pickers";

interface DateRangePickerProps {
  label: string;
  handleChange?: (date: any) => void;
  value?: any;
  startDate?: any;
  endDate?: any;
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  label,
  handleChange,
  value,
  startDate,
  endDate,
}) => {
  return (
    <div className="history__date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            label={label}
            format="DD/MM/YYYY"
            onChange={handleChange}
            value={value}
            minDate={startDate}
            maxDate={endDate}
            slots={{
              openPickerButton: ({ onClick }) => (
                <button style={{ background: "white" }} onClick={onClick}>
                  <img src="/assets/images/calender-icon.png" alt="" />
                </button>
              ),
            }}
            disableFuture
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
};

export default DateRangePicker;
