import React, { FC, useState } from "react";
import styles from "./CreditHistory.module.scss";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useAppSelector } from "../../utils/hooks";
import { getAccessToken } from "../../features/common/commonSlice";
import { TABLET_MAX_WIDTH } from "../../constants/common";
import { Dayjs } from "dayjs";
import { GET_CREDIT_HISTORY_QUERY } from "../../features/creditHistory/creditHistory.query";
import DateRangePicker from "../../features/common/dateRangePicker/DateRangePicker";
import CreditHistorySkeleton from "../../features/creditHistory/skeleton/CreditHistorySkeleton";

const CreditHistory: FC = () => {
  const { accessToken } = useAppSelector(getAccessToken);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: any) => {
    setEndDate(date);
  };

  const { data, loading, error } = useQuery(GET_CREDIT_HISTORY_QUERY, {
    variables: {
      startDate: startDate?.format("DD-MM-YYYY") || "",
      endDate: endDate?.format("DD-MM-YYYY") || "",
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  if (error) {
    console.error("Error fetching credit history:", error);
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const rows = data?.creditHistory?.edges.map((item: any) => {
    return {
      promotion: item?.node?.promotion,
      amount: item?.node?.amountInBaseCurrency,
      creditDate: item?.node?.createdOn,
      expiryDate: item?.node?.expirationDate,
      currency: item?.node?.currency,
    };
  });

  // Calculate the index range for the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Slice the rows array to display only the current page
  const displayedRows = rows?.slice(startIndex, endIndex);

  // Event handler for changing the page
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  // Event handler for changing the number of rows per page
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  return (
    <div className={`${styles["credit-history-container"]} history-container`}>
      <div className={styles["credit-history-container__banner"]}>
        {isTabletDevice && <h4>{t("creditHistory")}</h4>}
      </div>
      <section className="wrapper">
        <div className={styles["credit-history-container__box"]}>
          <h4>{t("creditHistory")}</h4>
          <div
            className={styles["credit-history-container__box__date-pickers"]}
          >
            <DateRangePicker
              label={t("startDate") ?? ""}
              handleChange={handleChangeStartDate}
              value={startDate}
              endDate={endDate}
            />
            <p>{t("to")}</p>
            <DateRangePicker
              label={t("endDate") ?? ""}
              handleChange={handleChangeEndDate}
              value={endDate}
              startDate={startDate}
            />
          </div>
          {loading ? (
            <CreditHistorySkeleton />
          ) : data?.creditHistory?.edges?.length > 0 ? (
            <div className="credit-table-contents">
              <Table>
                <TableHead>
                  <TableRow className="table-header">
                    <TableCell width={"25%"}>{t("promotion")}</TableCell>
                    <TableCell width={"25%"}>{t("amount")}</TableCell>
                    <TableCell width={"25%"}>{t("creditDate")}</TableCell>
                    <TableCell width={"20%"}>{t("expiryDate")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="credit-table-body">
                  {displayedRows?.map((row: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <TableCell>
                        <Tooltip
                          title={row?.promotion}
                          placement="top-start"
                          arrow
                        >
                          <div className="credit-table-promotion">
                            {row?.promotion}{" "}
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {row.currency} {row.amount}
                      </TableCell>
                      <TableCell>{row.creditDate}</TableCell>
                      <TableCell>{row.expiryDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <div>{t("historyNoData")}</div>
          )}
        </div>
      </section>
    </div>
  );
};

export default CreditHistory;
