import { Snackbar } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showNotifierPopup } from "../commonSlice";
import styles from "./Notifier.module.scss";

const Notifier: FC<{
  titleHead?: string;
  titleContent?: string;
  showSnackBar?: boolean;
}> = ({ titleContent, titleHead, showSnackBar }) => {
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(showSnackBar);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsSnackbarOpen(showSnackBar);
    if (showSnackBar) {
      // Automatically hide the Snackbar after 3 seconds
      const timeout = setTimeout(() => {
        dispatch(
          showNotifierPopup({
            showNotifier: false,
            notifierHeader: "",
            notifierText: "",
          })
        );
      }, 3000);

      // Clean up the timeout on component unmount or when showSnackBar changes to false
      return () => clearTimeout(timeout);
    }
  }, [showSnackBar]);
  return (
    <div className="snackbar-component">
      <Snackbar
        open={isSnackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        className="snackBar-component"
      >
        <div className={`${styles["snackbar-container"]}`}>
          <div className={`${styles["success-logo"]}`}>
            <img src="/assets/images/success-tick.png" alt="success-tick" />
          </div>
          <div className={`${styles["success-texts"]}`}>
            <h3 className={`${styles["success-text-head"]}`}>{titleHead}</h3>
            <p className={`${styles["success-text-content"]}`}>
              {titleContent}
            </p>
          </div>
        </div>
      </Snackbar>
    </div>
  );
};

export default Notifier;
