import React, { FC } from "react";
import { MOBILE_MAX_WIDTH } from "../../constants/common";
import { useMediaQuery } from "@mui/material";
import ProfileSettingsMobile from "../../features/profile/mobile/profileSettingsMobile/ProfileSettingsMobile";
import ProfileSettingsBlock from "../../features/profile/profileSettingsBlock/ProfileSettingsBlock";

const Profile: FC = () => {
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  return (
    <div>{isMobile ? <ProfileSettingsMobile /> : <ProfileSettingsBlock />}</div>
  );
};

export default Profile;
