import { Button } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./PaymentSuccess.module.scss";
import { useAppSelector } from "../../utils/hooks";
import { getUserNameFromProfile } from "../../features/common/commonSlice";
import PAGE_URLS from "../../constants/pageUrls";
import { useNavigate, useParams } from "react-router-dom";
import ButtonAnchor from "../../features/common/ButtonAnchor";


const PaymentSuccess: FC = () => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const { userNameFromProfile } = useAppSelector(getUserNameFromProfile);

  const handleGoBack = () => {
    navigate(`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`);
  };
  const navigate = useNavigate();
  const params = useParams();

  const handleNavigate = () => {
    navigate(`/${currentLang}/${PAGE_URLS.VIEWGIFTCARD}/${params.giftId}`);
  };

  return (
    <div className={`${styles["payment-success-container"]}`}>
      <div className={styles["payment-success-container__inner-container"]}>
        <div
          className={
            styles["payment-success-container__inner-container__banner"]
          }
        ></div>
        <section className="wrapper">
          <div
            className={
              styles["payment-success-container__inner-container__success-box"]
            }
          >
            <div
              className={
                styles[
                  "payment-success-container__inner-container__success-box__back-home"
                ]
              }
            >
              <img src="/assets/images/left-arrow.png" alt="" />
              <ButtonAnchor onClick={handleGoBack}>
                {t("backToHome")}
              </ButtonAnchor>
            </div>
            <div
              className={
                styles[
                  "payment-success-container__inner-container__success-box__contents"
                ]
              }
            >
              <img src="/assets/images/payment-success-tick.png" alt="" />
              <h4>{t("purchaseSuccess")}</h4>
              <p>
                {t("purchaseSuccessText", { recipient: userNameFromProfile })}
              </p>
              <div className={styles["payment-success-page-buttons"]}>
                <Button
                  className="payment-success-continue-shopping-button"
                  variant="outlined"
                  onClick={handleGoBack}
                >
                  {t("continueShopping")}
                </Button>
                <Button
                  className="payment-success-view-giftcard-button"
                  variant="contained"
                  onClick={() => handleNavigate()}
                >
                  {t("viewGiftCard")}
                </Button>
              </div>
              <p>{t("purchaseSuccessNote")}</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PaymentSuccess;
