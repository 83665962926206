import React, { FC, MouseEvent, useState } from "react";
import styles from "./GiftContainer.module.scss";
import { CircularProgress, Tooltip } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";
import DashBoardWebSkeleton from "../../web/skeleton/DashBoardWebSkeleton";
import { t } from "i18next";

const GiftContainer: FC<{
  brands: any;
  fetchMoreBrands: () => void;
  hasMoreBrands: boolean;
  onChangeLoading: boolean;
}> = ({ brands, fetchMoreBrands, hasMoreBrands, onChangeLoading }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const handleRedirect = (brandCode: any) => {
    navigate(`/${currentLang}/${PAGE_URLS.BRANDS}/${brandCode}`);
  };
  const [tooltipStates, setTooltipStates] = useState<boolean[]>([]);

  const handleShouldShow = (
    { currentTarget }: MouseEvent<Element>,
    index: number
  ) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
    }
  };

  const handleShouldHide = (index: number) => () => {
    setTooltipStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  return (
    <InfiniteScroll
      dataLength={brands?.length}
      next={fetchMoreBrands}
      hasMore={hasMoreBrands}
      loader={<CircularProgress />}
      style={{ overflow: "unset", textAlign: "center" }}
    >
      <div className={styles["gift-container__box"]}>
        <div className={styles["gift-container"]}>
          {onChangeLoading ? (
            Array.from({ length: 12 }, (item, index) => (
              <DashBoardWebSkeleton key={index} />
            ))
          ) : brands && brands.length > 0 ? (
            brands.map((item: any, index: any) => (
              <div
                className={styles["gift-container__box__card"]}
                key={index}
                onClick={() => handleRedirect(item?.node?.brandCode)}
              >
                <div className={styles["gift-container__box__card__logo-box"]}>
                  <img src={item?.node?.productImageUrl} alt="" />
                </div>
                {item?.node?.offerText && (
                  <div className={styles["gift-container__box__card__offers"]}>
                    <img
                      src="/assets/images/offers-icon.png"
                      alt="offer-icon"
                    />
                    <p>{item?.node?.offerText}</p>
                  </div>
                )}

                <Tooltip
                  title={item?.node?.name}
                  placement="top"
                  open={tooltipStates[index] || false}
                >
                  <div
                    onMouseEnter={(e) => handleShouldShow(e, index)}
                    onMouseLeave={handleShouldHide(index)}
                    className={styles["gift-container__box__card__brand-name"]}
                  >
                    {item?.node?.name}
                  </div>
                </Tooltip>

                <div
                  className={styles["gift-container__box__card__brand-type"]}
                >
                  {item?.node?.shortTagline}
                </div>
                {item?.node?.isOnlineFriendly && (
                  <div
                    className={
                      styles["gift-container__box__card__online-friendly"]
                    }
                  >
                    {t("onlineFriendly")}
                  </div>
                )}
              </div>
            ))
          ) : (
            <div>No Brands to display!</div>
          )}
        </div>
      </div>
    </InfiniteScroll>
  );
};

export default GiftContainer;
