import React from "react";
import { Skeleton } from "@mui/material";

const RedemptionHistorySkeleton = () => {
  // Adjust the number of skeleton rows to match your table structure
  const skeletonRows = Array.from({ length: 5 }).map((_, index) => (
    <Skeleton key={index} height={50} animation="wave" />
  ));

  return (
    <div className="dashboard-wrapper">
      <div style={{ marginTop: 10 }}>{skeletonRows}</div>
    </div>
  );
};

export default RedemptionHistorySkeleton;
