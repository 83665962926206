import { useMediaQuery } from "@mui/material";
import React, { ChangeEvent, FC } from "react";
import LandingFilterTab from "../tablet/landingFilterTab/LandingFilterTab";
import GiftContainer from "./giftContainer/GiftContainer";
import GiftSelectionFilters from "./giftSelectionFilters/GiftSelectionFilters";
import { TABLET_MAX_WIDTH } from "../../../constants/common";

const GiftSelectionBox: FC<{
  handleCategoryOpen: () => void;
  handleCategoryClose: () => void;
  handleCategoryClick: (name: string, id: any, image: string) => void;
  showCategorySelector: boolean;
  selectedCategory: any;
  categories: any;
  handleBrandName: (event: ChangeEvent<HTMLInputElement>) => void;
  hasMoreBrands: boolean;
  brands: any;
  fetchMoreBrands: () => void;
  onChangeLoading: boolean;
  categoryLoading?: boolean;
}> = ({
  categories,
  handleCategoryClick,
  handleCategoryClose,
  handleCategoryOpen,
  selectedCategory,
  showCategorySelector,
  brands,
  fetchMoreBrands,
  handleBrandName,
  hasMoreBrands,
  categoryLoading,
  onChangeLoading,
}) => {
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);
  return (
    <section className="dashboard-wrapper">
      {isTabletDevice ? (
        <LandingFilterTab
          categories={categories}
          handleCategoryClick={handleCategoryClick}
          handleCategoryClose={handleCategoryClose}
          handleCategoryOpen={handleCategoryOpen}
          selectedCategory={selectedCategory}
          showCategorySelector={showCategorySelector}
          handleBrandName={handleBrandName}
          categoryLoading={categoryLoading}
        />
      ) : (
        <GiftSelectionFilters
          categories={categories}
          handleCategoryClick={handleCategoryClick}
          handleCategoryClose={handleCategoryClose}
          handleCategoryOpen={handleCategoryOpen}
          selectedCategory={selectedCategory}
          showCategorySelector={showCategorySelector}
          handleBrandName={handleBrandName}
          categoryLoading={categoryLoading}
        />
      )}
      <GiftContainer
        brands={brands}
        fetchMoreBrands={fetchMoreBrands}
        hasMoreBrands={hasMoreBrands}
        onChangeLoading={onChangeLoading}
      />
    </section>
  );
};

export default GiftSelectionBox;
