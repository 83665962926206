import React, { FC } from "react";
import { Skeleton, useMediaQuery } from "@mui/material";
import styles from "./TabletHeaderSkeleton.module.scss";
import { MOBILE_MAX_WIDTH } from "../../../../../constants/common";

const LandingHeaderTabSkeleton: FC = () => {
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  return (
    <div className={styles["header-tab-skeleton"]}>
      <div className={styles["header-tab-skeleton__stores"]}>
        <Skeleton
          variant="rectangular"
          width={isMobile ? 50 : 100}
          height={isMobile ? 30 : 50}
          animation="wave"
        />
      </div>
      <div className={styles["header-tab-skeleton__logo"]}>
        <Skeleton
          variant="rectangular"
          width={isMobile ? 50 : 100}
          height={isMobile ? 30 : 50}
          animation="wave"
        />
      </div>
      <div className={styles["header-tab-skeleton__hamburger"]}>
        <Skeleton
          variant="rectangular"
          width={isMobile ? 50 : 100}
          height={isMobile ? 30 : 50}
          animation="wave"
        />
      </div>
    </div>
  );
};

export default LandingHeaderTabSkeleton;
