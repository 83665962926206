import { useTranslation } from "react-i18next";

import styles from "./TooManyRequest.module.scss";
import { FC } from "react";

const TooManyRequest: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["error-container"]}>
      <div className={styles["error-container__inner"]}>
        <img
          className={styles["error-container__inner__img"]}
          src="/assets/images/429.png"
          alt=""
        />
        <div className={styles["error-container__inner__text-container"]}>
          <div
            className={
              styles["error-container__inner__text-container__error-number"]
            }
          >
            429
          </div>
          <div
            className={
              styles["error-container__inner__text-container__error-request"]
            }
          >
            {t("oopsRequests")}
          </div>
          <div
            className={
              styles["error-container__inner__text-container__error-details"]
            }
          >
            {t("tooManyRequests")}
          </div>
        </div>
      </div>
      <div className={styles["error-container__footer"]}>
        © 2013 - 2023 YouGotaGift.com Ltd. -{" "}
        <span className={styles["error-container__footer__privacy-text"]}>
          {t("privacyPolicy")} | {t("termsOfUse")}
        </span>
      </div>
    </div>
  );
};

export default TooManyRequest;
