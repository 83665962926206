import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login(
    $username: String!
    $password: String!
    $corporateSlug: String!
  ) {
    login(
      username: $username
      password: $password
      corporateSlug: $corporateSlug
    ) {
      message
      status
      accessToken
      data
    }
  }
`;

export const LOGIN_MUTATION_NO_CORPORATE_SLUG = gql`
  mutation loginNoCorporateSlug($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      message
      status
      accessToken
      data
    }
  }
`;

export const VERIFY_OTP_LOGIN_MUTATION = gql`
  mutation verifyOtp(
    $otp: String!
    $username: String!
    $corporateSlug: String!
  ) {
    verifyLoginOtp(
      otp: $otp
      username: $username
      corporateSlug: $corporateSlug
    ) {
      message
      status
      accessToken
      data
    }
  }
`;

export const VERIFY_OTP_LOGIN_NO_SLUG_MUTATION = gql`
  mutation verifyOtp($otp: String!, $username: String!) {
    verifyLoginOtp(otp: $otp, username: $username) {
      message
      status
      accessToken
      data
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshTokenMutation {
    refreshToken {
      message
      status
      data
      accessToken
    }
  }
`;
