// store.ts
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import notifierReducer from "../features/common/commonSlice";
import storeUserNameReducer from "../features/common/commonSlice";
import storeAccessTokenReducer from "../features/common/commonSlice";
import storeUserNameFromProfile from "../features/common/commonSlice";
import storeSelectedStore from "../features/common/commonSlice";
import storeUserBalance from "../features/common/commonSlice";

export const store = configureStore({
  reducer: {
    notifier: notifierReducer,
    setUserName: storeUserNameReducer,
    setAccessToken: storeAccessTokenReducer,
    setUserNameFromProfile: storeUserNameFromProfile,
    setSelectedStore: storeSelectedStore,
    setUserBalance: storeUserBalance,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
