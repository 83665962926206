import { gql } from "@apollo/client";

export const STORE_LISTING_QUERY = gql`
  query StoreList {
    storeList {
      totalCount
      edges {
        node {
          isDefault
          id
          country {
            flagIcon
          }
          name
          code
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const CATEGORY_LISTING_QUERY = gql`
  query CategoryList {
    categoryList {
      edges {
        node {
          code
          name
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const BRAND_LISTING_QUERY = gql`
  query BrandList(
    $storeCode: String!
    $categoryCode: String!
    $brandName: String!
    $after: String!
    $first: Int!
  ) {
    brandList(
      storeCode: $storeCode
      categoryCode: $categoryCode
      brandName: $brandName
      after: $after
      first: $first
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          name
          brandCode
          shortTagline
          productImageUrl
          offerText
          isOnlineFriendly
        }
      }
    }
  }
`;

export const GET_BANNERS_QUERY = gql`
  query GetBanners($language: String!) {
    banners(language: $language) {
      name
      image
      url
      orderNumber
      startDate
      endDate
    }
  }
`;
