import { useMutation } from "@apollo/client";
import { Button, useMediaQuery, CircularProgress } from "@mui/material";
import { TextField } from "@mui/material";
import React, { FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { VerifyUserData } from "../../interfaces/common.interface";
import { useAppDispatch } from "../../utils/hooks";
import { showNotifierPopup, setUserName } from "../common/commonSlice";
import { useNavigate } from "react-router-dom";
import { SM_MAX_WIDTH } from "../../constants/common";
import { VERIFY_ACCOUNT_MUTATION } from "./activation.query";

const ActivationStepOne: FC<{
  nextStep: () => void;
  toFinalScreen: () => void;
  toLoginScreen: () => void;
  token?: string;
  slug?: string;
  handleShowTimer: () => void;
  showTimerOnMount?: boolean;
}> = ({
  nextStep,
  toLoginScreen,
  toFinalScreen,
  token,
  slug,
  handleShowTimer,
}) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ email: "" });
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(SM_MAX_WIDTH);
  const responsiveFieldsMargin = isMobile ? "20px" : "30px";
  const { t, i18n } = useTranslation();
  const [graphQlError, setGraphQlError] = useState("");
  const currentLang = i18n.language;

  const [verifyAccount, { loading }] = useMutation(VERIFY_ACCOUNT_MUTATION);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setEmail(value);

    if (email) {
      setError({ email: "" });
    }
  };

  const handleClearError = () => {
    // Clear the error state when mouse leaves the text field
    setError({ email: "" });
    setGraphQlError("");
  };

  const showOtpNotifier = () => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: `${t("otp-success-sent")}`,
        notifierText: `${t("pls-enter-email-otp")}`,
      })
    );
  };

  const showActivationNotifier = (header: string, text?: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-sign-in-proceed"),
      })
    );
  };

  const validateFields = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let errors = { email: "" };
      let hasError = false;

      if (!email) {
        // Check if email is empty
        hasError = true;
        errors = {
          ...errors,
          email: t("emailOrMobileRequired"),
        };
      }

      hasError ? reject(errors) : resolve(email);
    });
  };

  const handleRedirection = (isActive: boolean, is2faEnabled: boolean) => {
    if (isActive) {
      toLoginScreen?.();
    } else if (is2faEnabled) {
      nextStep?.();
      handleShowTimer?.(); // for setting the state which is responsible for showing the timer in ActivationStepTwo initially;
    } else {
      toFinalScreen?.();
    }
  };
  const navigate = useNavigate();
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      const values = await validateFields();
      const { data } = await verifyAccount({
        variables: {
          username: values,
          signedUserName: token,
          corporateSlug: slug,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      const results: VerifyUserData = data;
      const message = results?.verifyAccount?.message;
      const splittedMessage = message.split(".");
      const displayMessage = splittedMessage[0];
      const is_2fa_enabled = results?.verifyAccount?.data?.is_2fa_enabled;
      const is_active = results?.verifyAccount?.data?.is_active;
      handleRedirection?.(is_active, is_2fa_enabled);
      is_active && showActivationNotifier(displayMessage);
      is_2fa_enabled && showOtpNotifier();
      dispatch(setUserName(email)); //setting this userName in a global state for accessing in ActivationStepThree;
    } catch (err: any) {
      const graphQlError = err && err.graphQLErrors && err.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setError(err);
      }
    }
  };

  return (
    <div className="activation-form">
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          placeholder={String(t("enter-redt-email"))}
          color="info"
          sx={{ marginBottom: responsiveFieldsMargin }}
          onChange={handleEmailChange}
          helperText={!email ? error.email : graphQlError}
          error={!!error.email || !!graphQlError}
          onBlur={handleClearError}
          inputProps={{ maxLength: 255 }}
        />
        <Button
          variant="contained"
          type="submit"
          className="auth-button"
          sx={{ marginBottom: "60px" }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("continue")}
        </Button>
      </form>
    </div>
  );
};

export default ActivationStepOne;
