import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import '../../../i18n'
import PageLoader from "../pageLoader/PageLoader";

interface TransalationProviderProps {
  lang?: string;
  children: JSX.Element;
}

const TransalationProvider = ({
  lang,
  children,
}: TransalationProviderProps) => {
  const { ready, i18n } = useTranslation();
  const currentLang = i18n.language;

  useLayoutEffect(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  if (!ready) {
    return <PageLoader />;
  }
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: currentLang,
          dir: currentLang === "ar" ? "rtl" : "ltr",
        }}
      />
      {children}
    </>
  );
};

export default TransalationProvider;
