import { ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import { createTheme } from "@mui/material/styles";
import { arSA, enUS } from "@mui/material/locale";
import theme from "../../../theme";
import { FCC } from "../../../interfaces/common.interface";

const ltrTheme = createTheme(
  {
    ...theme,
    direction: "ltr",
    typography: {
      fontFamily: `"Roboto", sans-serif, "Segoe UI", roboto, oxygen, ubuntu,
      cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;`,
    },
  },
  enUS
);

const rtlTheme = createTheme(
  {
    ...theme,
    direction: "rtl",
    typography: {
      fontFamily: ` "Cairo", sans-serif, "Segoe UI", roboto, oxygen, ubuntu,
      cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;`,
    },
  },
  arSA
);

// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
  prepend: true,
});

const RTL: FCC = (props) => {
  return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
};

const MUIThemeProvider: FCC = ({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  if (language === "ar") {
    return (
      <RTL>
        <ThemeProvider theme={rtlTheme}>{children}</ThemeProvider>
      </RTL>
    );
  }
  return <ThemeProvider theme={ltrTheme}>{children}</ThemeProvider>;
};

export default MUIThemeProvider;
