import React, { FC } from "react";
import styles from "./WebCategorySelector.module.scss";

interface CategorySelectorProps {
  handleCategoryClick?: (name: string, id: any, image?: any) => void;
  categories?: any;
}

const WebCategorySelector: FC<CategorySelectorProps> = ({
  handleCategoryClick,
  categories,
}) => {
  return (
    <div
      className={styles["category-selector-container"]}
      id="scrollable-container"
    >
      {categories &&
        categories.length > 0 &&
        categories.map((item: any, index: any) => (
          <div
            className={styles["category-selector-container__category-section"]}
            key={index}
            onClick={() =>
              handleCategoryClick?.(item?.node?.name, item?.node?.code)
            }
          >
            <div>{item?.node?.name}</div>
          </div>
        ))}
    </div>
  );
};

export default WebCategorySelector;
