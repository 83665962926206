import { Drawer, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FCC } from "../../../../interfaces/common.interface";
import HelpLine from "../../helpline/web/HelpLine";
import styles from "./AuthLayout.module.scss";
import MobileHelpline from "../../helpline/mobile/MobileHelpline";
import ProgressiveImg from "../../progressiveImage/ProgressiveImg";
import { useAppSelector } from "../../../../utils/hooks";
import Notifier from "../../notifier/Notifier";
import { NotifierSelector } from "../../commonSlice";
import { useMutation } from "@apollo/client";
import Loader from "../../loader/Loader";
import PAGE_URLS from "../../../../constants/pageUrls";
import { REFRESH_TOKEN_MUTATION } from "../../../userLogin/userLogin.query";

const AuthLayout: FCC = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("corp_slug");
  const isNotMobile = useMediaQuery("(min-width:480px)");
  const selectLnag = i18n.language === "ar" ? "en" : "ar";
  const currentLang = i18n.language;
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { notifierHeader, notifierText, showNotifier } =
    useAppSelector(NotifierSelector);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    const newUrl: string =
      pathname.replace(`/${params.locale}`, `/${lng}`) + search;

    navigate(newUrl, { replace: true });
  };

  const [refreshTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await refreshTokenMutation();
        setIsAuthenticating(false);
        if (slug) {
          navigate(`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`);
        } else {
          navigate(`/${currentLang}/${PAGE_URLS.CORPORATE_SELECTION}`);
        }
      } catch (error) {
        setIsAuthenticating(false);
      }
    };
    fetchData();
  }, []);

  if (isAuthenticating) {
    return <Loader />;
  }

  return (
    <section className={`wrapper ${styles["authLayout"]}`}>
      <div className={`${styles["authLayout__container"]}`}>
        <div className={`${styles["authLayout__container__left"]}`}>
          <ProgressiveImg
            src="/assets/images/authImg.png"
            placeHolderSrc="/assets/images/placeholder-authImg.png"
          />
        </div>
        <div className={`${styles["authLayout__container__right"]}`}>
          <div className={`${styles["authLayout__container__right__sub"]}`}>
            <div className={`${styles["auth-logo-header"]}`}>
              <div className={`${styles["auth-logo-header__logo-container"]}`}>
                <img src={"/assets/images/ygag-logo.png"} alt="auth-img" />
              </div>
              {!isNotMobile ? (
                <div
                  className={`${styles["auth-logo-header__mobile-helpline-container"]}`}
                >
                  <span
                    className={`${styles["auth-logo-header__text"]}`}
                    onClick={() => changeLanguage(selectLnag)}
                  >
                    {" "}
                    {i18n.language === "en" ? "العربية" : "English"}
                  </span>
                  <img
                    src="/assets/images/helpline_call-icon.png"
                    alt="helpline-icon"
                    onClick={handleDrawerOpen}
                  />
                </div>
              ) : (
                <p
                  className={`${styles["auth-logo-header__text"]}`}
                  onClick={() => changeLanguage(selectLnag)}
                >
                  {i18n.language === "en" ? "العربية" : "English"}
                </p>
              )}
            </div>
            <div
              className={`${styles["authLayout__container__right__outlet"]}`}
            >
              {!isNotMobile && (
                <img src="/assets/images/mobile-auth-banner.png" alt="" />
              )}
              <div
                className={`${styles["authLayout__container__right__outlet__container"]}`}
              >
                {children}
                {isNotMobile && <HelpLine />}
              </div>
            </div>
          </div>
          <Notifier
            showSnackBar={showNotifier}
            titleHead={notifierHeader}
            titleContent={notifierText}
          />
        </div>
      </div>
      {!isNotMobile && (
        <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={handleDrawerClose}
          transitionDuration={300}
          className="mobile-helpline-drawer"
        >
          <MobileHelpline />
        </Drawer>
      )}
    </section>
  );
};

export default AuthLayout;
