import { gql } from "@apollo/client";

export const BRAND_DETAILING_QUERY = gql`
  query BrandDetails($code: String!) {
    brandDetails(code: $code) {
      message
      status
      data {
        name
        code
        offers
        currencyDecimalNotation
        productImageUrl
        description
        validity
        termsAndConditions
        redemptionType
        allowsVariableDenominations
        denominationRange {
          currency
          min
          max
        }
        denominations {
          amount
          isActive
          isDefault
          currency
        }
        location {
          address
          city
          contactNumber
        }
      }
    }
  }
`;

export const CONFIRM_PAYMENT_MUTATION = gql`
  mutation GiftPurchase(
    $currency: String!
    $amountInCurrency: String!
    $brandCode: String!
  ) {
    giftPurchase(
      input: {
        currency: $currency
        amountInCurrency: $amountInCurrency
        brandCode: $brandCode
      }
    ) {
      message
      status
      data
    }
  }
`;
