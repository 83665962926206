import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./HelpLine.module.scss";

const HelpLine: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={`${styles["helpline-footer"]}`}>
      <h4 className={`${styles["helpline-footer--heading"]}`}>
        {t("helpline")}
      </h4>
      <div className={`${styles["helpline-footer--phonenumbers"]}`}>
        <div className={`${styles["helpline-footer--phonenumber"]}`}>
          <div className={`${styles["helpline-footer--phonenumber-country"]}`}>
            <img src="/assets/images/uae-flag.png" alt="National Flag" />
            <span>UAE</span>
          </div>
          <a
            href={`tel:+971 4 4418473`}
            className={`${styles["helpline-footer--phonenumber-link"]}`}
          >
            <i className="icon icon-phone"></i>
            +971 4 441 8473
          </a>

          <div
            className={`${styles["helpline-footer--phonenumber-description"]}`}
            dangerouslySetInnerHTML={{
              __html: `${t("everyday")}&lrm; 9AM-10PM`,
            }}
          ></div>
        </div>
        <div className={`${styles["helpline-footer--phonenumber"]}`}>
          <div className={`${styles["helpline-footer--phonenumber-country"]}`}>
            <img src="/assets/images/sa-flag.png" alt="National Flag" />
            <span>KSA</span>
          </div>
          <a
            href={`tel:+971 4 4418473`}
            className={`${styles["helpline-footer--phonenumber-link"]}`}
          >
            <i className="icon icon-phone"></i>
            +966 9200 00175
          </a>

          <div
            className={`${styles["helpline-footer--phonenumber-description"]}`}
            dangerouslySetInnerHTML={{
              __html: `${t("everyday")}&lrm; 9AM-9PM`,
            }}
          ></div>
        </div>
        <div
          data-testid={`help-line-item`}
          className={`${styles["helpline-footer--phonenumber"]}`}
        >
          <div className={`${styles["helpline-footer--phonenumber-country"]}`}>
            <img src="/assets/images/kw-flag.png" alt="National Flag" />
            <span>KW</span>
          </div>
          <a
            href={`tel:+971 4 4418473`}
            className={`${styles["helpline-footer--phonenumber-link"]}`}
          >
            <i className="icon icon-phone"></i>
            +965 22 20 4519
          </a>

          <div
            className={`${styles["helpline-footer--phonenumber-description"]}`}
            dangerouslySetInnerHTML={{
              __html: `${t("everyday")}&lrm; 9AM-9PM`,
            }}
          ></div>
        </div>
      </div>
      <div className={`${styles["helpline-footer--email"]}`}>
        <img src="/assets/images/mail-icon.png" alt="mail-icon" />
        <a href="help@yougotagift.com">help@yougotagift.com</a>
      </div>
    </div>
  );
};

export default HelpLine;
