import React, { FC } from "react";
import styles from "./PageNotFound.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFound: FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const backHome = () => {
    navigate(`/${currentLanguage}/dashboard`);
  };
  return (
    <div className={styles["error-container"]}>
      <div className={styles["error-container__inner"]}>
        <div className={styles["error-container__inner__imoji-container"]}>
          <img src="/assets/images/404-logo.png" alt="" />
        </div>
        <div className={styles["error-container__inner__error-contents"]}>
          <p>{t("errorCode404")}</p>
          <h4>{t("pageNotFound")}</h4>
          <p>{t("weCouldntFind")}</p>
        </div>
        <div className={styles["error-container__inner__back-button"]}>
          <Button variant="contained" onClick={backHome}>
            {t("backToHome")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
