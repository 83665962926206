import { Skeleton } from "@mui/material";
import styles from "./CartProductsSkeleton.module.scss";
import { useTranslation } from "react-i18next";
const CartProducts = () => {
  const loopCount = 4;
  const { t } = useTranslation();
  return (
    <div className={styles["left-container"]}>
      <div className={styles["left-container__heading"]}>
        {t("orderDetails")}
      </div>
      {[...Array(loopCount)].map((item) => (
        <div key={item}>
          <div className={styles["left-container__logo-container"]}>
            <div
              className={styles["left-container__logo-container__logo-name"]}
            >
              <Skeleton
                variant="rectangular"
                width={48}
                height={48}
                sx={{ borderRadius: "10px" }}
              />

              <div
                className={
                  styles[
                    "left-container__logo-container__logo-name__logo-brand"
                  ]
                }
              >
                <Skeleton variant="text" width={123} height={19} />
              </div>
            </div>
            <div>
              <Skeleton variant="text" width={127} height={29} />
            </div>
          </div>
          <div className={styles["left-container__button-container"]}>
            <div
              className={
                styles[
                  "left-container__button-container__edit-remove-container"
                ]
              }
            >
              <Skeleton variant="text" width={80} height={30} />
              <Skeleton variant="text" width={80} height={30} />
            </div>

            <Skeleton variant="text" width={150} height={30} />
          </div>
        </div>
      ))}
      <div className={styles["left-container__line"]}></div>
    </div>
  );
};

export default CartProducts;
