import styles from "./ViewGiftcardMobile.module.scss";
import { useTranslation } from "react-i18next";
import ViewGiftcardAccordions from "../viewGiftcardAccordion/ViewGiftcardAccordions";
import { FC, useRef, useEffect, useState, MouseEvent } from "react";
import i18n from "../../../i18n";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";

const ViewGiftcardMobile: FC<{ singleData: any }> = ({ singleData }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentLang = i18n.language;

  const handleGoBack = () => {
    navigate(`/${currentLang}/dashboard`);
  };

  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>();
  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const handleShouldHide = () => {
    setTooltipEnabled(false);
  };

  return (
    <div
      className={`${styles["view-giftcard-container"]} view-giftcard-wrapper`}
    >
      <div className={styles["view-giftcard-container__left-section"]}>
        <div
          className={
            styles["view-giftcard-container__left-section__card-wrapper"]
          }
        >
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__info"
              ]
            }
          >
            <Tooltip
              title={singleData.brand_name}
              open={tooltipEnabled || false}
            >
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__info__brand-name"
                  ]
                }
                onMouseEnter={handleShouldShow}
                onMouseLeave={handleShouldHide}
              >
                {singleData.brand_name}
              </div>
            </Tooltip>

            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__validity"
                ]
              }
            >
              {t("expiryOn")} <strong>{singleData.expires_on}</strong>
            </div>
          </div>
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__logo"
              ]
            }
          >
            <img src={singleData.brand_logo} alt="" />
          </div>

          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__details"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__price"
                ]
              }
            >
              {singleData.currency} {singleData.amount}
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__barcode"
                ]
              }
            >
              <img
                width={254}
                height={58}
                src={singleData.barcode_url}
                alt=""
              />
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__details__barcode__digit"
                  ]
                }
              >
                {singleData.code}
              </div>
            </div>
            {singleData.pin_code && (
              <>
                <div
                  className={
                    styles[
                      "view-giftcard-container__left-section__card-wrapper__details__pin"
                    ]
                  }
                >
                  <span
                    className={
                      styles[
                        "view-giftcard-container__left-section__card-wrapper__details__pin__text"
                      ]
                    }
                  >
                    {t("pin")}{" "}
                  </span>
                  <span
                    className={
                      styles[
                        "view-giftcard-container__left-section__card-wrapper__details__pin__value"
                      ]
                    }
                  >
                    {singleData.pin_code}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={
            styles["view-giftcard-container__left-section__accordions"]
          }
        >
          <ViewGiftcardAccordions
            description={singleData.description}
            terms={singleData.terms_and_conditions}
            locations={singleData.locations}
          />
        </div>
        <div
          className={
            styles["view-giftcard-container__left-section__arrow-back"]
          }
        >
          <img
            src="../../assets/images/vector.png"
            alt=""
            onClick={handleGoBack}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewGiftcardMobile;
