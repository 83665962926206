import React, { FC } from "react";
import styles from "./PaymentFailure.module.scss";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PAGE_URLS from "../../constants/pageUrls";

const PaymentFailure: FC = () => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`);
  };

  return (
    <div className={`${styles["payment-failure-container"]}`}>
      <div className={styles["payment-failure-container__inner-container"]}>
        <div
          className={
            styles["payment-failure-container__inner-container__banner"]
          }
        ></div>
        <section className="wrapper">
          <div
            className={
              styles["payment-failure-container__inner-container__failure-box"]
            }
          >
            <img src="/assets/images/payment-failure-cross.png" alt="" />
            <h4>{t("somethingWrong")}</h4>
            <p>{t("transactionFailureText")}</p>
            <Button variant="outlined" onClick={handleGoBack}>
              {t("goBack")}
            </Button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PaymentFailure;
