import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./MobileHelpline.module.scss";

const MobileHelpline: FC = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles["helpline-container"]}`}>
      <div className={styles["helpline-container-header"]}>
        <img src="/assets/images/mobileHelplineLogo.png" alt="mobileHelpline" />
        <h4>{t("helpline")}</h4>
      </div>
      <div className={styles["helpline-container-phone-details"]}>
        <div className={styles["helpline-container-phone-details-single"]}>
          <div
            className={styles["helpline-container-phone-details-single-left"]}
          >
            <img src="/assets/images/uae-flag.png" alt="uae-flag" />
          </div>
          <div
            className={styles["helpline-container-phone-details-single-right"]}
          >
            <div
              className={
                styles["helpline-container-phone-details-single-right-top"]
              }
            >
              +971 4 441 8473
            </div>
            <div
              className={
                styles["helpline-container-phone-details-single-right-bottom"]
              }
            >
              {t("everyday")} 9AM - 10PM UAE
            </div>
          </div>
        </div>
        <div className={styles["helpline-container-phone-details-single"]}>
          <div
            className={styles["helpline-container-phone-details-single-left"]}
          >
            <img src="/assets/images/sa-flag.png" alt="uae-flag" />
          </div>
          <div
            className={styles["helpline-container-phone-details-single-right"]}
          >
            <div
              className={
                styles["helpline-container-phone-details-single-right-top"]
              }
            >
              +966 9200 00175
            </div>
            <div
              className={
                styles["helpline-container-phone-details-single-right-bottom"]
              }
            >
              {t("everyday")} 9AM - 9PM KSA
            </div>
          </div>
        </div>
        <div className={styles["helpline-container-phone-details-single"]}>
          <div
            className={styles["helpline-container-phone-details-single-left"]}
          >
            <img src="/assets/images/kw-flag.png" alt="uae-flag" />
          </div>
          <div
            className={styles["helpline-container-phone-details-single-right"]}
          >
            <div
              className={
                styles["helpline-container-phone-details-single-right-top"]
              }
            >
              +965 22 20 4519
            </div>
            <div
              className={
                styles["helpline-container-phone-details-single-right-bottom"]
              }
            >
              {t("everyday")} 9AM - 9PM KW
            </div>
          </div>
        </div>
        <div className={styles["helpline-container-phone-details-single"]}>
          <div
            className={styles["helpline-container-phone-details-single-left"]}
          >
            <img src="/assets/images/mail-icon.png" alt="uae-flag" />
          </div>
          <div
            className={styles["helpline-container-phone-details-single-right"]}
          >
            help@yougotagift.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHelpline;
