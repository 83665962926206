import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../utils/hooks";
import { showNotifierPopup } from "../common/commonSlice";
import { useNavigate } from "react-router-dom";
import { SM_MAX_WIDTH } from "../../constants/common";
import { FORGOT_PASSWORD_VERIFY_USERNAME_MUTATION } from "./forgotPassword.query";

interface PropsInterface {
  nextStep: () => void;
  storeUserName: (username: string) => void;
  handleShowTimer: () => void;
}

const ForgotPasswordStepOne: FC<PropsInterface> = ({
  nextStep,
  storeUserName,
  handleShowTimer,
}) => {
  const [fields, setFields] = useState({
    userName: "",
  });

  const [errors, setErrors] = useState({
    userName: "",
  });

  const [graphQlError, setGraphQlError] = useState("");

  const isMobile = useMediaQuery(SM_MAX_WIDTH);
  const responsiveFieldsMargin = isMobile ? "20px" : "30px";
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [verifyUserName, { loading }] = useMutation(
    FORGOT_PASSWORD_VERIFY_USERNAME_MUTATION
  );
  const currentLang = i18n.language;

  const showNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-enter-email-otp"),
      })
    );
  };

  const handleClearError = () => {
    setErrors({
      ...errors,
      userName: "",
    });
    setGraphQlError("");
  };

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const values = event.target.value;
    setFields((prev) => ({ ...prev, userName: values }));
    if (fields.userName) {
      setErrors((prev) => ({ ...prev, userName: "" }));
    }
  };
  const navigate = useNavigate();
  const handleSubmit = async (event: FormEvent) => {
    handleShowTimer?.();
    event.preventDefault();
    try {
      await validateFields();
      const { data } = await verifyUserName({
        variables: {
          username: fields.userName,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      storeUserName?.(fields.userName);
      showNotifier(data?.forgotPasswordVerifyUsername?.message);
      nextStep();
    } catch (err: any) {
      const graphQlError = err && err.graphQLErrors && err.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setErrors(err);
      }
    }
  };

  const validateFields = () => {
    return new Promise(function (resolve, reject) {
      let errors = { userName: "" };
      let hasError = false;

      if (!fields.userName) {
        hasError = true;
        errors = { ...errors, userName: t("username-required") };
      }

      if (!hasError) {
        // Clear the error messages
        errors = { userName: "" };
      }

      hasError ? reject(errors) : resolve(fields);
    });
  };
  return (
    <div className="activation-form">
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          placeholder={t("registered-emailormobile") ?? ""}
          sx={{ marginBottom: responsiveFieldsMargin }}
          color="info"
          helperText={!fields.userName ? errors.userName : graphQlError}
          onBlur={handleClearError}
          onChange={handleUsernameChange}
          error={!!errors.userName || !!graphQlError}
          inputProps={{ maxLength: 255 }}
        />
        <Button
          variant="contained"
          type="submit"
          className="auth-button"
          sx={{ marginBottom: "59px" }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("submit")}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPasswordStepOne;
