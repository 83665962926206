import React, { FC } from "react";
import styles from "./CorporateSelectionDrawer.module.scss";
import { Button, CircularProgress, Drawer } from "@mui/material";
import { useTranslation } from "react-i18next";

const CorporateSelectionDrawer: FC<{
  drawerOpen?: boolean;
  onClose?: () => void;
  data?: any;
  handleLogout?: () => void;
  logoutLoading?: boolean;
}> = ({ drawerOpen, onClose, data, logoutLoading, handleLogout }) => {
  const { t } = useTranslation();
  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      transitionDuration={{ enter: 300, exit: 300 }}
      anchor="right"
      className="tab-hamburger-menu"
    >
      <div className={styles["hamburger-drawer-container"]}>
        <div className={styles["hamburger-drawer-container__user-details"]}>
          <div
            className={styles["hamburger-drawer-container__user-details__user"]}
          >
            <h4>{t("userDetails")}</h4>
          </div>
          <div
            className={
              styles["hamburger-drawer-container__user-details__basic-details"]
            }
          >
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__text"
                ]
              }
            >
              {data?.data?.name
                ? data?.data?.name?.charAt(0)?.toUpperCase()
                : data?.data?.username?.charAt(0)?.toUpperCase()}
            </div>
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__green-tick"
                ]
              }
            >
              <img src="/assets/images/green-tick.png" alt="green-tick" />
            </div>
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__name-email"
                ]
              }
            >
              <h4>{data?.data?.name}</h4>
              <h3>{data?.data?.username}</h3>
            </div>
          </div>
        </div>
        <div
          className={`${styles["hamburger-drawer-container__sign-out-btn"]} tab-hamburger-drawer-btn`}
        >
          <Button
            variant="contained"
            onClick={handleLogout}
            startIcon={logoutLoading ? <CircularProgress size={20} /> : null}
          >
            {logoutLoading ? "" : t("signOut")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default CorporateSelectionDrawer;
