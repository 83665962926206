import styles from "./ViewGiftcardContentSkeleton.module.scss";
import ViewGiftcardAccordions from "../../viewGiftcardAccordion/ViewGiftcardAccordions";
import { Skeleton } from "@mui/material";
const ViewGiftcardContentSkeleton = () => {
  const productSkeleton = {
    description: "",
    terms_and_conditions: "",
    locations: [],
  };
  return (
    <div
      className={`${styles["view-giftcard-container"]} view-giftcard-wrapper`}
    >
      <div className={styles["view-giftcard-container__left-section"]}>
        <div
          className={
            styles["view-giftcard-container__left-section__card-wrapper"]
          }
        >
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__info"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__validity"
                ]
              }
            >
              <Skeleton width={147} />
            </div>
            <Skeleton width={80} />
          </div>
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__logo"
              ]
            }
          >
            <Skeleton
              variant="rectangular"
              width={416}
              height={266.7}
              sx={{ borderRadius: "10px" }}
            />
          </div>

          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__details"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__price"
                ]
              }
            >
              <Skeleton width={141} />
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__barcode"
                ]
              }
            >
              <Skeleton
                variant="rectangular"
                width={254}
                height={76.4}
                sx={{ borderRadius: "10px" }}
              />
            </div>

            <>
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__details__pin"
                  ]
                }
              >
                <span
                  className={
                    styles[
                      "view-giftcard-container__left-section__card-wrapper__details__pin__value"
                    ]
                  }
                >
                  <Skeleton width={62} />
                </span>
              </div>
            </>
          </div>
        </div>
      </div>
      <div
        className={styles["view-giftcard-container__left-section__accordions"]}
      >
        <ViewGiftcardAccordions
          description={productSkeleton.description}
          terms={productSkeleton.terms_and_conditions}
          locations={productSkeleton.locations}
        />
      </div>
    </div>
  );
};

export default ViewGiftcardContentSkeleton;
