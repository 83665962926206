enum PAGE_URLS {
  LOGIN = "login",
  USER_ACTIVATION = "user-activation",
  NOT_FOUND = "page-not-found",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  LANDING_PAGE = "dashboard",
  CORPORATE_SELECTION = "corporate-selection",
  PROFILE = "profile",
  SUCCESS_PAGE = "payment-success",
  ERROR_PAGE = "payment-failure",
  CREDIT_HISTORY = "credit-history",
  BRANDS = "brands",
  REDEMPTION_HISTORY = "redemption-history",
  CART = "cart",
  VIEWGIFTCARD = "view-giftcard",
}

export default PAGE_URLS;
 