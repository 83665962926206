import { gql } from "@apollo/client";

export const GIFT_DETAILS_QUERY = gql`
  query GiftDetails($giftId: String!) {
    giftDetails(giftId: $giftId) {
      data
      message
      status
    }
  }
`;
