import React, { FC, useEffect, useRef, useState } from "react";
import UserDetailsBox from "../../userDetailsBox/UserDetailsBox";
import styles from "./CorporateSelectionHeader.module.scss";
import { useMediaQuery } from "@mui/material";
import CorporateSelectionDrawer from "../../../corporateSelection/tablet/corporateSelectionDrawer/CorporateSelectionDrawer";
import { useTranslation } from "react-i18next";
import { TABLET_MAX_WIDTH } from "../../../../constants/common";

interface MinorHeaderProps {
  handleLogout: () => void;
  logoutLoading?: boolean;
  profileData?: any;
}

const MinorHeader: FC<MinorHeaderProps> = ({
  handleLogout,
  logoutLoading,
  profileData,
}) => {
  const [open, setOpen] = useState(false);
  const [tabDrawerOpen, setTabDrawerOpen] = useState(false);
  const isTablet = useMediaQuery(TABLET_MAX_WIDTH);
  const { t } = useTranslation();
  const handleDrawerOpen = () => {
    setTabDrawerOpen(true);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className={styles["minor-header-container"]}>
        <div className={styles["minor-header-container__wrapper"]}>
          <div className={styles["minor-header-container__wrapper__left"]}>
            <img src="/assets/images/ygag-logo.png" alt="ygag-logo" />
          </div>
          <div
            className={styles["minor-header-container__wrapper__right"]}
            onClick={() => setOpen(true)}
            ref={componentRef}
          >
            {isTablet ? (
              <div
                className={
                  styles["minor-header-container__wrapper__right__hamburger"]
                }
              >
                <img
                  src="/assets/images/tab-hamburger.png"
                  alt=""
                  onClick={handleDrawerOpen}
                />
              </div>
            ) : (
              <>
                <div
                  className={
                    styles["minor-header-container__wrapper__right__round-box"]
                  }
                >
                  {profileData?.userprofileDetails?.data?.name
                    ? profileData?.userprofileDetails?.data?.name
                        ?.charAt(0)
                        .toUpperCase()
                    : profileData?.userprofileDetails?.data?.username
                        ?.charAt(0)
                        ?.toUpperCase()}
                </div>
                <div
                  className={
                    styles["minor-header-container__wrapper__right__name-box"]
                  }
                >
                  <div
                    className={
                      styles[
                        "minor-header-container__wrapper__right__name-box__text"
                      ]
                    }
                  >
                    {t("me")}
                  </div>
                  <div
                    className={
                      styles[
                        "minor-header-container__wrapper__right__name-box__arrow"
                      ]
                    }
                  >
                    {open && (
                      <UserDetailsBox
                        data={profileData?.userprofileDetails}
                        handleLogout={handleLogout}
                        logoutLoading={logoutLoading}
                      />
                    )}
                    <img src="/assets/images/down-arrow.png" alt="down-arrow" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CorporateSelectionDrawer
        drawerOpen={tabDrawerOpen}
        onClose={() => setTabDrawerOpen(false)}
        data={profileData?.userprofileDetails}
        handleLogout={handleLogout}
        logoutLoading={logoutLoading}
      />
    </>
  );
};

export default MinorHeader;
