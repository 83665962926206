import { gql } from "@apollo/client";

export const FORGOT_PASSWORD_VERIFY_USERNAME_MUTATION = gql`
  mutation verifyForgotPasswordUserName($username: String!) {
    forgotPasswordVerifyUsername(username: $username) {
      status
      message
      data
    }
  }
`;

export const FORGOT_PASSWORD_VERIFY_OTP_MUTATION = gql`
  mutation forgotPasswordVerifyOtp($otp: String!, $username: String!) {
    forgotPasswordVerifyOtp(otp: $otp, username: $username) {
      status
      message
      data
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword(
    $confirmPassword: String!
    $password: String!
    $username: String!
  ) {
    forgotPasswordReset(
      confirmPassword: $confirmPassword
      password: $password
      username: $username
    ) {
      status
      message
      data
    }
  }
`;
