import React, { FC, MouseEvent, useEffect, useState } from "react";
import styles from "./corporateSelection.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { useAppSelector } from "../../utils/hooks";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton, Tooltip } from "@mui/material";
import { getAccessToken } from "../../features/common/commonSlice";
import {
  CORPORATE_LISTING_QUERY,
  CORPORATE_SELECTION_MUTATION,
} from "../../features/corporateSelection/corporateSelection.query";

const CorporateSelection: FC = () => {
  // State and hooks initialization
  const { accessToken } = useAppSelector(getAccessToken);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;

  const ONE_ITEM = 9; // Request one item at a time

  // State management for data and hasMore flag
  const [hasMore, setHasMore] = useState(true);
  const [data, setData] = useState<any>([]);

  const { loading, error, fetchMore } = useQuery(CORPORATE_LISTING_QUERY, {
    variables: {
      first: ONE_ITEM,
      after: "",
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  // Fetch initial data
  useEffect(() => {
    const fetchData = async () => {
      if (data?.length === 0 && !loading && !error) {
        try {
          const newData = await fetchMore({
            variables: {
              after: "",
            },
          });
          if (newData && newData?.data?.corporateList) {
            const updatedData = newData?.data?.corporateList?.edges;
            setData(updatedData);
            if (!newData?.data?.corporateList?.pageInfo?.hasNextPage) {
              setHasMore(false);
            }
          }
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      }
    };

    fetchData();
  }, [data, fetchMore, loading, error]);

  // Fetch more data function
  const fetchMoreData = async () => {
    if (data.length > 0) {
      try {
        const newData = await fetchMore({
          variables: {
            after: data[data?.length - 1]?.cursor,
          },
        });
        if (newData && newData?.data?.corporateList) {
          const updatedData = newData?.data?.corporateList?.edges;
          setData([...data, ...updatedData]);
          if (!newData?.data?.corporateList?.pageInfo?.hasNextPage) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error("Error fetching more data:", error);
      }
    }
  };
  const [tooltipStates, setTooltipStates] = useState<boolean[]>([]);

  const handleShouldShow = (
    { currentTarget }: MouseEvent<Element>,
    index: number
  ) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
    }
  };

  const handleShouldHide = (index: number) => () => {
    setTooltipStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const [loadingState, setLoadingState] = useState<number | null>(null);

  // Mutation for selecting a corporate
  const [selectCorporate] = useMutation(CORPORATE_SELECTION_MUTATION);
  // Function to handle selection of a corporate
  const handleSelectCorporate = async (corporateId: any, index: number) => {
    try {
      setLoadingState(index);
      const { data } = await selectCorporate({
        variables: {
          corporateId: corporateId,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });

      navigate(`/${currentLang}/dashboard`);
    } catch (error) {
      // Handle error
    } finally {
      setLoadingState(null);
    }
  };

  // Render the corporate selection box
  return (
    <div className={styles["corporate-selection-box"]}>
      <div className={styles["corporate-selection-box__banner"]}>
        <img src="/assets/images/corporate-banner.png" alt="" />
        <h3>{t("selectCorporate")}</h3>
      </div>
      <section className={`corporate-selection-wrapper`}>
        <InfiniteScroll
          dataLength={data.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={""}
          scrollableTarget="scrollable-div"
          style={{ overflow: "unset" }}
        >
          <div
            className={styles["corporate-selection-box__container"]}
            id="scrollable-div"
          >
            {data && data?.length > 0
              ? data?.map((item: any, index: any) => (
                  <div
                    className={`${
                      styles["corporate-selection-box__container__card"]
                    } ${
                      loadingState === index && styles["corporate-selected"]
                    }`}
                    key={index}
                    onClick={() =>
                      handleSelectCorporate(
                        Number(item?.node?.corporate?.id),
                        index
                      )
                    }
                  >
                    <div
                      className={
                        styles[
                          "corporate-selection-box__container__card__balance"
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            "corporate-selection-box__container__card__balance__text"
                          ]
                        }
                      >
                        {t("corporateAccountBalance")}
                      </div>
                      <div
                        className={
                          styles[
                            "corporate-selection-box__container__card__balance__amount"
                          ]
                        }
                      >
                        {item.node.currency} {Number(item?.node?.balance)}
                      </div>
                    </div>
                    <div
                      className={
                        styles["corporate-selection-box__container__card__logo"]
                      }
                    >
                      <img src={item.node.corporate?.logo} alt="" />
                    </div>
                    <Tooltip
                      title={item?.node?.corporate?.name}
                      placement="top"
                      open={tooltipStates[index] || false}
                    >
                      <div
                        className={
                          styles[
                            "corporate-selection-box__container__card__name"
                          ]
                        }
                        onMouseEnter={(e) => handleShouldShow(e, index)}
                        onMouseLeave={handleShouldHide(index)}
                      >
                        {item?.node?.corporate?.name}
                      </div>
                    </Tooltip>
                  </div>
                ))
              : Array.from({ length: 9 }, (_, index) => (
                  <div
                    className={
                      styles["corporate-selection-box__container__card"]
                    }
                    key={index}
                  >
                    <div
                      className={
                        styles[
                          "corporate-selection-box__container__card__balance"
                        ]
                      }
                    >
                      <Skeleton
                        variant="text"
                        animation="pulse"
                        width={"100%"}
                      />
                    </div>
                    <div
                      className={
                        styles["corporate-selection-box__container__card__logo"]
                      }
                    >
                      <Skeleton
                        variant="rectangular"
                        height={"108px"}
                        width={"207px"}
                        animation="pulse"
                      />
                    </div>
                    <div
                      className={
                        styles["corporate-selection-box__container__card__name"]
                      }
                    >
                      <Skeleton variant="text" animation="pulse" />
                    </div>
                  </div>
                ))}
          </div>
        </InfiniteScroll>
      </section>
    </div>
  );
};

export default CorporateSelection;
