import React, { FC } from "react";
import styles from "./BrandDetailWebSkeleton.module.scss";
import { Skeleton } from "@mui/material";

const denominations = Array.from({ length: 7 });

const BrandDetailWebSkeleton: FC = () => {
  return (
    <div className={`${styles["brand-detail-container"]} brand-detail-wrapper`}>
      <div className={styles["brand-detail-container__left-section"]}>
        <div
          className={
            styles["brand-detail-container__left-section__card-wrapper"]
          }
        >
          <div
            className={
              styles["brand-detail-container__left-section__card-wrapper__info"]
            }
          >
            <Skeleton variant="text" width={"100%"} height={50} />
            <div
              className={
                styles[
                  "brand-detail-container__left-section__card-wrapper__info__in-store"
                ]
              }
            >
              <Skeleton variant="text" width={"100%"} height={50} />
            </div>
          </div>
          <div
            className={
              styles["brand-detail-container__left-section__card-wrapper__logo"]
            }
          >
            <Skeleton
              variant="rectangular"
              width={"100%"}
              animation="wave"
              height={"266px"}
              sx={{ borderRadius: "12px" }}
            />
          </div>
        </div>
        <div
          className={styles["brand-detail-container__left-section__accordions"]}
        >
          <Skeleton variant="rectangular" width={"100%"} animation="wave" />
        </div>
      </div>
      <div className={styles["brand-detail-container__right-section"]}>
        <div
          className={styles["brand-detail-container__right-section__left-logo"]}
        >
          <Skeleton variant="rectangular" width={"100%"} animation="wave" />
        </div>
        <div
          className={
            styles["brand-detail-container__right-section__right-contents"]
          }
        >
          <div
            className={
              styles[
                "brand-detail-container__right-section__right-contents__header"
              ]
            }
          >
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__header__left"
                ]
              }
            >
              <Skeleton variant="rectangular" width={"100%"} animation="wave" />
            </div>
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__header__right"
                ]
              }
            >
              <Skeleton variant="rectangular" width={"100%"} animation="wave" />
            </div>
          </div>
          <div
            className={
              styles[
                "brand-detail-container__right-section__right-contents__denomination-section"
              ]
            }
          >
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__denomination-section__denom-value"
                ]
              }
            >
              <Skeleton variant="text" width={"100%"} />
              <input type="number" />
            </div>

            <Skeleton variant="text" width={"100%"} />
          </div>
          <div
            className={
              styles[
                "brand-detail-container__right-section__right-contents__amount-selection"
              ]
            }
          >
            {denominations &&
              denominations.length > 0 &&
              denominations.map((item: any, index: any) => (
                <div key={index}>
                  <div
                    className={
                      styles[
                        "brand-detail-container__right-section__right-contents__amount-selection__single-item"
                      ]
                    }
                  >
                    <Skeleton variant="text" width={"100%"} />
                  </div>
                </div>
              ))}

            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__amount-selection__other"
                ]
              }
            >
              <Skeleton variant="text" width={"100%"} />
            </div>
          </div>
          <div
            className={
              styles[
                "brand-detail-container__right-section__right-contents__checkout-button"
              ]
            }
          >
            <Skeleton
              variant="text"
              width={"100%"}
              height={50}
              animation="wave"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandDetailWebSkeleton;
