import styles from "./ViewGiftcardMobileSkeleton.module.scss";
import ViewGiftcardAccordions from "../../viewGiftcardAccordion/ViewGiftcardAccordions";
import { Skeleton } from "@mui/material";

const ViewGiftcardMobileSkeleton = () => {
  const productSkeleton = {
    description: "",
    terms_and_conditions: "",
    locations: [],
  };

  return (
    <div
      className={`${styles["view-giftcard-container"]} view-giftcard-wrapper`}
    >
      <div className={styles["view-giftcard-container__left-section"]}>
        <div
          className={
            styles["view-giftcard-container__left-section__card-wrapper"]
          }
        >
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__info"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__brand-name"
                ]
              }
            >
              <Skeleton width={154} />
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__validity"
                ]
              }
            >
              <Skeleton width={147} />
            </div>
          </div>
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__logo"
              ]
            }
          >
            <Skeleton
              variant="rectangular"
              width={284}
              height={182}
              sx={{ borderRadius: "10px" }}
            />
          </div>

          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__details"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__price"
                ]
              }
            >
              <Skeleton width={136} />
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__barcode"
                ]
              }
            >
              <Skeleton
                variant="rectangular"
                width={248}
                height={73.4}
                sx={{ borderRadius: "10px" }}
              />
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__details__barcode__digit"
                  ]
                }
              ></div>
            </div>

            <>
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__details__pin"
                  ]
                }
              >
                <span
                  className={
                    styles[
                      "view-giftcard-container__left-section__card-wrapper__details__pin__text"
                    ]
                  }
                >
                  <Skeleton width={62} />
                </span>
              </div>
            </>
          </div>
        </div>
        <div
          className={
            styles["view-giftcard-container__left-section__accordions"]
          }
        >
          <ViewGiftcardAccordions
            description={productSkeleton.description}
            terms={productSkeleton.terms_and_conditions}
            locations={productSkeleton.locations}
          />
        </div>
        <div
          className={
            styles["view-giftcard-container__left-section__arrow-back"]
          }
        >
          <img src="../../assets/images/vector.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default ViewGiftcardMobileSkeleton;
