import { useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../utils/hooks";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getDefaultAmount } from "../../utils/functions";
import styles from "../../features/brandDetail/web/brandDetailWeb/BrandDetailWeb.module.scss";
import { MOBILE_MAX_WIDTH, TABLET_MAX_WIDTH } from "../../constants/common";
import {
  getAccessToken,
  getUserBalance,
} from "../../features/common/commonSlice";
import { BRAND_DETAILING_QUERY } from "../../features/brandDetail/brandDetailing.query";
import BrandDetaingMobile from "../../features/brandDetail/mobile/BrandDetaingMobile";
import BrandDetailingTab from "../../features/brandDetail/tablet/BrandDetailingTab";
import BrandDetailWeb from "../../features/brandDetail/web/brandDetailWeb/BrandDetailWeb";

const BrandDetail: FC = () => {
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);
  const isMobileDevice = useMediaQuery(MOBILE_MAX_WIDTH);
  const { accessToken } = useAppSelector(getAccessToken);
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;

  const { userBalance } = useAppSelector(getUserBalance);
  const formattedUserBalance = Number(userBalance);
  const [errorText, setErrorText] = useState("");
  const params = useParams();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { data: detailData, loading: detailLoading } = useQuery(
    BRAND_DETAILING_QUERY,
    {
      variables: {
        code: params.brandcode,
      },
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "accept-language": currentLang,
        },
      },
    }
  );
  const singleData = detailData?.brandDetails?.data;
  const isVariableDenom = singleData?.allowsVariableDenominations;
  const denominations = singleData?.denominations;
  const result = Number(getDefaultAmount(denominations));
  const [initialDenom, setInitialDenom] = useState<any>(result);
  const minDenom = singleData?.denominationRange?.min;
  const maxDenom = singleData?.denominationRange?.max;

  // New state to manage the 'OTHER' div's active state
  const [isOtherActive, setIsOtherActive] = useState(false);

  // for setting default value as active
  useEffect(() => {
    if (result) {
      setInitialDenom(result);
    }
  }, [result]);
  // showing error message from API if the denomination selected is greater than user balance
  const [balanceError, setBalanceError] = useState("");

  const handleUserBalanceErrors = (errorMessage: string) => {
    setBalanceError(errorMessage);
  };

  const clearBalanceErrors = () => {
    setBalanceError("");
  };

  // the function which is responsible for the interaction in the user input
  const handleDenomChange = (event: ChangeEvent<HTMLInputElement>) => {
    const userInput = Number(event.target.value);

    // clear the balance error if present
    clearBalanceErrors?.();

    // Initialize newValue with a default value
    let newValue: number = userInput;

    const decimalNotation: number = singleData?.currencyDecimalNotation;

    // Modify the value based on decimal notation
    if (decimalNotation === 0) {
      // No decimal allowed
      newValue = Math.floor(userInput);
    } else if (decimalNotation === 1) {
      // Allow one decimal
      newValue = parseFloat(userInput.toFixed(1));
    } else if (decimalNotation === 2) {
      // Allow two decimals
      newValue = parseFloat(userInput.toFixed(2));
    } else {
      newValue = parseFloat(userInput.toFixed(3));
    }

    if (newValue < minDenom || newValue > maxDenom) {
      setErrorText(t("enterValidCardValue") ?? "");
      setInitialDenom(newValue);
      setIsOtherActive(false);
    } else if (
      denominations.some((item: any) => Number(item.amount) === newValue)
    ) {
      setErrorText("");
      setInitialDenom(newValue);
      setIsOtherActive(false);
    } else {
      setErrorText("");
      setInitialDenom(newValue);
      setIsOtherActive(true);
    }
  };

  const handleMouseEnter = (index: any, item: any) => {
    // Add the 'active' class when the user hovers over an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles[
        "brand-detail-container__right-section__right-contents__amount-selection__single-item"
      ]
    );
    if (!(item === initialDenom)) {
      elements[index]?.classList?.add(styles["active"]);
    }
  };

  const handleMouseLeave = (index: any, item: any) => {
    // Remove the 'active' class when the user hovers out of an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles[
        "brand-detail-container__right-section__right-contents__amount-selection__single-item"
      ]
    );
    if (!(item === initialDenom)) {
      elements[index]?.classList?.remove(styles["active"]);
    }
  };

  // if variable denomination is present then increment and decrement should be by 10.
  // if not present then increment by the listed denominations.
  const handleIncrement = () => {
    // clear the balance error if present
    clearBalanceErrors?.();

    if (isVariableDenom) {
      const minimumDenom = Number(minDenom);
      // If initialDenom is less than the minimum denomination, set it to the minimum denomination
      if (initialDenom < minimumDenom) {
        setInitialDenom(minimumDenom);
        setErrorText("");
        return;
      }
      // Increment by 10 if variable denominations are allowed
      const incrementedValue = Number(initialDenom) + 10;
      setInitialDenom(incrementedValue);

      const isValueInDenominations = denominations.some(
        (item: any) => Number(item.amount) === incrementedValue
      );
      setIsOtherActive(!isValueInDenominations);
    } else {
      const currentIndex = denominations.findIndex(
        (item: any) => Number(item.amount) === initialDenom
      );

      const nextDenom = denominations[currentIndex + 1];

      if (nextDenom) {
        setInitialDenom(Number(nextDenom?.amount));
        setIsOtherActive(false);
      } else {
        setIsOtherActive(false);
      }
    }
  };

  // if variable denomination is present then increment and decrement should be by 10.
  // if not present then increment by the listed denominations.
  const handleDecrement = () => {
    // clear the balance error if present
    clearBalanceErrors?.();

    if (isVariableDenom) {
      const maximumDenom = Number(maxDenom);
      // If initialDenom is greater than the maximum denomination, set it to the maximum denomination
      if (initialDenom > maximumDenom) {
        setInitialDenom(maximumDenom);
        setIsOtherActive(true);
        return;
      }
      // Decrement by 10 if variable denominations are allowed
      const decrementedValue = Number(initialDenom) - 10;
      // when ever the decremented value comes less than the user balance then no need
      if (decrementedValue < formattedUserBalance) {
        setErrorText("");
      }
      setInitialDenom(decrementedValue);
      const isValueInDenominations = denominations.some(
        (item: any) => Number(item.amount) === decrementedValue
      );
      setIsOtherActive(!isValueInDenominations);
    } else {
      // Find the previous amount in denominations and set it as the new value
      const currentIndex = denominations.findIndex(
        (item: any) => Number(item.amount) === initialDenom
      );
      const prevDenom = denominations[currentIndex - 1];

      if (prevDenom) {
        if (prevDenom?.amount < formattedUserBalance) {
          setErrorText("");
        }
        setInitialDenom(Number(prevDenom.amount));
        setIsOtherActive(false);
      } else {
        setIsOtherActive(false);
      }
    }
  };

  // function which is responsible for the clicking selection of denomination.
  const handleDenominationClick = (clickedAmount: number) => {
    // clear the balance error if present
    clearBalanceErrors?.();

    setInitialDenom(clickedAmount);
    if (initialDenom > minDenom || initialDenom < maxDenom) {
      setErrorText("");
    } else if (initialDenom < formattedUserBalance) {
      setErrorText("");
    }
    setIsOtherActive(false); // Reset the 'OTHER' active state
  };

  const onClickOther = () => {
    setIsOtherActive(true);
    setInitialDenom("");
    setErrorText("");
    inputRef.current?.focus();
  };

  // for value to become empty when user empty the input
  useEffect(() => {
    if (initialDenom === 0) {
      setInitialDenom("");
    }
  }, [initialDenom]);

  const singleObjProps = {
    singleData,
    initialDenom,
    minDenom,
    maxDenom,
    errorText,
    isOtherActive,
    isVariableDenom,
    denominations,
    detailLoading,
    inputRef,
    balanceError,
  };

  return (
    <div>
      {isMobileDevice ? (
        <BrandDetaingMobile
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleDenomChange={handleDenomChange}
          handleDenominationClick={handleDenominationClick}
          onClickOther={onClickOther}
          handleBalanceError={handleUserBalanceErrors}
          singleObj={singleObjProps}
        />
      ) : isTabletDevice ? (
        <BrandDetailingTab
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleDenomChange={handleDenomChange}
          handleDenominationClick={handleDenominationClick}
          onClickOther={onClickOther}
          handleBalanceError={handleUserBalanceErrors}
          singleObj={singleObjProps}
        />
      ) : (
        <BrandDetailWeb
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
          handleMouseLeave={handleMouseLeave}
          handleMouseEnter={handleMouseEnter}
          handleDenomChange={handleDenomChange}
          handleDenominationClick={handleDenominationClick}
          onClickOther={onClickOther}
          handleBalanceError={handleUserBalanceErrors}
          singleObj={singleObjProps}
        />
      )}
    </div>
  );
};

export default BrandDetail;
