import styles from "./ViewGiftcardTablet.module.scss";
import { useTranslation } from "react-i18next";
import ViewGiftcardAccordions from "../viewGiftcardAccordion/ViewGiftcardAccordions";
import { FC } from "react";

const ViewGiftcardTablet: FC<{ singleData: any }> = ({ singleData }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`${styles["view-giftcard-container"]} view-giftcard-wrapper`}
    >
      <div className={styles["view-giftcard-container__left-section"]}>
        <div
          className={
            styles["view-giftcard-container__left-section__card-wrapper"]
          }
        >
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__info"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__validity"
                ]
              }
            >
              {t("expiryOn")} <strong>{singleData.expires_on}</strong>
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__info__in-store"
                ]
              }
            >
              {singleData.redemption_type}
            </div>
          </div>
          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__logo"
              ]
            }
          >
            <img src={singleData.brand_logo} alt="" />
          </div>

          <div
            className={
              styles[
                "view-giftcard-container__left-section__card-wrapper__details"
              ]
            }
          >
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__price"
                ]
              }
            >
              {singleData.currency} {singleData.amount}
            </div>
            <div
              className={
                styles[
                  "view-giftcard-container__left-section__card-wrapper__details__barcode"
                ]
              }
            >
              <img
                width={254}
                height={61}
                src={singleData.barcode_url}
                alt=""
              />
              <div
                className={
                  styles[
                    "view-giftcard-container__left-section__card-wrapper__details__barcode__digit"
                  ]
                }
              >
                {singleData.code}
              </div>
            </div>
            {singleData.pin_code && (
              <>
                <div
                  className={
                    styles[
                      "view-giftcard-container__left-section__card-wrapper__details__pin"
                    ]
                  }
                >
                  <span
                    className={
                      styles[
                        "view-giftcard-container__left-section__card-wrapper__details__pin__text"
                      ]
                    }
                  >
                    {t("pin")}{" "}
                  </span>
                  <span
                    className={
                      styles[
                        "view-giftcard-container__left-section__card-wrapper__details__pin__value"
                      ]
                    }
                  >
                    {singleData.pin_code}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div
          className={
            styles["view-giftcard-container__left-section__accordions"]
          }
        >
          <ViewGiftcardAccordions
            description={singleData.description}
            terms={singleData.terms_and_conditions}
            locations={singleData.locations}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewGiftcardTablet;
