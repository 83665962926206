export const formatTime = (seconds: any) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
};

export const storeSlugInLocalStorage = (slug?: string): void => {
  try {
    // Check if Local Storage is available
    if (typeof localStorage !== "undefined") {
      // Store the slug in Local Storage with a specific key
      if (slug) {
        localStorage.setItem("slug", slug);
        console.log("Slug stored in Local Storage");
      } else {
        // Handle the case where the slug is not provided
        console.log("No slug provided. Local Storage not updated.");
      }
    } else {
      console.log("Local Storage is not available in this browser.");
    }
  } catch (error) {
    console.error("Error storing slug in Local Storage:", error);
  }
};

export const getSlugFromLocalStorage = (): string | null => {
  try {
    if (typeof localStorage !== "undefined") {
      return localStorage.getItem("slug") || null;
    }
  } catch (error) {
    console.error("Error retrieving slug from Local Storage:", error);
  }
  return null;
};

export const getDefaultAmount = (arr: any[]) => {
  // Check if any object has isDefault set to true
  const defaultObject = arr?.find((item) => item.isDefault);

  // If a default object is found, return its amount
  if (defaultObject) {
    return defaultObject.amount;
  }

  // If no default object is found, return the amount of the first object
  return arr?.length > 0 ? arr[0].amount : null;
};
