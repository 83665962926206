import { FC } from "react";
import UserLogin from "../../features/userLogin/UserLogin";
import AuthLayout from "../../features/common/layouts/authLayout/AuthLayout";

const Login: FC = () => {
  return (
    <AuthLayout>
      <UserLogin />
    </AuthLayout>
  );
};

export default Login;
