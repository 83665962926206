import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { formatTime } from "../../utils/functions";
import { useAppDispatch } from "../../utils/hooks";
import ButtonAnchor from "../common/ButtonAnchor";
import { showNotifierPopup } from "../common/commonSlice";
import {
  FORGOT_PASSWORD_ACTION,
  SM_MAX_WIDTH,
} from "../../constants/common";
import { FORGOT_PASSWORD_VERIFY_OTP_MUTATION } from "./forgotPassword.query";
import { RESEND_OTP_MUTATION } from "../activation/activation.query";

const ForgotPasswordStepTwo: FC<{
  userName: string;
  slug: string;
  showTimerOnMount: boolean;
  toInitialState: () => void;
  nextStep: () => void;
}> = ({ userName, slug, showTimerOnMount, toInitialState, nextStep }) => {
  const isMobile = useMediaQuery(SM_MAX_WIDTH);

  const responsiveFieldsMargin = isMobile ? "20px" : "30px";

  const styles = {
    link: {
      color: "#3c2f8f",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
    },
    text: {
      color: "#000000",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
      marginBottom: isMobile ? "22px" : "59px",
    },
    pipeSpan: {
      margin: "0 5px",
    },
  };
  const [fields, setFields] = useState({
    userName: "",
    otp: "",
  });

  const [errors, setErrors] = useState({
    userName: "",
    otp: "",
  });

  const { t } = useTranslation();
  const [graphQlError, setGraphQlError] = useState("");
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const showNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-enter-email-otp"),
      })
    );
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (timerActive) {
      // Only start the timer if it's active
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer, timerActive]);

  // for running the timer when this component mounts (when showTimerOnMount is true)
  useEffect(() => {
    if (showTimerOnMount) {
      setTimer(45);
      setTimerActive(true);
    }
  }, [showTimerOnMount]);

  const handleOtpChange = (event: ChangeEvent<HTMLInputElement>) => {
    const otp = event.target.value;
    setFields((prev) => ({ ...prev, otp: otp }));
    if (otp) {
      setErrors((prev) => ({ ...prev, otp: "" }));
    }
  };

  const handleClearError = () => {
    setErrors({
      otp: "",
      userName: "",
    });
    setGraphQlError("");
  };

  const validateFields = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let errors = { otp: "" };
      let hasError = false;

      if (!fields.otp) {
        // Check if otp is empty
        hasError = true;
        errors = {
          ...errors,
          otp: t("otp-required"),
        };
      }

      hasError ? reject(errors) : resolve(fields.otp);
    });
  };

  const [verifyOtp, { loading }] = useMutation(
    FORGOT_PASSWORD_VERIFY_OTP_MUTATION
  );
  const [resendOtp] = useMutation(RESEND_OTP_MUTATION);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await validateFields();
      const { data } = await verifyOtp({
        variables: {
          otp: fields.otp,
          username: userName,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      if (data.forgotPasswordVerifyOtp.status === 200) {
        nextStep?.();
      }
    } catch (err: any) {
      const graphQlError = err && err.graphQLErrors && err.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setErrors(err);
      }
    }
  };

  const handleResendOtp = async () => {
    setTimer(45);
    setTimerActive(true);
    setFields({
      otp: "",
      userName: "",
    });
    handleClearError?.();
    try {
      const { data } = await resendOtp({
        variables: {
          username: userName,
          action: FORGOT_PASSWORD_ACTION,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });

      showNotifier(data?.resendOtp?.message);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleStartOver = () => {
    toInitialState?.();
  };

  return (
    <div className="activation-form">
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          placeholder={t("registered-emailormobile") ?? ""}
          sx={{ marginBottom: responsiveFieldsMargin }}
          color="info"
          value={userName}
          disabled={true}
        />
        <TextField
          placeholder={t("enter-otp") ?? ""}
          color="info"
          sx={{ marginBottom: responsiveFieldsMargin }}
          onChange={handleOtpChange}
          error={!!errors.otp || !!graphQlError}
          helperText={!fields.otp ? errors.otp : graphQlError}
          onBlur={handleClearError}
          value={fields.otp}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            maxLength: 6,
          }}
          onKeyPress={(e) => {
            if (isNaN(Number(e.key))) {
              e.preventDefault();
            }
          }}
        />
        <Button
          variant="contained"
          type="submit"
          className="auth-button"
          sx={{ marginBottom: "30px" }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("submit")}
        </Button>
      </form>
      <div style={styles.text}>
        {timer > 0 ? (
          `${t("resendVerificationCode")} ${formatTime(timer)}`
        ) : (
          <div
            style={{
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {t("didntReceiveTheOtp")}{" "}
            <ButtonAnchor
              style={{ color: "#3c2f8f", marginRight: "5px" }}
              onClick={handleResendOtp}
            >
              {t("resend")}
            </ButtonAnchor>
            |
            <ButtonAnchor
              style={{ color: "#3c2f8f", marginLeft: "5px" }}
              onClick={handleStartOver}
            >
              {t("start-over")}
            </ButtonAnchor>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordStepTwo;
