import React, { FC, useState } from "react";
import styles from "./RedemptionHistory.module.scss";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Dayjs } from "dayjs";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { TABLET_MAX_WIDTH } from "../../constants/common";
import { useAppSelector } from "../../utils/hooks";
import { getAccessToken } from "../../features/common/commonSlice";
import {
  REDEMPTION_LIST_QUERY,
  RESEND_GIFT_QUERY,
} from "../../features/redemptionHistory/redemptionHistory.query";
import PAGE_URLS from "../../constants/pageUrls";
import DateRangePicker from "../../features/common/dateRangePicker/DateRangePicker";
import RedemptionHistorySkeleton from "../../features/redemptionHistory/skeleton/RedemptionHistorySkeleton";
import ResendSuccessModal from "../../features/redemptionHistory/common/resendSuccessModal/ResendSuccessModal";
import ResendThrottleModal from "../../features/redemptionHistory/common/resendThrottleModal/ResendThrottleModal";

const RedemptionHistory: FC = () => {
  const { accessToken } = useAppSelector(getAccessToken);
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  const [startDate, setStartDate] = React.useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  // State variables for hover state, using an object to track hover state for each action and row
  const [hoveredIcons, setHoveredIcons] = useState<{
    [key: string]: { view: boolean; resend: boolean };
  }>({});

  const [loadingRow, setLoadingRow] = useState<string | null>(null);

  // Event handlers for hover state changes
  const handleMouseEnter = (id: string, action: string) => {
    setHoveredIcons((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [action]: true,
      },
    }));
  };

  const handleMouseLeave = (id: string, action: string) => {
    setHoveredIcons((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [action]: false,
      },
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handleChangeStartDate = (date: any) => {
    setStartDate(date);
  };

  const handleChangeEndDate = (date: any) => {
    setEndDate(date);
  };

  const { data, loading } = useQuery(REDEMPTION_LIST_QUERY, {
    variables: {
      startDate: startDate?.format("DD-MM-YYYY") || "",
      endDate: endDate?.format("DD-MM-YYYY") || "",
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const rows = data?.redemptionList?.edges.map((item: any) => {
    return {
      createdOn: item?.node?.createdOn,
      name: item?.node?.brand?.name,
      expiresOn: item?.node?.expiresOn,
      amount: item?.node?.amount,
      currencyCode: item?.node?.currency?.isoCode,
      id: item?.node?.giftId,
      isExpired: item?.node?.isExpired,
    };
  });

  // Calculate the index range for the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Slice the rows array to display only the current page
  const displayedRows = rows?.slice(startIndex, endIndex);

  // Event handler for changing the page
  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  // Event handler for changing the number of rows per page
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rows per page
  };

  const viewGift = (id: any) => {
    navigate(`/${currentLang}/${PAGE_URLS.VIEWGIFTCARD}/${id}`);
  };

  const [resendGift, { loading: resendLoading }] =
    useLazyQuery(RESEND_GIFT_QUERY);

  const handleResend = async (giftId: any) => {
    try {
      setLoadingRow(giftId);
      const response = await resendGift({
        variables: {
          giftId: giftId,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });
      const graphQlError: any = response?.error?.graphQLErrors[0];
      const statusCode = graphQlError?.status;
      if (statusCode === 429) {
        setOpenErrorModal(true);
      } else if (statusCode === 404) {
        navigate(`/${currentLang}/${PAGE_URLS.NOT_FOUND}`);
      } else if (response?.data) {
        setOpen(true);
      }
    } catch (err) {
      setOpenErrorModal(true);
    } finally {
      setLoadingRow(null);
    }
  };

  return (
    <div
      className={`${styles["redemption-history-container"]} redemption-history-container`}
    >
      <div className={styles["redemption-history-container__banner"]}>
        {isTabletDevice && <h4>{t("redemptionHistory")}</h4>}
      </div>
      <section className="wrapper">
        <div className={styles["redemption-history-container__box"]}>
          <h4>{t("redemptionHistory")}</h4>
          <div
            className={
              styles["redemption-history-container__box__date-pickers"]
            }
          >
            <DateRangePicker
              label={t("startDate") ?? ""}
              handleChange={handleChangeStartDate}
              value={startDate}
              endDate={endDate}
            />
            <p>{t("to")}</p>
            <DateRangePicker
              label={t("endDate") ?? ""}
              handleChange={handleChangeEndDate}
              value={endDate}
              startDate={startDate}
            />
          </div>
          {loading ? (
            <RedemptionHistorySkeleton />
          ) : data?.redemptionList?.edges?.length > 0 ? (
            <div className="redemption-table-contents">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("date")}</TableCell>
                    <TableCell>{t("brand")}</TableCell>
                    <TableCell className="credit-date">{t("expiry")}</TableCell>
                    <TableCell className="expiry-date">
                      {t("giftAmount")}
                    </TableCell>
                    <TableCell className="expiry-date">{t("view")}</TableCell>
                    <TableCell className="expiry-date">{t("resend")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="redemption-table-body">
                  {displayedRows?.map((row: any, index: any) => (
                    <TableRow
                      key={index}
                      sx={{
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <TableCell>{row?.createdOn}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell
                        sx={{ color: row?.isExpired ? "#f8450b" : "" }}
                      >
                        {row?.expiresOn}
                      </TableCell>
                      <TableCell>
                        {row?.currencyCode} {Number(row?.amount)}
                      </TableCell>
                      <TableCell className="view-button">
                        <Tooltip title={t("view")} placement="top" arrow>
                          <IconButton
                            className="icon-button"
                            onClick={() => viewGift(row?.id)}
                            disabled={row?.isExpired}
                            onMouseEnter={() =>
                              handleMouseEnter(row?.id, "view")
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(row?.id, "view")
                            }
                          >
                            <img
                              src={
                                hoveredIcons[row?.id]?.view
                                  ? "/assets/images/eye-icon-filled.png"
                                  : "/assets/images/eye-icon-unfilled.png"
                              }
                              alt=""
                              style={{
                                height: "30px",
                                width: "30px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("resend")} placement="top-end" arrow>
                          <IconButton
                            className="icon-button"
                            onClick={() => handleResend(row?.id)}
                            disabled={row?.isExpired}
                            onMouseEnter={() =>
                              handleMouseEnter(row?.id, "resend")
                            }
                            onMouseLeave={() =>
                              handleMouseLeave(row?.id, "resend")
                            }
                          >
                            {resendLoading && loadingRow === row?.id ? (
                              <CircularProgress size={24} color="secondary" />
                            ) : (
                              <img
                                src={
                                  hoveredIcons[row?.id]?.resend
                                    ? "/assets/images/email-icon-filled.png"
                                    : "/assets/images/email-icon-unfilled.png"
                                }
                                alt=""
                                style={{ height: "30px", width: "30px" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </div>
          ) : (
            <div style={{ fontSize: "16px" }}>{t("historyNoData")}</div>
          )}
        </div>
      </section>
      {open && <ResendSuccessModal onClose={handleClose} />}
      {openErrorModal && (
        <ResendThrottleModal onClose={handleErrorModalClose} />
      )}
    </div>
  );
};

export default RedemptionHistory;
