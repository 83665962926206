import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import styles from "./BrandDetailingTab.module.scss";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BrandDetailAccordion from "../common/brandDetailAccordion/BrandDetailAccordion";
import CheckoutModal from "../common/checkoutModal/CheckoutModal";
import BrandDetailingTabSkeleton from "./skeleton/BrandDetailingTabSkeleton";
import { SingleObj } from "../../../interfaces/common.interface";

interface BrandDetailTabProps {
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleDenomChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDenominationClick: (amount: any) => void;
  onClickOther: () => void;
  handleBalanceError: (error: string) => void;
  singleObj: SingleObj;
}

const BrandDetailingTab: FC<BrandDetailTabProps> = ({
  handleDecrement,
  handleDenomChange,
  handleDenominationClick,
  handleIncrement,
  onClickOther,
  handleBalanceError,
  singleObj,
}) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();

  const handleMouseEnter = (index: any, item: any) => {
    // Add the 'active' class when the user hovers over an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles["brand-detail-container__amount-selection__single-item"]
    );
    if (!(item === singleObj?.initialDenom)) {
      elements[index]?.classList?.add(styles["active"]);
    }
  };

  const handleMouseLeave = (index: any, item: any) => {
    // Remove the 'active' class when the user hovers out of an item, unless it's the initially set active item
    const elements = document.getElementsByClassName(
      styles["brand-detail-container__amount-selection__single-item"]
    );
    if (!(item === singleObj?.initialDenom)) {
      elements[index]?.classList?.remove(styles["active"]);
    }
  };

  const onKeyDown = (event: any) => {
    const value = event?.target?.value;
    const maxLength = String(singleObj?.maxDenom).length || 4;

    if (
      value?.split(".")[0].length === maxLength &&
      event.which !== 8 &&
      event.which !== 46 &&
      (Number(value) < Number(singleObj?.maxDenom) ||
        Number(value) > Number(singleObj?.minDenom))
    ) {
      event.preventDefault();
      return false;
    }

    // #. Prevent adding char "e" from input
    // #. "e" char allowed in numeric field by default
    if (event.which === 69 || event.which === 107 || event.which === 109) {
      event.preventDefault();
      return false;
    }
  };

  const handleGoBack = () => {
    navigate(`/${currentLang}/dashboard`);
  };

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>();
  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const handleShouldHide = () => {
    setTooltipEnabled(false);
  };
  if (singleObj?.detailLoading) {
    return <BrandDetailingTabSkeleton />;
  }
  return (
    <>
      <div
        className={`${styles["brand-detail-container"]} brand-detail-tab-wrapper`}
      >
        <div className={styles["brand-detail-container__back"]}>
          <img src="/assets/images/brand-detail-back.png" alt="" />
          <h4 onClick={handleGoBack}>{t("back")}</h4>
        </div>
        <div className={styles["brand-detail-container__header"]}>
          <div className={styles["brand-detail-container__header__left"]}>
            <img src={singleObj?.singleData?.productImageUrl} alt="" />

            <Tooltip
              title={singleObj?.singleData?.name}
              placement="top"
              open={tooltipEnabled || false}
            >
              <h4
                onMouseEnter={handleShouldShow}
                onMouseLeave={handleShouldHide}
              >
                {singleObj?.singleData?.name}
              </h4>
            </Tooltip>
          </div>
          <div className={styles["brand-detail-container__header__right"]}>
            {singleObj?.singleData?.denominationRange?.currency}{" "}
            {singleObj?.singleData?.denominationRange?.min} -{" "}
            {singleObj?.singleData?.denominationRange?.currency}{" "}
            {singleObj?.singleData?.denominationRange?.max}
          </div>
        </div>
        <div className={styles["brand-detail-container__logo-container"]}>
          <div
            className={styles["brand-detail-container__logo-container__top"]}
          >
            <div
              className={
                styles["brand-detail-container__logo-container__top__validity"]
              }
            >
              {t("validity")} <strong>{singleObj?.singleData?.validity}</strong>
            </div>
            <div
              className={
                styles["brand-detail-container__logo-container__top__instore"]
              }
            >
              {singleObj?.singleData?.redemptionType}
            </div>
          </div>
          <div
            className={styles["brand-detail-container__logo-container__bottom"]}
          >
            <img src={singleObj?.singleData?.productImageUrl} alt="" />
          </div>
        </div>
        <div className={styles["brand-detail-container__denomination-section"]}>
          <img
            src="/assets/images/minus-icon.png"
            alt=""
            onClick={
              singleObj?.initialDenom > singleObj?.minDenom
                ? handleDecrement
                : undefined
            }
            className={
              singleObj?.initialDenom <= singleObj?.minDenom
                ? styles["disabled"]
                : ""
            }
          />
          <div
            className={
              styles[
                "brand-detail-container__denomination-section__denom-value"
              ]
            }
          >
            <h4>{singleObj?.singleData?.denominationRange?.currency}</h4>
            <input
              type="number"
              value={singleObj?.initialDenom}
              onChange={handleDenomChange}
              pattern="\d+(\.\d+)?"
              step="any"
              ref={singleObj?.inputRef}
              readOnly={!singleObj?.isVariableDenom}
              style={{ width: String(singleObj?.initialDenom)?.length + "ch" }}
              max={Number(singleObj?.maxDenom)}
              min={Number(singleObj?.minDenom)}
              onKeyDown={onKeyDown}
            />
          </div>

          <img
            src="/assets/images/plus-icon.png"
            alt=""
            onClick={
              singleObj?.initialDenom < singleObj?.maxDenom
                ? handleIncrement
                : undefined
            }
            className={
              singleObj?.initialDenom >= singleObj?.maxDenom
                ? styles["disabled"]
                : ""
            }
          />
        </div>
        <div className={styles["brand-detail-container__amount-selection"]}>
          {singleObj?.denominations &&
            singleObj?.denominations.length > 0 &&
            singleObj?.denominations.map((item: any, index: any) => (
              <div key={index}>
                <div
                  className={`${
                    styles[
                      "brand-detail-container__amount-selection__single-item"
                    ]
                  } ${
                    Number(item?.amount) === singleObj?.initialDenom &&
                    styles["active"]
                  }`}
                  key={index}
                  onMouseEnter={() =>
                    handleMouseEnter(index, Number(item?.amount))
                  }
                  onMouseLeave={() =>
                    handleMouseLeave(index, Number(item?.amount))
                  }
                  onClick={() => handleDenominationClick(Number(item?.amount))}
                >
                  {item?.currency} {item?.amount}
                </div>
              </div>
            ))}
          {singleObj?.isVariableDenom && (
            <div
              className={`${
                styles["brand-detail-container__amount-selection__other"]
              } ${singleObj?.isOtherActive && styles["active"]}`}
              onClick={onClickOther}
            >
              {t("other")}
            </div>
          )}
        </div>
        <div className={styles["brand-detail-container__checkout-button"]}>
          <Button
            variant="contained"
            fullWidth
            disabled={
              singleObj?.errorText.length > 0 || singleObj?.initialDenom === 0
            }
            onClick={() => setOpen(true)}
          >
            {t("checkout")}
          </Button>
        </div>
        {singleObj?.errorText ? (
          <div className={styles["brand-detail-container__error-container"]}>
            <img src="/assets/images/brand-denomination-error.png" alt="" />
            <p>{singleObj?.errorText}</p>
          </div>
        ) : singleObj?.balanceError ? (
          <div className={styles["brand-detail-container__error-container"]}>
            <img src="/assets/images/brand-denomination-error.png" alt="" />
            <p>{singleObj?.balanceError}</p>
          </div>
        ) : (
          ""
        )}
        <BrandDetailAccordion
          description={singleObj?.singleData?.description}
          terms={singleObj?.singleData?.termsAndConditions}
          locations={singleObj?.singleData?.location}
        />
      </div>
      {open && (
        <CheckoutModal
          amount={singleObj?.initialDenom?.toString()}
          currency={singleObj?.singleData?.denominationRange?.currency}
          brandName={singleObj?.singleData?.name}
          onClose={handleClose}
          brandCode={singleObj?.singleData?.code}
          handleError={handleBalanceError}
        />
      )}
    </>
  );
};

export default BrandDetailingTab;
