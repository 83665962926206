import { Backdrop, Button, CircularProgress } from "@mui/material";
import React, { FC, useState } from "react";
import styles from "./CheckoutModal.module.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";
import { useMutation } from "@apollo/client";
import { CONFIRM_PAYMENT_MUTATION } from "../../brandDetailing.query";
import { useAppSelector } from "../../../../utils/hooks";
import { getAccessToken } from "../../../common/commonSlice";
import DOMPurify from "dompurify";

interface CheckoutModalProps {
  amount: string;
  currency: string;
  brandName: string;
  onClose?: () => void;
  brandCode?: any;
  handleError?: (error: string) => void;
}

const CheckoutModal: FC<CheckoutModalProps> = ({
  amount,
  currency,
  brandName,
  onClose,
  brandCode,
  handleError,
}) => {
  const { accessToken } = useAppSelector(getAccessToken);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const [disable, setDisable] = useState(false);

  const handleRedirect = (giftId?: any) => {
    navigate(`/${currentLang}/${PAGE_URLS.SUCCESS_PAGE}/${giftId}`);
  };

  const [giftPurchase, { loading }] = useMutation(CONFIRM_PAYMENT_MUTATION);

  const sanitizedBrandName = DOMPurify.sanitize(brandName);
  const sanitizedCurrency = DOMPurify.sanitize(currency);
  const sanitizedAmount = DOMPurify.sanitize(amount);

  const handleConfirm = async () => {
    setDisable(true);
    try {
      const { data } = await giftPurchase({
        variables: {
          currency: currency,
          amountInCurrency: amount,
          brandCode: brandCode,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });
      const gift_id = data?.giftPurchase?.data?.id;
      handleRedirect(gift_id);
      setDisable(false);
    } catch (error: any) {
      const graphQlError =
        error && error?.graphQLErrors && error?.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        handleError?.(graphQlError?.message);
        onClose?.();
      } else {
        navigate(`/${currentLang}/${PAGE_URLS.ERROR_PAGE}`);
      }
    }
  };

  return (
    <div className="checkout-modal-container">
      <Backdrop open={true}>
        <div className={styles["checkout-modal"]}>
          <div className={styles["checkout-modal__header"]}>
            {t("confirmOrder")}
          </div>
          <div
            className={styles["checkout-modal__contents"]}
            dangerouslySetInnerHTML={{
              __html:
                t("orderConfirmText", {
                  brand: `<strong>${sanitizedBrandName || ""}</strong>`,
                  currency: `<strong>${sanitizedCurrency || ""}</strong>`,
                  amount: `<strong>${sanitizedAmount || ""}</strong>`,
                }) || "",
            }}
          ></div>

          <div className={styles["checkout-modal__footer"]}>
            <Button variant="outlined" onClick={onClose} disabled={disable}>
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              startIcon={
                loading ? (
                  <CircularProgress size={20} sx={{ color: "white" }} />
                ) : null
              }
            >
              {loading ? "" : t("confirm")}
            </Button>
          </div>
        </div>
      </Backdrop>
    </div>
  );
};

export default CheckoutModal;
