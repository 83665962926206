import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PAGE_URLS from "../../constants/pageUrls";
import { useAppDispatch } from "../../utils/hooks";
import { passwordRegex } from "../../utils/regex";
import { showNotifierPopup } from "../common/commonSlice";
import { RESET_PASSWORD_MUTATION } from "./forgotPassword.query";

const ForgotPasswordStepThree: FC<{
  userName: string;
  slug: string;
}> = ({ userName, slug }) => {
  const [fields, setFields] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const { i18n, t } = useTranslation();

  const currentLang = i18n.language;

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const showNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-sign-with-new-pw"),
      })
    );
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION);

  const validateFields = () => {
    return new Promise(function (resolve, reject) {
      let errors = { password: "", confirmPassword: "" };
      let hasError = false;

      if (!fields.password) {
        hasError = true;
        errors = {
          ...errors,
          password: t("password-required"),
        };
      } else if (!passwordRegex.test(fields.password)) {
        hasError = true;
        errors = {
          ...errors,
          password: t("password-error-text"),
        };
      } else if (fields.password !== fields.confirmPassword) {
        hasError = true;
        errors = {
          ...errors,
          confirmPassword: t("password-not-match"),
        };
      }

      if (!hasError) {
        // Clear the error messages
        errors = { password: "", confirmPassword: "" };
      }

      hasError ? reject(errors) : resolve(fields);
    });
  };

  const handleClearError = () => {
    setErrors({
      password: "",
      confirmPassword: "",
    });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setFields((prev) => ({ ...prev, password: newPassword }));
    if (newPassword.length >= 8 && newPassword.length <= 25) {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
  };

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = event.target.value;
    setFields((prev) => ({ ...prev, confirmPassword: newConfirmPassword }));
    if (newConfirmPassword === fields.password) {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await validateFields();
      const { data } = await resetPassword({
        variables: {
          username: userName,
          password: fields.password,
          confirmPassword: fields.confirmPassword,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      const message = data?.forgotPasswordReset?.message;
      showNotifier(message);
      navigate(
        slug
          ? `/${currentLang}/${PAGE_URLS.LOGIN}?corp_slug=${slug}`
          : `/${currentLang}/${PAGE_URLS.LOGIN}`
      );
    } catch (err: any) {
      setErrors(err);
    }
  };

  const handleBlurPassword = () => {
    validateFields()
      .then(() => {
        // Password is valid on blur, clear error message
        setErrors((prev) => ({ ...prev, password: "" }));
      })
      .catch((err) => {
        // Password is invalid on blur, set error message
        setErrors((prev) => ({ ...prev, password: err.password }));
      });
  };

  const handleBlurConfirmPassword = () => {
    validateFields()
      .then(() => {
        // Confirm password is valid on blur, clear error message
        setErrors((prev) => ({ ...prev, confirmPassword: "" }));
      })
      .catch((err) => {
        // Confirm password is invalid on blur, set error message
        setErrors((prev) => ({
          ...prev,
          confirmPassword: err.confirmPassword,
        }));
      });
  };

  return (
    <div className="activation-form">
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          placeholder={t("password") ?? ""}
          color="info"
          sx={{ marginBottom: "30px" }}
          type={showPassword ? "text" : "password"}
          onChange={handlePasswordChange}
          helperText={errors.password}
          error={!!errors.password}
          onFocus={handleClearError}
          onBlur={() => {
            handleBlurConfirmPassword();
            handleBlurPassword();
          }}
          className="confirm-password-eye"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleTogglePasswordVisibility}
                  edge="end"
                  disabled={!fields.password}
                >
                  {showPassword ? (
                    <img
                      src="/assets/images/invisible-eye.png"
                      alt="password-eye-invisible"
                      style={{ height: "16px", width: "21px" }}
                    />
                  ) : (
                    <img
                      src="/assets/images/visible-eye.png"
                      alt="password-eye-visible"
                      style={{ height: "16px", width: "21px" }}
                    />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          placeholder={t("confirm-password") ?? ""}
          color="info"
          sx={{ marginBottom: "30px" }}
          type="password"
          onChange={handleConfirmPasswordChange}
          helperText={errors.confirmPassword}
          error={!!errors.confirmPassword}
          onFocus={handleClearError}
          inputProps={{ maxLength: 255 }}
          onBlur={() => {
            handleBlurConfirmPassword();
            handleBlurPassword();
          }}
        />
        <Button
          className="auth-button"
          variant="contained"
          type="submit"
          sx={{ marginBottom: "60px" }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("submit")}
        </Button>
      </form>
    </div>
  );
};

export default ForgotPasswordStepThree;
