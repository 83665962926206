import React, { FC } from "react";
import styles from "./Loader.module.scss";

const Loader: FC = () => {
  return (
    <div className={styles["loader-container"]}>
      <img src="/assets/images/circle-loader.gif" alt="Loading..." />
    </div>
  );
};

export default Loader;
