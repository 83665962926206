import { useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { setAccessToken } from "../features/common/commonSlice";
import Loader from "../features/common/loader/Loader";
import i18n from "../i18n";
import { RootState } from "../redux/store";
import { useAppDispatch, useAppSelector } from "../utils/hooks";
import { REFRESH_TOKEN_MUTATION } from "../features/userLogin/userLogin.query";
const PrivateRoutes = () => {
  const { accessToken } = useAppSelector(
    (state: RootState) => state.setAccessToken
  );
  const navigate = useNavigate();
  const cookie = require("cookie");
  const { mycredits_token_expiry } = cookie.parse(document.cookie);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [refreshTokenMutation] = useMutation(REFRESH_TOKEN_MUTATION);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");
  const corpSlug = queryParams.get("corp_slug");
  const fullURL = window?.location?.href;
  const { language } = i18n;
  useEffect(() => {
    if (!accessToken || !mycredits_token_expiry) {
      const fetchData = async () => {
        try {
          const { data } = await refreshTokenMutation();
          const updatedToken = data?.refreshToken?.accessToken;
          setIsAuthenticating(false);
          dispatch(setAccessToken(updatedToken));
        } catch (error) {
          // Handle any errors that occurred during the refreshTokenMutation
          setIsAuthenticating(false);
          if (!!redirect) {
            navigate(
              `/${language}/login?corp_slug=${corpSlug}&redirect_url=${encodeURI(
                fullURL
              )}`
            );
          } else {
            navigate(`/${language}/error/401`);
          }
        }
      };
      fetchData();
    } else {
      setIsAuthenticating(false);
    }
  }, []);
  if (isAuthenticating) {
    return <Loader />;
  }
  return <Outlet />;
};
export default PrivateRoutes;
