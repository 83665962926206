import React, { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PAGE_URLS from "../constants/pageUrls";
import Progress from "../features/common/pageLoader/PageLoader";
import CorporateSelection from "../pages/corporateSelection/CorporateSelection";
import ForgotPassword from "../pages/forgotPassword/ForgotPassword";
import LocaleRoute from "./LocaleRoute";
import PrivateRoutes from "./privateRoutes";
import CommonLayout from "../features/common/layouts/commonLayout/CommonLayout";
import CorporateSelectionLayout from "../features/common/layouts/corporateSelectionLayout/CorporateSelectionLayout";
import TooManyRequestsError from "../pages/429/TooManyRequestsError";
import NotFound from "../pages/404/NotFound";
import AuthRequired from "../pages/401/AuthRequired";
import Activation from "../pages/activation/Activation";
import Login from "../pages/login/Login";
import RedemptionHistory from "../pages/redemptionHistory/RedemptionHistory";
import CreditHistory from "../pages/creditHistory/CreditHistory";
import PaymentFailure from "../pages/paymentFailure/PaymentFailure";
import PaymentSuccess from "../pages/paymentSuccess/PaymentSuccess";
import Profile from "../pages/profile/Profile";
import ViewGiftcard from "../pages/viewGiftcard/ViewGiftcard";
import Cart from "../pages/cart/Cart";
import BrandDetail from "../pages/brandDetail/BrandDetail";
import Dashboard from "../pages/dashboard/DashBoard";

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<Progress />}>
        <Routes>
          <Route path={":locale"} element={<LocaleRoute />}>
            <Route element={<PrivateRoutes />}>
              <Route element={<CorporateSelectionLayout />}>
                <Route
                  path={PAGE_URLS.CORPORATE_SELECTION}
                  element={<CorporateSelection />}
                />
              </Route>
              <Route element={<CommonLayout />}>
                <Route
                  path={PAGE_URLS.LANDING_PAGE}
                  element={<Dashboard />}
                />
                <Route
                  path={`${PAGE_URLS.BRANDS}/:brandcode`}
                  element={<BrandDetail />}
                />
                <Route path={PAGE_URLS.CART} element={<Cart />} />{" "}
                <Route
                  path={`${PAGE_URLS.VIEWGIFTCARD}/:giftid`}
                  element={<ViewGiftcard />}
                />
                <Route path={PAGE_URLS.PROFILE} element={<Profile />} />
                <Route
                  path={`${PAGE_URLS.SUCCESS_PAGE}/:giftId`}
                  element={<PaymentSuccess />}
                />
                <Route path={PAGE_URLS.ERROR_PAGE} element={<PaymentFailure />} />
                <Route
                  path={PAGE_URLS.CREDIT_HISTORY}
                  element={<CreditHistory />}
                />
                <Route
                  path={PAGE_URLS.REDEMPTION_HISTORY}
                  element={<RedemptionHistory />}
                />
              </Route>
            </Route>

            <Route path={PAGE_URLS.LOGIN} element={<Login />} />
            <Route
              path={`${PAGE_URLS.USER_ACTIVATION}/:uid`}
              element={<Activation />}
            />
            <Route
              path={PAGE_URLS.FORGOT_PASSWORD}
              element={<ForgotPassword />}
            />
            <Route index element={<Navigate to="/login" replace />} />
            <Route path="*" element={<Navigate to="/error/404" />} /> 
            <Route path="error">
              <Route path="401" element={<AuthRequired />} />
              <Route path="404" element={<NotFound />} />
              <Route path="429" element={<TooManyRequestsError />} />
            </Route>
          </Route>

          <Route path={`/${PAGE_URLS.NOT_FOUND}`} element={< NotFound/>} />
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="*" element={<Navigate to="/error/404" replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRouter;
