import { Skeleton, useMediaQuery } from "@mui/material";
import React, { FC } from "react";
import styles from "./DashBoardWebSkeleton.module.scss";
import { MOBILE_MAX_WIDTH } from "../../../../constants/common";

const DashBoardWebSkeleton: FC = () => {
  const isMobile = useMediaQuery(MOBILE_MAX_WIDTH);

  return (
    <div className={styles["gift-container__box__card"]}>
      <div className={styles["gift-container__box__card__logo-box"]}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{ height: isMobile ? "104px" : "168px", width: "100%" }}
        />
      </div>
      <div className={styles["gift-container__box__card__brand-name"]}>
        <Skeleton variant="text" />
      </div>
      <div className={styles["gift-container__box__card__brand-type"]}>
        <Skeleton variant="text" />
      </div>
      <div className={styles["gift-container__box__card__brand-type"]}>
        <Skeleton variant="text" />
      </div>
    </div>
  );
};

export default DashBoardWebSkeleton;
