import { FC } from "react";
import PageNotFound from "../../features/common/error/pageNotFound/PageNotFound";
const NotFound: FC = () => {
  return (
    <div>
      <PageNotFound />
    </div>
  );
};

export default NotFound;
