import { CssBaseline } from "@mui/material";
import AppRouter from "./routes/AppRouter";
import "./styles/global.scss";
import "./i18n";
import MUIThemeProvider from "./features/common/themeProvider/ThemeProvider";
import TransalationProvider from "./features/common/translationProvider/TranslationProvider";

const langFromUrl = window.location.pathname.includes("/ar/") ? "ar" : "en";

function App() {
  return (
    <TransalationProvider lang={langFromUrl}>
      <MUIThemeProvider>
        <CssBaseline />
        <AppRouter />
      </MUIThemeProvider>
    </TransalationProvider>
  );
}

export default App;
