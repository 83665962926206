import { useMutation } from "@apollo/client";
import {
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { t } from "i18next";
import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PAGE_URLS from "../../constants/pageUrls";
import { formatTime } from "../../utils/functions";
import { useAppDispatch } from "../../utils/hooks";
import ButtonAnchor from "../common/ButtonAnchor";
import { showNotifierPopup, setAccessToken } from "../common/commonSlice";
import { LOGIN_ACTION, SM_MAX_WIDTH } from "../../constants/common";
import { RESEND_OTP_MUTATION } from "../activation/activation.query";
import { LOGIN_MUTATION, LOGIN_MUTATION_NO_CORPORATE_SLUG, VERIFY_OTP_LOGIN_MUTATION, VERIFY_OTP_LOGIN_NO_SLUG_MUTATION } from "./userLogin.query";

const UserLogin: FC = () => {
  const isMobile = useMediaQuery(SM_MAX_WIDTH);

  const responsiveFieldsMargin = isMobile ? "20px" : "30px";

  const styles = {
    link: {
      color: "#3c2f8f",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
      marginBottom: isMobile ? "35px" : "59px",
    },
  };

  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const [fields, setFields] = useState({
    userName: "",
    password: "",
  });

  const [otp, setOtp] = useState("");

  const [otpError, setOtpError] = useState("");

  const [errors, setErrors] = useState({
    userName: "",
    password: "",
  });

  const [graphQlError, setGraphQlError] = useState("");

  const [verifyOtpError, setVerifyOtpError] = useState("");

  const dispatch = useAppDispatch();

  const showOtpNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: `${t("pls-enter-email-otp")}`,
      })
    );
  };

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirect_url = searchParams.get("redirect_url");
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get("corp_slug");
  const [showOtp, setShowOtp] = useState(false);
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  const [login, { loading }] = useMutation(LOGIN_MUTATION);

  const [noslugLogin, { loading: noslugLoginLoading }] = useMutation(
    LOGIN_MUTATION_NO_CORPORATE_SLUG
  );

  const [verifyLoginOtp, { loading: verifyOtpLoading }] = useMutation(
    VERIFY_OTP_LOGIN_MUTATION
  );

  const [noSlugVerifyOtpLogin, { loading: noSlugVerifyOtpLoginLoading }] =
    useMutation(VERIFY_OTP_LOGIN_NO_SLUG_MUTATION);

  const [resendOtp] = useMutation(RESEND_OTP_MUTATION);

  const validateFields = () => {
    return new Promise(function (resolve, reject) {
      let errors = { userName: "", password: "" };
      let hasError = false;

      if (!fields.userName) {
        hasError = true;
        errors = { ...errors, userName: t("username-required") };
      }
      if (!fields.password) {
        hasError = true;
        errors = { ...errors, password: t("password-required") };
      }

      if (!hasError) {
        // Clear the error messages
        errors = { password: "", userName: "" };
      }

      hasError ? reject(errors) : resolve(fields);
    });
  };

  const handleClearError = () => {
    // Clear the error state when clicked outside textfield
    setErrors({ userName: "", password: "" });
    setGraphQlError("");
  };

  const handleClearOtpError = () => {
    setOtpError("");
    setVerifyOtpError("");
  };

  const handleUserNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const userName = event.target.value;
    setFields((prev) => ({ ...prev, userName: userName }));
    if (userName) {
      setErrors((prev) => ({ ...prev, userName: "" }));
      setGraphQlError("");
    }
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setFields((prev) => ({ ...prev, password: password }));
    if (password) {
      setErrors((prev) => ({ ...prev, password: "" }));
      setGraphQlError("");
    }
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);
    if (otp) {
      setOtpError("");
      setVerifyOtpError("");
    }
  };

  const handleRedirection = (data: any) => {
    //condition to check wether the user is directed from view-giftcard email
    if (!!redirect_url) {
      window.location.href = redirect_url;
    } else {
      //condition  to check whether corparate  selection is needed
      if (data?.enable_corporate_selection) {
        navigate(`/${currentLang}/${PAGE_URLS.CORPORATE_SELECTION}`);
      } else {
        navigate(`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`);
      }
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await validateFields();
      // condition checking based on whether the slug is present on the url.
      const { data } = slug
        ? await login({
            variables: {
              username: fields.userName,
              password: fields.password,
              corporateSlug: slug,
            },
            context: {
              headers: {
                "accept-language": currentLang,
              },
            },
          })
        : await noslugLogin({
            variables: {
              username: fields.userName,
              password: fields.password,
            },
            context: {
              headers: {
                "accept-language": currentLang,
              },
            },
          });

      const is_2fa_enabled = data?.login?.data?.is_2fa_enabled;
      if (is_2fa_enabled) {
        setShowOtp(is_2fa_enabled);
        setTimer(45); // for showing the timer initially when otp field is showing.
        setTimerActive(true);
        showOtpNotifier(data?.login?.message);
      } else {
        handleRedirection(data?.login?.data);
      }
    } catch (err: any) {
      const graphQlError = err && err?.graphQLErrors && err?.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setErrors(err);
      }
    }
  };

  const handleLoginOtpVerification = async () => {
    try {
      if (!otp) {
        setOtpError(t("otp-required") ?? "");
      } else {
        const { data } = slug
          ? await verifyLoginOtp({
              variables: {
                otp: otp,
                username: fields.userName,
                corporateSlug: slug,
              },
              context: {
                headers: {
                  "accept-language": currentLang,
                },
              },
            })
          : await noSlugVerifyOtpLogin({
              variables: {
                otp: otp,
                username: fields.userName,
              },
              context: {
                headers: {
                  "accept-language": currentLang,
                },
              },
            });
        dispatch(setAccessToken(data?.verifyLoginOtp?.accessToken));

        handleRedirection(data?.verifyLoginOtp?.data);
      }
    } catch (err: any) {
      const graphQlError = err?.graphQLErrors?.[0];
      if (graphQlError?.status === 400) {
        setVerifyOtpError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setErrors(err);
      }
    }
  };

  const handleResendOtp = async () => {
    setTimer(45);
    setTimerActive(true);
    setOtp("");
    handleClearOtpError?.();
    try {
      const { data } = await resendOtp({
        variables: {
          username: fields.userName,
          action: LOGIN_ACTION,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      showOtpNotifier(data?.resendOtp?.message);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleStartOver = () => {
    window.location.reload();
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (timerActive) {
      // Only start the timer if it's active
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer, timerActive]);

  return (
    <div className="activation-form">
      <form onSubmit={handleSubmit} noValidate>
        <TextField
          placeholder={t("enter-email-mobile") ?? ""}
          color="info"
          sx={{ marginBottom: responsiveFieldsMargin }}
          onChange={handleUserNameChange}
          value={fields.userName}
          helperText={errors.userName}
          error={!!errors.userName || !!graphQlError}
          onBlur={handleClearError}
          disabled={showOtp === true}
          inputProps={{ maxLength: 255 }}
        />
        <TextField
          placeholder={t("password") ?? ""}
          color="info"
          type="password"
          sx={{ marginBottom: responsiveFieldsMargin }}
          onChange={handlePasswordChange}
          value={fields.password}
          helperText={!fields.password ? errors.password : graphQlError}
          error={!!errors.password || !!graphQlError}
          onBlur={handleClearError}
          disabled={showOtp === true}
          inputProps={{ maxLength: 255 }}
        />
        {showOtp && (
          <TextField
            placeholder={t("enter-otp") ?? ""}
            color="info"
            sx={{ marginBottom: responsiveFieldsMargin }}
            onChange={handleOtpChange}
            value={otp}
            helperText={!otp ? otpError : verifyOtpError}
            error={!!otpError || !!verifyOtpError}
            onBlur={handleClearOtpError}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              maxLength: 6,
            }}
            onKeyPress={(e) => {
              if (isNaN(Number(e.key))) {
                e.preventDefault();
              }
            }}
          />
        )}
        {!showOtp ? (
          <Button
            variant="contained"
            type="submit"
            className="auth-button"
            sx={{ marginBottom: "30px" }}
            startIcon={
              loading || noslugLoginLoading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : null
            }
          >
            {loading || noslugLoginLoading ? "" : t("submit")}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="auth-button"
            sx={{ marginBottom: "30px" }}
            startIcon={
              verifyOtpLoading || noSlugVerifyOtpLoginLoading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : null
            }
            onClick={handleLoginOtpVerification}
          >
            {verifyOtpLoading || noSlugVerifyOtpLoginLoading
              ? ""
              : t("sign-in")}
          </Button>
        )}
      </form>
      {showOtp && timer > 0 && (
        // shows the resend otp timer
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            marginBottom: "59px",
          }}
        >
          {t("resendVerificationCode")} {formatTime(timer)}
        </div>
      )}

      {showOtp && timer <= 0 && (
        // shows the send again otp button
        <div
          style={{
            textAlign: "center",
            fontSize: "14px",
            marginBottom: "59px",
          }}
        >
          {t("not-recieve-otp")}
          <ButtonAnchor
            style={{ color: "#3c2f8f", marginRight: "5px" }}
            onClick={handleResendOtp}
          >
            {t("send-again")}
          </ButtonAnchor>
          |
          <ButtonAnchor
            style={{ color: "#3c2f8f", marginLeft: "5px" }}
            onClick={() => handleStartOver()}
          >
            {t("start-over")}
          </ButtonAnchor>
        </div>
      )}

      {!showOtp && !timerActive && (
        // shows the forgot password button
        <Link
          to={
            slug
              ? `/${currentLang}/${PAGE_URLS.FORGOT_PASSWORD}?corp_slug=${slug}`
              : `/${currentLang}/${PAGE_URLS.FORGOT_PASSWORD}`
          }
          style={styles.link}
        >
          {loading ? "" : t("forgot-password")}
        </Link>
      )}
    </div>
  );
};

export default UserLogin;
