import React, { FC } from "react";
import AuthenticationRequired from "../../features/common/error/authenticationRequired/AuthenticationRequired";


const AuthRequired: FC = () => {
  return (
    <div>
      <AuthenticationRequired />
    </div>
  );
};

export default AuthRequired;
