import { Button, CircularProgress, Drawer } from "@mui/material";
import React, { FC } from "react";
import styles from "./HamburgerDrawer.module.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";
import { useAppSelector } from "../../../../utils/hooks";
import { getSelectedStore } from "../../../common/commonSlice";

interface HamburgerDrawerProps {
  drawerOpen?: boolean;
  onClose?: () => void;
  headerData?: any;
  data?: any;
  logoutLoading?: boolean;
  handleLogout?: () => void;
  handleDrawerClose?: () => void;
}

const HamburgerDrawer: FC<HamburgerDrawerProps> = ({
  drawerOpen,
  onClose,
  headerData,
  data,
  logoutLoading,
  handleLogout,
  handleDrawerClose,
}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;
  const { pathname } = useLocation();
  const params = useParams();
  const selectLnag = i18n.language === "ar" ? "en" : "ar";
  const { selectedStore } = useAppSelector(getSelectedStore);

  const handleRedirect = () => {
    navigate(`/${currentLang}/${PAGE_URLS.CORPORATE_SELECTION}`);
  };

  const redirectToProfile = () => {
    navigate(`/${currentLang}/${PAGE_URLS.PROFILE}`);
    handleDrawerClose?.();
  };

  const redirectToCreditHistory = () => {
    navigate(`/${currentLang}/${PAGE_URLS.CREDIT_HISTORY}`);
    handleDrawerClose?.();
  };

  const redirectToRedemptionHistory = () => {
    navigate(`/${currentLang}/${PAGE_URLS.REDEMPTION_HISTORY}`);
    handleDrawerClose?.();
  };

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    const newUrl: string = pathname.replace(`/${params.locale}`, `/${lng}`);

    navigate(newUrl, { replace: true });
  };

  return (
    <Drawer
      open={drawerOpen}
      onClose={onClose}
      transitionDuration={{ enter: 300, exit: 300 }}
      anchor="right"
      className="tab-hamburger-menu"
    >
      <div className={styles["hamburger-drawer-container"]}>
        <div className={styles["hamburger-drawer-container__payments"]}>
          <div className={styles["hamburger-drawer-container__payments__logo"]}>
            <img src="/assets/images/header-wallet-icon.png" alt="" />
          </div>
          <div
            className={styles["hamburger-drawer-container__payments__amounts"]}
          >
            <div
              className={
                styles["hamburger-drawer-container__payments__amounts__text"]
              }
            >
              {t("corporateAccountBalance")}
            </div>
            <div
              className={
                styles["hamburger-drawer-container__payments__amounts__amount"]
              }
            >
              {headerData?.currency?.code} {headerData?.accountBalance}
            </div>
          </div>
        </div>
        <div className={styles["hamburger-drawer-container__user-details"]}>
          <div
            className={styles["hamburger-drawer-container__user-details__user"]}
          >
            <h4>{t("userDetails")}</h4>
          </div>
          <div
            className={
              styles["hamburger-drawer-container__user-details__basic-details"]
            }
          >
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__text"
                ]
              }
            >
              {data?.data?.name?.charAt(0).toUpperCase()}
            </div>
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__green-tick"
                ]
              }
            >
              <img src="/assets/images/green-tick.png" alt="green-tick" />
            </div>
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__basic-details__name-email"
                ]
              }
            >
              <h4>{data?.data?.name}</h4>
              <h3>{data?.data?.username}</h3>
            </div>
          </div>
          {data?.data?.enable_corporate_selection && (
            <div
              className={
                styles[
                  "hamburger-drawer-container__user-details__switch-button"
                ]
              }
            >
              <Button variant="outlined" onClick={handleRedirect}>
                {t("switchAccount")}
              </Button>
            </div>
          )}
        </div>
        <div className={styles["hamburger-drawer-container__history"]}>
          {selectedStore?.id === "STSA" && (
            <div
              className={
                styles["hamburger-drawer-container__history__language-switch"]
              }
            >
              <div>{t("changeLanguage")}</div>
              <div
                className={
                  styles[
                    "hamburger-drawer-container__history__language-switch__text"
                  ]
                }
                onClick={() => changeLanguage(selectLnag)}
              >
                {i18n.language === "en" ? "العربية" : "English"}
              </div>
            </div>
          )}
          <div
            className={styles["hamburger-drawer-container__history__credit"]}
            onClick={redirectToCreditHistory}
          >
            {t("creditHistory")}
          </div>
          <div
            className={
              styles["hamburger-drawer-container__history__redemption"]
            }
            onClick={redirectToRedemptionHistory}
          >
            {t("redemptionHistory")}
          </div>
          <div
            className={
              styles["hamburger-drawer-container__history__redemption"]
            }
            onClick={redirectToProfile}
          >
            {t("changePassword")}
          </div>
        </div>
        <div
          className={`${styles["hamburger-drawer-container__sign-out-btn"]} tab-hamburger-drawer-btn`}
        >
          <Button
            variant="contained"
            startIcon={logoutLoading ? <CircularProgress size={20} /> : null}
            onClick={handleLogout}
          >
            {logoutLoading ? "" : t("signOut")}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
export default HamburgerDrawer;
