import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FCC } from "../../../../interfaces/common.interface";
import { useMediaQuery } from "@mui/material";
import LandingHeaderTab from "../../header/TabletHeader/TabletHeader";
import CorporateHeader from "../../header/majorHeader/MajorHeader";
import MajorFooter from "../../footer/majorFooter/MajorFooter";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import {
  NotifierSelector,
  getAccessToken,
  setSelectedStore,
  setUserBalance,
  setUserNameFromProfileQuery,
} from "../../commonSlice";
import { STORE_LISTING_QUERY } from "../../../dashboard/dashboard.query";
import {
  DASHBOARD_HEADER_QUERY,
  GET_USER_PROFILE_DETAILS_QUERY,
  LOGOUT_MUTATION,
} from "../../commonApi.query";
import PAGE_URLS from "../../../../constants/pageUrls";
import Notifier from "../../notifier/Notifier";
import { TABLET_MAX_WIDTH } from "../../../../constants/common";

const CommonLayout: FCC = () => {
  const isTabletDevice = useMediaQuery(TABLET_MAX_WIDTH);
  const { pathname } = useLocation();
  const { accessToken } = useAppSelector(getAccessToken);
  const { i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const selectLnag = i18n.language === "ar" ? "en" : "ar";
  const currentLang = i18n.language;
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirect = queryParams.get("redirect");
  const { notifierHeader, notifierText, showNotifier } =
    useAppSelector(NotifierSelector);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    const newUrl: string = pathname.replace(`/${params.locale}`, `/${lng}`);

    navigate(newUrl, { replace: true });
  };

  const {
    data: headerDetails,
    loading: headerApiLoading,
    refetch: refetchDashBoardAPI,
  } = useQuery(DASHBOARD_HEADER_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  });

  useEffect(() => {
    dispatch(setUserBalance(headerDetails?.dashboardHeader?.accountBalance));
  }, [headerDetails?.dashboardHeader?.accountBalance]);

  const { data: profileData, loading: profileApiLoading } = useQuery(
    GET_USER_PROFILE_DETAILS_QUERY,
    {
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "accept-language": currentLang,
        },
      },
    }
  );

  // setting the userName to redux for accessing other pages such as gift success page.
  useEffect(() => {
    dispatch(
      setUserNameFromProfileQuery(
        profileData?.userprofileDetails?.data?.username
      )
    );
  }, [profileData?.userprofileDetails?.data?.username]);

  const {
    loading: storeLoading,
    data: stores,
    refetch: refetchStoreList,
  } = useQuery(STORE_LISTING_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  // setting the default store from API and dispatching an action to set the default store initially.
  useEffect(() => {
    if (stores) {
      const initialStore = stores?.storeList?.edges.find(
        (item: any) => item?.node?.isDefault === true
      );

      if (initialStore) {
        // If there is a default store, set it as the selected store
        dispatch(
          setSelectedStore({
            id: initialStore?.node?.code,
            name: initialStore?.node?.name,
            image: initialStore?.node?.country?.flagIcon,
          })
        );
      } else if (stores?.storeList?.edges.length > 0) {
        // If there is no default store, but there are stores available, set the first store as the selected store
        const firstStore = stores?.storeList?.edges[0]?.node;
        dispatch(
          setSelectedStore({
            id: firstStore?.code,
            name: firstStore?.name,
            image: firstStore?.country?.flagIcon,
          })
        );
      }
    }
  }, [stores]);

  // fix for store not updating when user comes from gift purchase email to view the full terms.
  useEffect(() => {
    if (redirect) {
      refetchStoreList();
    }
  }, [redirect]);

  // calling this APIS everytime the rouute changes so that
  // the response will get updated on when moving from one page to other
  useEffect(() => {
    if (pathname) {
      refetchDashBoardAPI();
    }
  }, [pathname]);

  const handleCountryClick = (id?: any, name?: any, image?: any) => {
    const selectedStore = { id, name, image };
    dispatch(setSelectedStore(selectedStore));
  };

  const [logout, { loading }] = useMutation(LOGOUT_MUTATION);

  const handleLogout = async () => {
    try {
      await logout({
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });
      navigate(`/${currentLang}/${PAGE_URLS.LOGIN}`);
    } catch (error) {
      console.log(error);
    }
  };

  const renderHeader = () => {
    if (isTabletDevice) {
      return (
        <LandingHeaderTab
          headerData={headerDetails?.dashboardHeader}
          profileData={profileData?.userprofileDetails}
          stores={stores?.storeList?.edges}
          handleCountryClick={handleCountryClick}
          storeLoading={storeLoading}
          handleLogout={handleLogout}
          logoutLoading={loading}
          profileLoading={profileApiLoading}
          headerApiLoading={headerApiLoading}
        />
      );
    } else {
      return (
        <CorporateHeader
          changeLang={changeLanguage}
          selectedLanguage={selectLnag}
          headerData={headerDetails?.dashboardHeader}
          profileData={profileData?.userprofileDetails}
          storeLists={stores?.storeList?.edges}
          storeLoading={storeLoading}
          handleCountryClick={handleCountryClick}
          handleLogout={handleLogout}
          logoutLoading={loading}
        />
      );
    }
  };

  return (
    <div>
      <Notifier
        showSnackBar={showNotifier}
        titleContent={notifierText}
        titleHead={notifierHeader}
      />
      <div>{renderHeader()}</div>
      <div>
        <Outlet />
      </div>
      <div>
        <MajorFooter />
      </div>
    </div>
  );
};

export default CommonLayout;
