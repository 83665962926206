import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { FCC } from "../interfaces/common.interface";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const LocaleRoute: FCC = () => {
  const { i18n } = useTranslation();
  const { locale } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const localeArray = ["en", "ar"];

  useEffect(() => {
    if (locale && i18n.resolvedLanguage !== locale) {
      if (localeArray?.includes(locale)) {
        i18n.changeLanguage(locale);
      } else {
        navigate("/" + i18n.resolvedLanguage + pathname, { replace: true });
      }
    }
  }, [locale]);
  return <Outlet />;
};

export default LocaleRoute;
