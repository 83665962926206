import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { API_BASE_URL } from "../constants/apiUrls";
import { onError } from "@apollo/client/link/error";

const httpLink = new HttpLink({ uri: API_BASE_URL, credentials: "include" });

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (operation.operationName !== "RefreshTokenMutation") {
    const errors = graphQLErrors as any;
    if (errors && errors.length > 0) {
      const statusCode = errors[0].status;
      switch (statusCode) {
        case 401:
          // Redirect to 401 page
          window.location.href = "/error/401";
          break;
        case 403:
          // Redirect to 403 page
          window.location.href = "/error/404";
          break;
        case 404:
          // Redirect to 404 page
          window.location.href = "/error/404";
          break;
        default:
      }
    }
  }
  return forward(operation);
});

export const client = new ApolloClient({
  link: from([errorLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
  },
});
