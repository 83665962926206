import { FormControl, Skeleton, TextField } from "@mui/material";
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useRef,
  useTransition,
} from "react";
import styles from "./GiftSelectionFilters.module.scss";
import WebCategorySelector from "../../web/webCategorySelector/WebCategorySelector";
import { useTranslation } from "react-i18next";

const GiftSelectionFilters: FC<{
  handleCategoryOpen: () => void;
  handleCategoryClose: () => void;
  handleCategoryClick: (name: string, id: any, image: string) => void;
  showCategorySelector: boolean;
  selectedCategory: any;
  categories: any;
  handleBrandName: (event: ChangeEvent<HTMLInputElement>) => void;
  categoryLoading?: boolean;
}> = ({
  categories,
  handleCategoryClick,
  handleCategoryClose,
  handleCategoryOpen,
  selectedCategory,
  showCategorySelector,
  handleBrandName,
  categoryLoading,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        handleCategoryClose?.();
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  if (categoryLoading) {
    return (
      <Skeleton height={70} animation="wave" sx={{ marginBottom: "30px" }} />
    );
  }

  return (
    <div
      className={`${styles["filters-container"]} dashboard-filters-container`}
    >
      <div className={styles["filters-container__category"]} ref={componentRef}>
        <div
          className={styles["filters-container__category__select-container"]}
          onClick={handleCategoryOpen}
        >
          <label htmlFor="myInput">{t("selectCategory")}</label>
          <input
            type="text"
            readOnly
            id="myInput"
            value={selectedCategory.name}
          />
          <img src="/assets/images/dwon-arrow-bold.png" alt="" />
        </div>
        {showCategorySelector && (
          <WebCategorySelector
            handleCategoryClick={handleCategoryClick}
            categories={categories}
          />
        )}
      </div>
      <div className={styles["filters-container__search"]}>
        <FormControl fullWidth>
          <TextField
            placeholder={t("searchBrandsHere") || ""}
            onChange={handleBrandName}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default GiftSelectionFilters;
