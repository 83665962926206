// queries.js
import { gql } from "@apollo/client";

export const REDEMPTION_LIST_QUERY = gql`
  query RedemptionList($startDate: String!, $endDate: String!) {
    redemptionList(startDate: $startDate, endDate: $endDate) {
      totalCount
      edges {
        cursor
        node {
          createdOn
          amount
          expiresOn
          id
          giftId
          brand {
            name
            code
          }
          currency {
            name
            isoCode
          }
          isExpired
        }
      }
    }
  }
`;

export const RESEND_GIFT_QUERY = gql`
  query GiftResend($giftId: String!) {
    giftResend(giftId: $giftId) {
      data
      message
      status
    }
  }
`;
