import React from "react";
import { FCC } from "../../../../interfaces/common.interface";
import MinorHeader from "../../header/corporateSelectionHeader/CorporateSelectionHeader";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../footer/CorporateSelectionFooter";
import {
  GET_USER_PROFILE_DETAILS_QUERY,
  LOGOUT_MUTATION,
} from "../../commonApi.query";
import { useMutation, useQuery } from "@apollo/client";
import { useAppSelector } from "../../../../utils/hooks";
import { getAccessToken } from "../../commonSlice";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";

const CorporateSelectionLayout: FCC = () => {
  const navigate = useNavigate();
  const { accessToken } = useAppSelector(getAccessToken);
  const { i18n } = useTranslation();
  const currentLang = i18n.language;
  const [logout, { loading }] = useMutation(LOGOUT_MUTATION);

  const { data } = useQuery(GET_USER_PROFILE_DETAILS_QUERY, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  const handleLogout = async () => {
    try {
      await logout({
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });
      navigate(`/${currentLang}/${PAGE_URLS.LOGIN}`);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <MinorHeader
        handleLogout={handleLogout}
        logoutLoading={loading}
        profileData={data}
      />
      <Outlet />
      <Footer />
    </div>
  );
};

export default CorporateSelectionLayout;
