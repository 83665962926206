import { gql } from "@apollo/client";

export const CORPORATE_LISTING_QUERY = gql`
  query corporateListing($first: Int!, $after: String) {
    corporateList(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          balance
          currency
          id
          corporate {
            companyName
            name
            id
            logo
            slug
          }
        }
        cursor
      }
    }
  }
`;

export const CORPORATE_SELECTION_MUTATION = gql`
  mutation corporateSelection($corporateId: Int!) {
    selectCorporate(input: { corporateId: $corporateId }) {
      data
      message
      status
    }
  }
`;
