import {
  Button,
  CircularProgress,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { FC, FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonAnchor from "../common/ButtonAnchor";
import { useMutation } from "@apollo/client";
import { getUserName, showNotifierPopup } from "../common/commonSlice";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";
import { formatTime } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { ACTIVATION_ACTION, SM_MAX_WIDTH } from "../../constants/common";
import { RESEND_OTP_MUTATION, VERIFY_OTP_MUTATION } from "./activation.query";

interface OtpErrors {
  otp: string;
}

const ActivationStepTwo: FC<{
  nextStep: () => void;
  token?: string;
  showTimerOnMount?: boolean;
}> = ({ nextStep, token, showTimerOnMount }) => {
  const isMobile = useMediaQuery(SM_MAX_WIDTH);

  const responsiveFieldsMargin = isMobile ? "20px" : "30px";

  const styles = {
    link: {
      color: "#3c2f8f",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
      marginLeft: "5px",
    },
    text: {
      color: "#000",
      textDecoration: "none",
      fontSize: "14px",
      fontWeight: "normal",
      display: "flex",
      justifyContent: "center",
      marginBottom: isMobile ? "22px" : "59px",
    },
    resendOtp: {
      display: "flex",
    },
  };
  const [otp, setOtp] = useState<string>("");
  const [error, setError] = useState<OtpErrors>({ otp: "" });
  const { t, i18n } = useTranslation();
  const [graphQlError, setGraphQlError] = useState("");
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);
  const dispatch = useAppDispatch();
  const currentLang = i18n.language;

  const { userName } = useAppSelector(getUserName);

  const showOtpNotifier = () => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: `${t("otp-success-sent")}`,
        notifierText: `${t("pls-enter-email-otp")}`,
      })
    );
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (timerActive) {
      // Only start the timer if it's active
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer, timerActive]);

  // for running the timer when this component mounts (when showTimerOnMount is true)
  useEffect(() => {
    if (showTimerOnMount) {
      setTimer(45);
      setTimerActive(true);
    }
  }, [showTimerOnMount]);

  const [verifyOtp, { loading }] = useMutation(VERIFY_OTP_MUTATION);

  const [resendOtp] = useMutation(RESEND_OTP_MUTATION);

  const validateFields = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      let errors: OtpErrors = { otp: "" };
      let hasError = false;

      if (!otp) {
        // Check if otp is empty
        hasError = true;
        errors = {
          ...errors,
          otp: t("otp-required"),
        };
      }

      hasError ? reject(errors) : resolve(otp);
    });
  };

  const handleOtpChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);

    // Clear the error message when the user enters a new value
    if (error.otp) {
      setError({ otp: "" });
    }
  };

  const handleClearError = () => {
    // Clear the error state when mouse leaves the text field
    setError({ otp: "" });
    setGraphQlError("");
  };
  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const values = await validateFields();
      // Call the mutation here
      const { data } = await verifyOtp({
        variables: {
          otp: values,
          signedUsername: token,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      const is_otp_verified = data?.verifyOtp?.data?.is_otp_verified;
      is_otp_verified && nextStep();
    } catch (err: any) {
      const graphQlError = err && err.graphQLErrors && err.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setError(err);
      }
    }
  };

  // calling the resend OTP mutation
  const handleResendOtp = async () => {
    setTimer(45);
    setTimerActive(true);
    setOtp("");
    handleClearError?.();
    try {
      await resendOtp({
        variables: {
          username: userName,
          action: ACTIVATION_ACTION,
        },
        context: {
          headers: {
            "accept-language": currentLang,
          },
        },
      });
      showOtpNotifier();
    } catch (err: any) {
      console.log(err);
    }
  };

  return (
    <div className="activation-form">
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          placeholder={t("enter-otp") ?? ""}
          color="info"
          sx={{ marginBottom: responsiveFieldsMargin }}
          onChange={handleOtpChange}
          onBlur={handleClearError}
          helperText={!otp ? error?.otp : graphQlError}
          error={!!error.otp || !!graphQlError}
          value={otp}
          inputProps={{
            inputMode: "numeric",
            pattern: "[0-9]*",
            maxLength: 6,
          }}
          onKeyPress={(e) => {
            if (isNaN(Number(e.key))) {
              e.preventDefault();
            }
          }}
        />
        <Button
          className="auth-button"
          variant="contained"
          type="submit"
          sx={{ marginBottom: responsiveFieldsMargin }}
          startIcon={
            loading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : null
          }
        >
          {loading ? "" : t("continue")}
        </Button>
        <div style={styles.text}>
          {timer > 0 ? (
            `${t("resendVerificationCode")} ${formatTime(timer)}`
          ) : (
            <div style={styles.resendOtp}>
              {t("not-recieve-otp")}
              <ButtonAnchor style={styles.link} onClick={handleResendOtp}>
                {t("resend")}
              </ButtonAnchor>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ActivationStepTwo;
