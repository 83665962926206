import React, { FC } from "react";
import styles from "./BrandDetailingTabSkeleton.module.scss";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import BrandDetailAccordion from "../../common/brandDetailAccordion/BrandDetailAccordion";

const BrandDetailingTabSkeleton: FC = () => {
  const denominations = Array.from({ length: 5 });
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${styles["brand-detail-container"]} brand-detail-tab-wrapper`}
      >
        <div className={styles["brand-detail-container__back"]}>
          <img src="/assets/images/brand-detail-back.png" alt="" />
          <h4>{t("back")}</h4>
        </div>
        <div className={styles["brand-detail-container__header"]}>
          <div className={styles["brand-detail-container__header__left"]}>
            <Skeleton
              variant="rectangular"
              width={48}
              height={48}
              sx={{ borderRadius: "10px" }}
            />
            <Skeleton variant="text" width={123} />
          </div>
          <div className={styles["brand-detail-container__header__right"]}>
            <Skeleton variant="text" width={118} />
          </div>
        </div>
        <div className={styles["brand-detail-container__logo-container"]}>
          <div
            className={styles["brand-detail-container__logo-container__top"]}
          >
            <div
              className={
                styles["brand-detail-container__logo-container__top__validity"]
              }
            >
              <Skeleton variant="text" width={117} />
            </div>
            <div
              className={
                styles["brand-detail-container__logo-container__top__instore"]
              }
            >
              <Skeleton width={80} variant="text" />
            </div>
          </div>
          <div
            className={styles["brand-detail-container__logo-container__bottom"]}
          >
            <Skeleton
              variant="rectangular"
              width={416}
              height={266.7}
              sx={{ borderRadius: "10px" }}
            />
          </div>
        </div>
        <div className={styles["brand-detail-container__denomination-section"]}>
          <img src="/assets/images/minus-icon.png" alt="" />
          <div
            className={
              styles[
                "brand-detail-container__denomination-section__denom-value"
              ]
            }
          >
            <Skeleton variant="text" width={156} height={61} />
          </div>
          <img src="/assets/images/plus-icon.png" alt="" />
        </div>
        <div className={styles["brand-detail-container__amount-selection"]}>
          {denominations &&
            denominations.length > 0 &&
            denominations.map((item: any, index: any) => (
              <div key={index}>
                <div
                  className={
                    styles[
                      "brand-detail-container__amount-selection__single-item"
                    ]
                  }
                >
                  <Skeleton variant="text" width={"50%"} />
                </div>
              </div>
            ))}
        </div>
        <div className={styles["brand-detail-container__checkout-button"]}>
          <Skeleton
            variant="text"
            width={"100%"}
            height={80}
            sx={{ borderRadius: "10px" }}
          />
        </div>
        <BrandDetailAccordion description={""} terms={""} locations={[]} />
      </div>
    </>
  );
};

export default BrandDetailingTabSkeleton;
