import React, { FC, MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./StoreSelector.module.scss";
import { Tooltip, useMediaQuery } from "@mui/material";
import { SelectedStore } from "../../../../interfaces/common.interface";
import { DYNAMIC_STORE_WIDTH } from "../../../../constants/common";

interface StoreSelectorProps {
  selectedCountry?: SelectedStore;
  handlecountryClick?: (id?: any, name?: string, image?: string) => void;
  stores?: any;
}

const StoreSelector: FC<StoreSelectorProps> = ({
  selectedCountry,
  handlecountryClick,
  stores,
}) => {
  const isWeb = useMediaQuery(DYNAMIC_STORE_WIDTH);

  const [tooltipStates, setTooltipStates] = useState<boolean[]>([]);

  const handleShouldShow = (
    { currentTarget }: MouseEvent<Element>,
    index: number
  ) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipStates((prevStates) => {
        const newStates = [...prevStates];
        newStates[index] = true;
        return newStates;
      });
    }
  };

  const handleShouldHide = (index: number) => () => {
    setTooltipStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };
  return (
    <div
      className={`${styles["store-selector-container"]} ${
        isWeb ? styles["is-web"] : ""
      }`}
      id="scrollable-container"
    >
      {stores &&
        stores?.length > 0 &&
        stores.map((item: any, index: any) => (
          <div
            className={styles["store-selector-container__country-section"]}
            key={index}
            onClick={() => {
              handlecountryClick?.(
                item?.node?.code,
                item?.node?.name,
                item?.node?.country?.flagIcon
              );
            }}
          >
            <div
              className={
                styles["store-selector-container__country-section__left"]
              }
            >
              <div
                className={
                  styles[
                    "store-selector-container__country-section__left__wrapper"
                  ]
                }
              >
                <img src={item?.node?.country?.flagIcon} alt="" />

                <Tooltip
                  title={item?.node?.name}
                  open={tooltipStates[index] || false}
                  placement="right"
                >
                  <h4
                    onMouseEnter={(e) => handleShouldShow(e, index)}
                    onMouseLeave={handleShouldHide(index)}
                  >
                    {item?.node?.name}
                  </h4>
                </Tooltip>
              </div>
            </div>
            <div
              className={
                styles["store-selector-container__country-section__right"]
              }
            >
              {selectedCountry?.id === item?.node?.code && (
                <img src="/assets/images/store-selector-tick.png" alt="" />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export default StoreSelector;
