import React, { FC } from "react";
import styles from "./BrandDetailMobileSkeleton.module.scss";
import { Skeleton } from "@mui/material";
import BrandDetailAccordion from "../../common/brandDetailAccordion/BrandDetailAccordion";


const BrandDetaingMobileSkeleton: FC = () => {
  const denominations = Array.from({ length: 8 });
  return (
    <>
      <div className={styles["brand-detail-container"]}>
        <div className="brand-detail-mobile-wrapper">
          <div className={styles["brand-detail-container__header"]}>
            <div className={styles["brand-detail-container__header__left"]}>
              <img src="/assets/images/left-arrow.png" alt="" />
            </div>
            <div className={styles["brand-detail-container__header__right"]}>
              <Skeleton variant="text" width={154} />
            </div>
            <div></div>
          </div>
          <div className={styles["brand-detail-container__logo-container"]}>
            <Skeleton
              variant="rectangular"
              width={281}
              height={182.7}
              sx={{ borderRadius: "10px" }}
            />
            <div
              className={
                styles["brand-detail-container__logo-container__texts"]
              }
            >
              <Skeleton variant="text" width={212} />
            </div>
          </div>
          <div
            className={styles["brand-detail-container__denomination-section"]}
          >
            <img src="/assets/images/minus-icon.png" alt="" />
            <div
              className={
                styles[
                  "brand-detail-container__denomination-section__denom-value"
                ]
              }
            >
              <Skeleton variant="text" height={43} width={141} />
            </div>

            <img src="/assets/images/plus-icon.png" alt="" />
          </div>
          <div className={styles["brand-detail-container__amount-selection"]}>
            {denominations &&
              denominations.length > 0 &&
              denominations.map((item: any, index: any) => (
                <div key={index}>
                  <div
                    className={
                      styles[
                        "brand-detail-container__amount-selection__single-item"
                      ]
                    }
                    key={index}
                  >
                    <Skeleton variant="text" width={"50%"} height={"90%"} />
                  </div>
                </div>
              ))}
          </div>

          <BrandDetailAccordion description={""} terms={""} locations={[]} />
        </div>
      </div>

      <div className={styles["brand-detail-container__checkout-button"]}>
        <Skeleton variant="text" width={"60%"} height={80} />
      </div>
    </>
  );
};

export default BrandDetaingMobileSkeleton;
