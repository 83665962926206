import { gql } from "graphql-tag";

export const DASHBOARD_HEADER_QUERY = gql`
  query DashboardHeader {
    dashboardHeader {
      corporateName
      corporateLogo
      accountBalance
      currency {
        name
        code
      }
    }
  }
`;

export const GET_USER_PROFILE_DETAILS_QUERY = gql`
  query GetUserProfileDetails {
    userprofileDetails {
      data
      message
      status
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout {
    logout {
      message
      status
      data
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ResetPassword(
    $currentPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      message
      status
      data
    }
  }
`;
