import { TextField, Button, CircularProgress } from "@mui/material";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "./ProfileSettingsMobile.module.scss";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks";
import { getAccessToken, showNotifierPopup } from "../../../common/commonSlice";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD_MUTATION } from "../../../common/commonApi.query";
import { passwordRegex } from "../../../../utils/regex";
import PAGE_URLS from "../../../../constants/pageUrls";

interface FieldsState {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

const ProfileSettingsMobile: FC = () => {
  const { t, i18n } = useTranslation();
  const { accessToken } = useAppSelector(getAccessToken);
  const dispatch = useAppDispatch();
  const currentLang = i18n.language;
  const navigate = useNavigate();

  const [fields, setFields] = useState<FieldsState>({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [graphQlError, setGraphQlError] = useState("");

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD_MUTATION);

  const showNotifier = (header: string) => {
    dispatch(
      showNotifierPopup({
        showNotifier: true,
        notifierHeader: header,
        notifierText: t("pls-sign-with-new-pw"),
      })
    );
  };

  const validateFields = () => {
    return new Promise(function (resolve, reject) {
      let errors = { password: "", newPassword: "", confirmPassword: "" };
      let hasError = false;

      if (!fields.password) {
        hasError = true;
        errors = {
          ...errors,
          password: t("password-required"),
        };
      } else if (!passwordRegex.test(fields.newPassword)) {
        hasError = true;
        errors = {
          ...errors,
          newPassword: t("password-error-text"),
        };
      } else if (fields.newPassword !== fields.confirmPassword) {
        hasError = true;
        errors = {
          ...errors,
          confirmPassword: t("password-not-match"),
        };
      }

      if (!hasError) {
        // Clear the error messages
        errors = { password: "", newPassword: "", confirmPassword: "" };
      }

      hasError ? reject(errors) : resolve(fields);
    });
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFields((prev) => ({ ...prev, password: value }));
  };

  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setFields((prev) => ({ ...prev, newPassword: value }));
    if (!value) {
      setErrors((prev) => ({ ...prev, newPassword: "" }));
    } else if (value.length >= 8 && value.length <= 25) {
      setErrors((prev) => ({ ...prev, newPassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setFields((prev) => ({ ...prev, confirmPassword: value }));
    if (!value) {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    } else if (value === fields.newPassword) {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      await validateFields();
      const { data } = await changePassword({
        variables: {
          currentPassword: fields.password,
          newPassword: fields.newPassword,
          confirmPassword: fields.confirmPassword,
        },
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "accept-language": currentLang,
          },
        },
      });
      const message = data?.resetPassword?.message;
      showNotifier?.(message);
      navigate(`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`);
    } catch (error: any) {
      const graphQlError =
        error && error.graphQLErrors && error.graphQLErrors[0];
      if (graphQlError?.status === 400) {
        setGraphQlError(graphQlError?.message);
      } else if (graphQlError?.status === 429) {
        navigate("/error/429");
      } else {
        setErrors(error);
      }
    }
  };

  const handleClearError = () => {
    // Clear the error state when mouse leaves the text field
    setErrors({ password: "", newPassword: "", confirmPassword: "" });
    setGraphQlError("");
  };

  return (
    <div className={`${styles["profile-container"]} profile-container`}>
      <div className={styles["profile-container__banner"]}>
        <h4>{t("changePassword")}</h4>
      </div>
      <section className="wrapper">
        <div className={`${styles["profile-container__box"]}`}>
          <TextField
            label={t("currentPassword")}
            color="info"
            onChange={handlePasswordChange}
            helperText={!fields.password ? errors.password : graphQlError}
            error={!!errors.password || !!graphQlError}
            onBlur={handleClearError}
            inputProps={{ maxLength: 255 }}
            type="password"
            sx={{ marginBottom: graphQlError && "37px" }}
          />
          <TextField
            label={t("new-password")}
            color="info"
            onChange={handleNewPasswordChange}
            helperText={errors.newPassword}
            error={!!errors.newPassword}
            onBlur={handleClearError}
            inputProps={{ maxLength: 255 }}
            type="password"
            sx={{ marginBottom: errors.newPassword && "37px" }}
          />
          <TextField
            label={t("confirm-password")}
            color="info"
            onChange={handleConfirmPasswordChange}
            helperText={errors.confirmPassword}
            error={!!errors.confirmPassword}
            onBlur={handleClearError}
            inputProps={{ maxLength: 255 }}
            type="password"
          />

          <Button
            variant="outlined"
            onClick={handleSubmit}
            startIcon={
              loading ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : null
            }
          >
            {loading ? "" : t("saveChanges")}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default ProfileSettingsMobile;
