import React, { FC } from "react";
import styles from "./CategorySelector.module.scss";

interface CategorySelectorProps {
  handleCategoryClick?: (name: string, id: any, image?: string) => void;
  categories: any;
  selectedCategory: any;
}

const CategorySelector: FC<CategorySelectorProps> = ({
  handleCategoryClick,
  categories,
  selectedCategory,
}) => {
  return (
    <div
      className={styles["category-selector-container"]}
      id="scrollable-container"
    >
      {categories &&
        categories.length > 0 &&
        categories.map((item: any, index: any) => (
          <div
            className={styles["category-selector-container__category-section"]}
            key={index}
            onClick={() =>
              handleCategoryClick?.(item?.node?.name, item?.node?.code)
            }
          >
            {item?.node?.name}
          </div>
        ))}
    </div>
  );
};

export default CategorySelector;
