import { gql } from "@apollo/client";

export const VERIFY_USER_STATUS_QUERY = gql`
  query VerifyRedeemLink($corporateSlug: String!, $signedUserName: String!) {
    verifyRedeemLink(
      corporateSlug: $corporateSlug
      signedUserName: $signedUserName
    ) {
      data
      message
      status
    }
  }
`;

export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation verify_user(
    $username: String!
    $signedUserName: String!
    $corporateSlug: String!
  ) {
    verifyAccount(
      username: $username
      signedUserName: $signedUserName
      corporateSlug: $corporateSlug
    ) {
      message
      status
      data
    }
  }
`;

export const VERIFY_OTP_MUTATION = gql`
  mutation verify_otp($otp: String!, $signedUsername: String!) {
    verifyOtp(otp: $otp, signedUsername: $signedUsername) {
      message
      status
      data
    }
  }
`;

export const RESEND_OTP_MUTATION = gql`
  mutation resend_otp($username: String!, $action: String!) {
    resendOtp(username: $username, action: $action) {
      message
      status
      data
    }
  }
`;

export const ACTIVATE_ACCOUNT_MUTATION = gql`
  mutation activateAccount(
    $username: String!
    $password: String!
    $confirmPassword: String!
    $signedUsername: String!
    $corporateSlug: String!
  ) {
    activateAccount(
      username: $username
      password: $password
      confirmPassword: $confirmPassword
      signedUsername: $signedUsername
      corporateSlug: $corporateSlug
    ) {
      message
      status
      data
    }
  }
`;
