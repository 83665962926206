import styles from "./CartSummarySkeleton.module.scss";
import { Skeleton } from "@mui/material";

const CartSummarySkeleton = () => {
  return (
    <div className={styles["order-summary-container"]}>
      <div className={styles["order-summary-container__heading"]}>
        <Skeleton variant="text" width={113} height={17} />
      </div>
      <div className={styles["order-summary-container__inner-container"]}>
        <div
          className={
            styles[
              "order-summary-container__inner-container__totalitem-container"
            ]
          }
        >
          <div
            className={
              styles[
                "order-summary-container__inner-container__totalitem-container__items"
              ]
            }
          >
            <Skeleton variant="text" width={61} height={17} />
          </div>
          <div
            className={
              styles[
                "order-summary-container__inner-container__totalitem-container__qty"
              ]
            }
          >
            <Skeleton variant="text" width={61} height={17} />
          </div>
        </div>
        <div
          className={
            styles["order-summary-container__inner-container__container"]
          }
        >
          <div
            className={
              styles[
                "order-summary-container__inner-container__container__total-gift-value"
              ]
            }
          >
            <Skeleton variant="text" width={61} height={17} />
          </div>
          <div
            className={
              styles[
                "order-summary-container__inner-container__container__price"
              ]
            }
          >
            <Skeleton variant="text" width={61} height={17} />
          </div>
        </div>
      </div>
      <div className={styles["order-summary-container__total"]}>
        <Skeleton variant="text" width={36} height={20} />
        <Skeleton variant="text" width={62} height={27} />
      </div>
      <div className={styles["order-summary-container__button-div"]}>
        <Skeleton
          variant="text"
          width={323}
          height={50}
          sx={{ borderRadius: "10px" }}
        />
        <Skeleton
          variant="text"
          width={323}
          height={50}
          sx={{ borderRadius: "10px" }}
        />
      </div>
    </div>
  );
};

export default CartSummarySkeleton;
