import React, { FC } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styles from "./CorporateSlider.module.scss";
import { useQuery } from "@apollo/client";
import { GET_BANNERS_QUERY } from "../dashboard.query";
import { useAppSelector } from "../../../utils/hooks";
import { getAccessToken } from "../../common/commonSlice";
import { useTranslation } from "react-i18next";
import { Skeleton } from "@mui/material";

const CorporateSlider: FC = () => {
  const { accessToken } = useAppSelector(getAccessToken);
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const { data, loading, error } = useQuery(GET_BANNERS_QUERY, {
    variables: {
      language: currentLang.toUpperCase(),
    },
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "accept-language": currentLang,
      },
    },
  });

  if (loading) {
    return (
      <div style={{ aspectRatio: "16/2" }}>
        <Skeleton
          width={"100%"}
          variant="rectangular"
          height={"100%"}
          animation="wave"
        />
      </div>
    );
  }

  if (error) {
    console.error("Error fetching data:", error);
  }

  return (
    <div className={styles["corporate-slider-container"]}>
      <Carousel
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={true}
        interval={3000}
        showArrows={false}
        swipeable
        stopOnHover={true}
        showIndicators={data?.banners?.length > 1}
        showStatus={false}
      >
        {data &&
          data.banners?.length > 0 &&
          data?.banners.map((item: any, index: any) => (
            <img src={item?.image} alt="" key={index} />
          ))}
      </Carousel>
    </div>
  );
};

export default CorporateSlider;
