import { FC } from "react";
import CartProducts from "../../features/cart/cartProducts/skeleton/CartProductsSkeleton";
import CartSummary from "../../features/cart/cartSummary/CartSummary";
import styles from "./Cart.module.scss";
const Cart: FC = () => {
  return (
    <div className={styles["cart-page"]}>
      <CartProducts />
      <CartSummary />
    </div>
  );
};

export default Cart;
