import React, { FC, useEffect, useRef, useState } from "react";
import HamburgerDrawer from "../../../dashboard/tablet/hamburgerDrawer/HamburgerDrawer";
import StoreSelector from "../../../dashboard/web/storeSelector/StoreSelector";
import styles from "./TabletHeader.module.scss";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../utils/hooks";
import { getSelectedStore } from "../../commonSlice";
import LandingHeaderTabSkeleton from "./skeleton/TabletHeaderSkeleton";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";
import { Tooltip } from "@mui/material";

// as the header for tablet is diffrent from web, the tablet and mobile are having the same header.

const LandingHeaderTab: FC<{
  handleCountryClick?: (id?: any, name?: any, image?: any) => void;
  headerData?: any;
  profileData?: any;
  stores?: any;
  storeLoading?: boolean;
  handleLogout?: () => void;
  logoutLoading?: boolean;
  profileLoading?: boolean;
  headerApiLoading?: boolean;
}> = ({
  handleCountryClick,
  headerData,
  profileData,
  stores,
  storeLoading,
  handleLogout,
  logoutLoading,
  headerApiLoading,
  profileLoading,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);
  const { pathname } = useLocation();
  const isDashBoard = pathname.split("/").includes("dashboard");
  const { selectedStore } = useAppSelector(getSelectedStore);
  const { i18n } = useTranslation();
  const currentLang = i18n.language;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleStoreOpen = () => {
    setStoreOpen((prv) => !prv);
  };

  const storeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        storeRef.current &&
        !storeRef.current.contains(event.target as Node)
      ) {
        setStoreOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);

  const handleMouseOver = ({ currentTarget }: any) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const handleMouseOut = () => {
    setTooltipEnabled(false);
  };

  if (headerApiLoading && profileLoading && storeLoading) {
    return <LandingHeaderTabSkeleton />;
  }

  return (
    <div className={`${styles["header-tab-container"]}`}>
      <div
        className={styles["header-tab-container__stores"]}
        onClick={isDashBoard ? handleStoreOpen : undefined}
        ref={storeRef}
      >
        {!isDashBoard ? (
          <>
            <img
              src={selectedStore?.image}
              alt=""
              className={styles["header-tab-container__stores__flag"]}
            />
            <Tooltip
              title={selectedStore?.name}
              placement="top"
              open={tooltipEnabled || false}
            >
              <p onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {selectedStore?.name}
              </p>
            </Tooltip>
          </>
        ) : (
          <>
            <img
              src={selectedStore?.image}
              alt=""
              className={styles["header-tab-container__stores__flag"]}
            />
            <Tooltip
              title={selectedStore?.name}
              placement="top"
              open={tooltipEnabled || false}
            >
              <p onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {selectedStore?.name}
              </p>
            </Tooltip>

            <img
              src="/assets/images/down-arrow.png"
              alt=""
              className={styles["header-tab-container__stores__down-arrow"]}
            />
          </>
        )}
        {storeOpen && (
          <StoreSelector
            selectedCountry={selectedStore}
            handlecountryClick={handleCountryClick}
            stores={stores}
          />
        )}
      </div>
      <div className={styles["header-tab-container__logo"]}>
        <Link to={`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`}>
          <img src={headerData?.corporateLogo} alt="" />
        </Link>
      </div>
      <div className={styles["header-tab-container__hamburger"]}>
        <img
          src="/assets/images/tab-hamburger.png"
          alt=""
          onClick={handleDrawerOpen}
        />
      </div>

      <HamburgerDrawer
        drawerOpen={drawerOpen}
        onClose={handleDrawerClose}
        headerData={headerData}
        data={profileData}
        logoutLoading={logoutLoading}
        handleLogout={handleLogout}
        handleDrawerClose={handleDrawerClose}
      />
    </div>
  );
};

export default LandingHeaderTab;
