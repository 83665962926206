export const nl2br = (str: string) => {
    const lines = str?.split(/(?:\r\n|\r|\n)/g);
    const liElements = lines.map((data) => {
      if (data === "<ol>") {
        return `${data}`;
      }
      return `<li>${data}</li>`;
    });
    return liElements.join(""); // Join the array of liElements into a single string
  };
  
  export const nl2brParagraph = (str: string) => {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  };
