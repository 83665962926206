import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import UserDetailsBox from "../../userDetailsBox/UserDetailsBox";
import styles from "./MajorHeader.module.scss";
import { Link, useLocation } from "react-router-dom";
import StoreSelector from "../../../dashboard/web/storeSelector/StoreSelector";
import { t } from "i18next";
import { useAppSelector } from "../../../../utils/hooks";
import { getSelectedStore } from "../../commonSlice";
import CorporateHeaderSkeleton from "./skeleton/MajorHeaderSkeleton";
import PAGE_URLS from "../../../../constants/pageUrls";
import { CorporateHeaderProps } from "../../../../interfaces/common.interface";
import { Tooltip } from "@mui/material";

const CorporateHeader: FC<CorporateHeaderProps> = ({
  changeLang,
  selectedLanguage,
  handleCountryClick,
  storeLists,
  headerData,
  profileData,
  storeLoading,
  handleLogout,
  logoutLoading,
}) => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const [storeOpen, setStoreOpen] = useState(false);
  const { pathname } = useLocation();
  const isDashBoard = pathname.split("/").includes("dashboard");
  const { selectedStore } = useAppSelector(getSelectedStore);
  const currentLang = i18n.language;
  const location = useLocation();

  const isActiveLink = (targetPath: string) =>
    location.pathname.includes(targetPath);

  const handleStoreOpen = () => {
    setStoreOpen((prv) => !prv);
  };

  const handleCloseProfileBox = (event: any) => {
    event?.stopPropagation();
    setOpen(false);
  };

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const storeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutsideStore(event: MouseEvent) {
      if (
        storeRef.current &&
        !storeRef.current.contains(event.target as Node)
      ) {
        setStoreOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutsideStore);

    return () => {
      document.removeEventListener("click", handleClickOutsideStore);
    };
  }, []);
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>(false);

  const handleMouseOver = ({ currentTarget }: any) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };

  const handleMouseOut = () => {
    setTooltipEnabled(false);
  };

  if (storeLoading) {
    return <CorporateHeaderSkeleton />;
  }

  return (
    <div className={styles["header-container"]}>
      <div className={styles["header-container__wrapper"]}>
        <div className={styles["header-container__wrapper__left"]}>
          <div className={styles["header-container__wrapper__left__logo"]}>
            <Link to={`/${currentLang}/${PAGE_URLS.LANDING_PAGE}`}>
              <img src={headerData?.corporateLogo} alt="corporate-logo" />
            </Link>
          </div>
          <div
            className={styles["header-container__wrapper__left__stores"]}
            onClick={isDashBoard ? handleStoreOpen : undefined}
            ref={storeRef}
          >
            {!isDashBoard ? (
              <>
                <img
                  src={selectedStore?.image}
                  alt=""
                  className={
                    styles["header-container__wrapper__left__stores__flag"]
                  }
                />
                <Tooltip
                  title={selectedStore?.name}
                  placement="top"
                  open={tooltipEnabled || false}
                >
                  <p onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    {selectedStore?.name}
                  </p>
                </Tooltip>
              </>
            ) : (
              <>
                <img
                  src={selectedStore?.image}
                  alt=""
                  className={
                    styles["header-container__wrapper__left__stores__flag"]
                  }
                />
                <Tooltip
                  title={selectedStore?.name}
                  placement="top"
                  open={tooltipEnabled || false}
                >
                  <p onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    {selectedStore?.name}
                  </p>
                </Tooltip>
                <img
                  src="/assets/images/down-arrow.png"
                  alt=""
                  className={
                    styles[
                      "header-container__wrapper__left__stores__down-arrow"
                    ]
                  }
                />
              </>
            )}
            {storeOpen && (
              <StoreSelector
                selectedCountry={selectedStore}
                handlecountryClick={handleCountryClick}
                stores={storeLists}
              />
            )}
          </div>
          {selectedStore?.id === "STSA" && (
            <div
              className={
                styles["header-container__wrapper__left__arabic-switch"]
              }
              onClick={() => changeLang?.(selectedLanguage)}
            >
              {i18n.language === "en" ? "العربية" : "English"}
            </div>
          )}
        </div>
        <div className={styles["header-container__wrapper__right"]}>
          <div className={styles["header-container__wrapper__right__history"]}>
            <Link
              to={`/${currentLang}/${PAGE_URLS.CREDIT_HISTORY}`}
              style={{
                color: isActiveLink(
                  `/${currentLang}/${PAGE_URLS.CREDIT_HISTORY}`
                )
                  ? "#332879"
                  : "inherit",
              }}
            >
              {t("creditHistory")}
            </Link>
            <Link
              to={`/${currentLang}/${PAGE_URLS.REDEMPTION_HISTORY}`}
              style={{
                color: isActiveLink(
                  `/${currentLang}/${PAGE_URLS.REDEMPTION_HISTORY}`
                )
                  ? "#332879"
                  : "inherit",
              }}
            >
              {t("redemptionHistory")}
            </Link>
          </div>
          <div
            className={
              styles["header-container__wrapper__right__account-balance"]
            }
          >
            <div
              className={
                styles[
                  "header-container__wrapper__right__account-balance__wallet-icon"
                ]
              }
            >
              <img
                src="/assets/images/header-wallet-icon.png"
                alt="wallet-icon"
              />
            </div>
            <div
              className={
                styles[
                  "header-container__wrapper__right__account-balance__texts"
                ]
              }
            >
              <p>{t("corporateAccountBalance")}</p>
              <h4>
                {headerData?.currency?.code} {headerData?.accountBalance}
              </h4>
            </div>
          </div>
          <div
            className={styles["header-container__wrapper__right__my-account"]}
            onClick={() => setOpen(true)}
            ref={componentRef}
          >
            <div
              className={
                styles[
                  "header-container__wrapper__right__my-account__round-box"
                ]
              }
            >
              {profileData?.data?.name?.charAt(0).toUpperCase()}
            </div>
            <div
              className={
                styles["header-container__wrapper__right__my-account__name-box"]
              }
            >
              <div
                className={
                  styles[
                    "header-container__wrapper__right__my-account__name-box__text"
                  ]
                }
              >
                {t("me")}
              </div>
              <div
                className={
                  styles[
                    "header-container__wrapper__right__my-account__name-box__arrow"
                  ]
                }
              >
                {open && (
                  <UserDetailsBox
                    isDashboard={true}
                    data={profileData}
                    handleLogout={handleLogout}
                    logoutLoading={logoutLoading}
                    handleClose={handleCloseProfileBox}
                  />
                )}
                <img src="/assets/images/down-arrow.png" alt="down-arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateHeader;
