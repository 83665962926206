import React, { FC } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Skeleton,
} from "@mui/material";
import styles from "./MajorHeaderSkeleton.module.scss";
import { useTranslation } from "react-i18next";

const CorporateHeaderSkeleton: FC = () => {
  const { t } = useTranslation();
  return (
    <div className="corporate-header-skeleton">
      <AppBar position="static">
        <Toolbar className={styles["header-container__wrapper"]}>
          <div className={styles["header-container__wrapper__left"]}>
            <IconButton>
              <Skeleton variant="rectangular" width={170} height={46} />
            </IconButton>
            <div className={styles["header-container__wrapper__left__stores"]}>
              <Avatar
                className={
                  styles["header-container__wrapper__left__stores__flag"]
                }
              >
                <Skeleton variant="rectangular" width={20} height={16} />
              </Avatar>
              <Typography>
                <Skeleton width={80} />
              </Typography>
            </div>
          </div>
          <div className={styles["header-container__wrapper__right"]}>
            <div
              className={styles["header-container__wrapper__right__history"]}
            >
              <Typography>
                <Skeleton width={80} />
              </Typography>
              <Typography>
                <Skeleton width={80} />
              </Typography>
              <Typography>
                <Skeleton width={80} />
              </Typography>
            </div>
            <div
              className={
                styles["header-container__wrapper__right__account-balance"]
              }
            >
              <div
                className={
                  styles[
                    "header-container__wrapper__right__account-balance__wallet-icon"
                  ]
                }
              ></div>
              <div
                className={
                  styles[
                    "header-container__wrapper__right__account-balance__texts"
                  ]
                }
              >
                <Typography>
                  <Skeleton width={80} />
                </Typography>
                <Typography>
                  <Skeleton width={80} />
                </Typography>
              </div>
            </div>
            <div className={styles["header-container__wrapper__right__cart"]}>
              <IconButton>
                <div
                  className={
                    styles[
                      "header-container__wrapper__right__cart__logo__count"
                    ]
                  }
                >
                  <Skeleton width={20} />
                </div>
              </IconButton>
            </div>
            <div
              className={styles["header-container__wrapper__right__my-account"]}
            >
              <IconButton></IconButton>
              <Avatar
                className={
                  styles[
                    "header-container__wrapper__right__my-account__round-box"
                  ]
                }
              >
                <Skeleton variant="text" width={20} height={20} />
              </Avatar>
              <div
                className={
                  styles[
                    "header-container__wrapper__right__my-account__name-box"
                  ]
                }
              >
                <div
                  className={
                    styles[
                      "header-container__wrapper__right__my-account__name-box__text"
                    ]
                  }
                >
                  {t("me")}
                </div>
                <div
                  className={
                    styles[
                      "header-container__wrapper__right__my-account__name-box__arrow"
                    ]
                  }
                ></div>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CorporateHeaderSkeleton;
