import React, { ChangeEvent, FC, MouseEvent, useState } from "react";
import styles from "./BrandDetailWeb.module.scss";
import BrandDetailAccordion from "../../common/brandDetailAccordion/BrandDetailAccordion";
import { Button, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import CheckoutModal from "../../common/checkoutModal/CheckoutModal";
import BrandDetailWebSkeleton from "../skeleton/BrandDetailWebSkeleton";
import { SingleObj } from "../../../../interfaces/common.interface";

interface BrandDetailContentsProps {
  handleDecrement: () => void;
  handleIncrement: () => void;
  handleMouseLeave: (index: any, item: any) => void;
  handleMouseEnter: (index: any, item: any) => void;
  handleDenomChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDenominationClick: (amount: any) => void;
  onClickOther: () => void;
  handleBalanceError?: (error: string) => void;
  singleObj?: SingleObj;
}

const BrandDetailWeb: FC<BrandDetailContentsProps> = ({
  handleDecrement,
  handleDenomChange,
  handleIncrement,
  handleMouseLeave,
  handleMouseEnter,
  onClickOther,
  handleDenominationClick,
  handleBalanceError,
  singleObj,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onKeyDown = (event: any) => {
    const value = event?.target?.value;
    const maxLength = String(singleObj?.maxDenom).length || 4;

    if (
      value?.split(".")[0].length === maxLength &&
      event.which !== 8 &&
      event.which !== 46 &&
      (Number(value) < Number(singleObj?.maxDenom) ||
        Number(value) > Number(singleObj?.maxDenom))
    ) {
      event.preventDefault();
      return false;
    }

    // #. Prevent adding char "e" from input
    // #. "e" char allowed in numeric field by default
    if (event.which === 69 || event.which === 107 || event.which === 109) {
      event.preventDefault();
      return false;
    }
  };
  const [tooltipEnabled, setTooltipEnabled] = useState<boolean>();
  const handleShouldShow = ({ currentTarget }: MouseEvent<Element>) => {
    if (currentTarget.scrollWidth > currentTarget.clientWidth) {
      setTooltipEnabled(true);
    }
  };
  const handleShouldHide = () => {
    setTooltipEnabled(false);
  };

  if (singleObj?.detailLoading) {
    return <BrandDetailWebSkeleton />;
  }

  return (
    <>
      <div
        className={`${styles["brand-detail-container"]} brand-detail-wrapper`}
      >
        <div className={styles["brand-detail-container__left-section"]}>
          <div
            className={
              styles["brand-detail-container__left-section__card-wrapper"]
            }
          >
            <div
              className={
                styles[
                  "brand-detail-container__left-section__card-wrapper__info"
                ]
              }
            >
              <div
                className={
                  styles[
                    "brand-detail-container__left-section__card-wrapper__info__validity"
                  ]
                }
              >
                {t("validity")}{" "}
                <strong>{singleObj?.singleData?.validity}</strong>
              </div>
              <div
                className={
                  styles[
                    "brand-detail-container__left-section__card-wrapper__info__in-store"
                  ]
                }
              >
                {singleObj?.singleData?.redemptionType}
              </div>
            </div>
            <div
              className={
                styles[
                  "brand-detail-container__left-section__card-wrapper__logo"
                ]
              }
            >
              <img src={singleObj?.singleData?.productImageUrl} alt="" />
            </div>
          </div>
          <div
            className={
              styles["brand-detail-container__left-section__accordions"]
            }
          >
            <BrandDetailAccordion
              description={singleObj?.singleData?.description}
              terms={singleObj?.singleData?.termsAndConditions}
              locations={singleObj?.singleData?.location}
            />
          </div>
        </div>
        <div className={styles["brand-detail-container__right-section"]}>
          <div
            className={
              styles["brand-detail-container__right-section__left-logo"]
            }
          >
            <img src={singleObj?.singleData?.productImageUrl} alt="" />
          </div>
          <div
            className={
              styles["brand-detail-container__right-section__right-contents"]
            }
          >
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__header"
                ]
              }
            >
              <div
                className={
                  styles[
                    "brand-detail-container__right-section__right-contents__header__left"
                  ]
                }
              >
                <Tooltip
                  title={singleObj?.singleData?.name}
                  placement="top"
                  open={tooltipEnabled || false}
                >
                  <h4
                    onMouseEnter={handleShouldShow}
                    onMouseLeave={handleShouldHide}
                  >
                    {singleObj?.singleData?.name}
                  </h4>
                </Tooltip>
              </div>
              <div
                className={
                  styles[
                    "brand-detail-container__right-section__right-contents__header__right"
                  ]
                }
              >
                {singleObj?.singleData?.denominationRange?.currency}{" "}
                {singleObj?.singleData?.denominationRange?.min} -{" "}
                {singleObj?.singleData?.denominationRange?.currency}{" "}
                {singleObj?.singleData?.denominationRange?.max}
              </div>
            </div>
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__denomination-section"
                ]
              }
            >
              <img
                src="/assets/images/minus-icon.png"
                alt=""
                onClick={
                  singleObj?.initialDenom > singleObj?.minDenom
                    ? handleDecrement
                    : undefined
                }
                className={
                  singleObj?.initialDenom <= singleObj?.minDenom
                    ? styles["disabled"]
                    : ""
                }
              />
              <div
                className={
                  styles[
                    "brand-detail-container__right-section__right-contents__denomination-section__denom-value"
                  ]
                }
              >
                <span>
                  {singleObj?.singleData?.denominationRange?.currency}
                </span>
                <input
                  type="number"
                  value={singleObj?.initialDenom}
                  onChange={handleDenomChange}
                  onKeyDown={onKeyDown}
                  pattern="\d+(\.\d+)?"
                  ref={singleObj?.inputRef}
                  readOnly={!singleObj?.isVariableDenom}
                  style={{
                    width: String(singleObj?.initialDenom)?.length + "ch",
                  }}
                  max={Number(singleObj?.maxDenom)}
                  min={Number(singleObj?.minDenom)}
                />
              </div>

              <img
                src="/assets/images/plus-icon.png"
                alt=""
                onClick={
                  singleObj?.initialDenom < singleObj?.maxDenom
                    ? handleIncrement
                    : undefined
                }
                className={
                  singleObj?.initialDenom >= singleObj?.maxDenom
                    ? styles["disabled"]
                    : ""
                }
              />
            </div>
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__amount-selection"
                ]
              }
            >
              {singleObj?.denominations &&
                singleObj?.denominations.length > 0 &&
                singleObj?.denominations.map((item: any, index: any) => (
                  <div key={index}>
                    <div
                      className={`${
                        styles[
                          "brand-detail-container__right-section__right-contents__amount-selection__single-item"
                        ]
                      } ${
                        Number(item?.amount) === singleObj?.initialDenom &&
                        styles["active"]
                      }`}
                      key={index}
                      onMouseEnter={() =>
                        handleMouseEnter(index, Number(item?.amount))
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(index, Number(item?.amount))
                      }
                      onClick={() =>
                        handleDenominationClick(Number(item?.amount))
                      }
                    >
                      {item?.currency} {item?.amount}
                    </div>
                  </div>
                ))}
              {singleObj?.isVariableDenom && (
                <div
                  className={`${
                    styles[
                      "brand-detail-container__right-section__right-contents__amount-selection__other"
                    ]
                  } ${singleObj?.isOtherActive && styles["active"]}`}
                  onClick={onClickOther}
                >
                  {t("other")}
                </div>
              )}
            </div>
            <div
              className={
                styles[
                  "brand-detail-container__right-section__right-contents__checkout-button"
                ]
              }
            >
              <Button
                variant="contained"
                fullWidth
                disabled={
                  singleObj?.errorText.length > 0 ||
                  singleObj?.initialDenom === 0
                }
                onClick={() => setOpen(true)}
              >
                {t("checkout")}
              </Button>
            </div>
            {singleObj?.errorText ? (
              <div
                className={
                  styles[
                    "brand-detail-container__right-section__right-contents__error-container"
                  ]
                }
              >
                <img src="/assets/images/brand-denomination-error.png" alt="" />
                <p>{singleObj?.errorText}</p>
              </div>
            ) : singleObj?.balanceError ? (
              <div
                className={
                  styles[
                    "brand-detail-container__right-section__right-contents__error-container"
                  ]
                }
              >
                <img src="/assets/images/brand-denomination-error.png" alt="" />
                <p>{singleObj?.balanceError}</p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {open && (
        <CheckoutModal
          amount={singleObj?.initialDenom?.toString()}
          currency={singleObj?.singleData?.denominationRange?.currency}
          brandName={singleObj?.singleData?.name}
          onClose={handleClose}
          brandCode={singleObj?.singleData?.code}
          handleError={handleBalanceError}
        />
      )}
    </>
  );
};

export default BrandDetailWeb;
