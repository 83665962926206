import React, { FC } from "react";
import styles from "./AuthenticationRequired.module.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PAGE_URLS from "../../../../constants/pageUrls";

const AuthenticationRequired: FC = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const backHome = () => {
    navigate(`/${currentLanguage}/${PAGE_URLS.LOGIN}`);
  };
  return (
    <div className={styles["auth-required-container"]}>
      <div className={styles["auth-required-container__contents"]}>
        <div
          className={
            styles["auth-required-container__contents__imoji-container"]
          }
        >
          <img src="/assets/images/auth-required.png" alt="" />
        </div>
        <div
          className={
            styles["auth-required-container__contents__text-container"]
          }
        >
          <p>{t("errorCode401")}</p>
          <h3>{t("authenticationRequired")}</h3>
          <p>{t("authenticationPermission")}</p>
        </div>
        <div
          className={styles["auth-required-container__contents__back-button"]}
        >
          <Button variant="contained" onClick={backHome}>
            {t("backToLoginPage")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AuthenticationRequired;
